import ImageEntryService from "../Services/imageEntryService";
import { GET_CONTINUE_IMAGE_DATA } from "./types";

export const getContinueImageData = (data) => async (dispatch) => {
  try {
    const res = await ImageEntryService.getContinueImageData(data);
    dispatch({
      type: GET_CONTINUE_IMAGE_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
