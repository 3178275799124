import { Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
export default function TagCheckingTable({
  imagesData,
  setSelectedImage,
  selectedImage,
}) {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "93vh",
          maxHeight: "93vh",
          overflowY: "scroll",
          background: "#FFFFE6",
          padding: "0px",
          marginTop: "7vh",
          overflowX: "hidden",
        }}
        className="col-2 w-100"
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "2px solid #d3d3d3",
                  borderTop: "0px solid",
                  borderBottom: "0px solid",
                  background: "#b1b1b1",
                  fontSize: "12px",
                  padding: "4px",
                  textAlign: "center",
                  fontWeight: "bold",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
                size="small"
              >
                Image Number ({imagesData?.length})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {imagesData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  padding: "0px",
                  margin: "0px",
                  background:
                    selectedImage === row["IMAGE_NUMBER"] ? "#5ca2ad" : "",
                }}
              >
                <TableCell
                  sx={{
                    border: "2px solid #b1b1b1!important",
                    padding: "0px",
                    borderBottom: "0px",
                  }}
                  className="col-12"
                  size="small"
                >
                  <Typography
                    type="text"
                    sx={{
                      padding: "2px 4px",
                      border: "none",
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      cursor: "pointer",
                    }}
                    readOnly
                    onClick={() => setSelectedImage(row["IMAGE_NUMBER"])}
                  >
                    {row["IMAGE_NUMBER"]}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
