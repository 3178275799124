import http from "../../utilities/http-common";

const ctrlTwoDataInsert = async (data) => {
  return await http.post("/Image/InsertControlTwo", data);
};
const ctrlOneDataInsert = async (data) => {
  return await http.post("/Image/InsertControlOne", data);
};
const getCtrlTwoData = async (data1, data2) => {
  return await http.get(`/Image/GetControlTwo/${data1}/${data2}`);
};
const CtrlTwoService = {
  ctrlTwoDataInsert,
  ctrlOneDataInsert,
  getCtrlTwoData,
};

export default CtrlTwoService;
