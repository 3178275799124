import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import Divider from "@mui/material/Divider";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import style from "./DataEntry.css";
import { useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import FullScreen from "./../FullScreen/FullScreen";
import AlertBox from "./../AlertBox/AlertBox";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getImageDataForEntry } from "../../Redux/Actions/imageTaggingAction";
import {
  getImageCoordinates,
  imageEntryDataInsert,
} from "../../Redux/Actions/imageEntryAction";
import { getContinueImageData } from "../../Redux/Actions/continueImageAction";
import {
  ctrlOneDataInsert,
  ctrlTwoDataInsert,
  getCtrlTwoData,
} from "../../Redux/Actions/ctrlTwoAction";
import { Button, Stack, Typography } from "@mui/material";
import {
  createCanvas,
  setModifiedDateValue,
  validateFunc,
} from "../Helper/entryHelper";
import {
  getImageOffset,
  setImageOffset,
} from "../../Redux/Actions/focusImageAction";
import disableScroll from "disable-scroll";
import { setEmptySearchData } from "../../Redux/Actions/searchDataAction";
import {
  drawFullLine,
  handleRotation,
  setTransform,
} from "../Helper/commonHelper";
import {
  addPrefixSuffix,
  allFieldsGenerator,
  getDataObjectByImgCode,
  onscreenKeyboardHandleOnEntry,
  pascalToCamelCase,
} from "../Helper/library";
import { getFieldSequence } from "../../Redux/Actions/fieldSequenceActions";
import Keyboard from "../Helper/Keyboard";
import { notifyWarn } from "../Helper/toasts";
import {
  getFieldNameCombiningNames,
  getSplittedCordinateForNames,
} from "../Helper/dataEntryHelper";
import { MIXED_TYPE_CODE } from "../Helper/constants";
import { allFieldsObject } from "../Helper/entryField";

export default function DataEntry() {
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const imgRef = useRef(null);
  const canvRef = useRef(null);
  const searchRef = useRef(null);
  const mainSectionRef = useRef(null);

  const imagesData = useSelector((state) => state.imageTaggingReducer);
  const continueImage = useSelector((state) => state.continueImageReducer);
  const ctrlTwo = useSelector((state) => state.ctrlTwoReducer);
  const imageCoordinates = useSelector((state) => state.imageEntryReducer);
  const imageFieldSequence = useSelector((state) => state.fieldSequenceReducer);
  const newFocus = useSelector((state) => state.focusImageReducer);

  const [isProperCase, setIsProperCase] = useState(false);
  const [isShowSearchBar, setIsShowSearchBar] = useState(false);
  const [screenKeyboardOpen, setScreenKeyboardOpen] = useState(false);

  const [screenKeyboard, setScreenKeyboard] = useState(null);
  const [previousLeftForFullLine, setPreviousLeftForFullLine] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [tagIndex, setTagIndex] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [openInp, setOpenInp] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(selectedSeriesfromLocal);
  const [images, setImages] = useState(imagesData);
  const [continueImageState, setContinueImageState] = useState(continueImage);
  const [query, setQuery] = useState("");
  const [entryData, setEntryData] = useState([]);
  const [taggedData, setTaggedData] = useState([]);
  const [entryBody, setEntryBody] = useState([]);
  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [taggedDatas, setTaggedDatas] = useState(imageCoordinates);
  const [focusData, setFocusData] = useState(null);
  const [fixedLeftData, setFixedLeftData] = useState(null);
  const [previousCoordinateData, setPreviousCoordinateData] = useState([
    0, 0, 0, 0,
  ]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageURL, setImageUrl] = useState();
  const [imageHW, setImageHW] = useState(null);
  const [scale, setScale] = useState(2);
  const [canvas, setCanvas] = useState(null);
  const [canvasPosition, setCanvasPosition] = useState(null);
  const [cardClicked, setCardClicked] = useState(false);

  const [inputFields, setInputFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [activeFields, setActiveFields] = useState([]);
  const [fieldSequence, setFieldSequence] = useState([]);
  const [dataType, setDataType] = useState("");

  const [selectedInputField, setSelectedInputField] = useState("");

  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];
    document.title = "Data Entry | Data Keying  ";
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!imageCoordinates) return;
    const eventType = pascalToCamelCase(imageCoordinates.EVENT_TYPE || "");
    const dataObject = allFieldsObject[eventType];

    let codeToUse =
      imageCoordinates.CODE !== MIXED_TYPE_CODE
        ? imageCoordinates.CODE
        : dataObject.imgCode;

    dispatch(getFieldSequence(selectedSeries, codeToUse, 1));
  }, [imageCoordinates]);

  useEffect(() => {
    if (imageFieldSequence !== null) {
      setFieldSequence(imageFieldSequence.fieldName);
    }
  }, [imageFieldSequence]);
  useEffect(() => {
    if (fieldSequence?.length >= 0) {
      const newMenus = allFieldsGenerator(
        "entry",
        fieldSequence,
        imageCoordinates?.CODE
      );
      setInputFields(newMenus);
    }
  }, [fieldSequence, imageCoordinates]);
  useEffect(() => {
    if (customFields?.length > 0) {
      setActiveFields(() =>
        customFields.filter((customField) => customField["active"] === true)
      );
    }
  }, [customFields]);

  // Generates the js for the DINAMIC menu.(testing)[end]

  useEffect(() => {
    if (!imageCoordinates) return;
    const dataObject = getDataObjectByImgCode(
      allFieldsObject,
      imageCoordinates.CODE
    );

    if (imageCoordinates.CODE === MIXED_TYPE_CODE) {
      setDataType(imageCoordinates.EVENT_TYPE);
    } else {
      setDataType(dataObject.value);
    }
  }, [imageCoordinates]);

  useEffect(() => setCustomFields(inputFields), [inputFields]);

  useEffect(() => {
    setImages(imagesData);
  }, [imagesData]);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[currentImgIndex]);
    }
  }, [images, currentImgIndex]);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(
        `${imageBaseUrl}${selectedSeries}/${selectedImage?.IMAGE_NAME}`
      );
      dispatch(getImageCoordinates(selectedImage?.IMAGE_NUMBER));
      dispatch(getContinueImageData(selectedImage?.IMAGE_NUMBER));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (imageCoordinates === null) {
      if (images[currentImgIndex + 1] !== undefined) {
        setCurrentImgIndex(currentImgIndex + 1);
      } else setOpen(true);
    }
  }, [imageCoordinates]);
  useEffect(() => {
    if (imageCoordinates !== null) {
      if (imageCoordinates?.LINE > 1) {
        searchRef?.current?.children[3]?.children[1]?.children[1]?.focus();
      } else searchRef?.current?.children[0]?.children[1]?.children[1]?.focus();
    }
  }, [imageCoordinates, activeFields]);

  // useEffect(() => {
  //   setTaggedDatas(imageCoordinates);
  // }, [imageCoordinates]);

  //=====================================================
  //sideeffects and others start
  //=======================================================
  useEffect(() => {
    dispatch(getImageDataForEntry(selectedSeries));
  }, [selectedSeries]);

  useEffect(() => {
    if (imagesData.length > 0) {
      dispatch(
        getCtrlTwoData(imagesData[0].SERIES_NUMBER, imagesData[0]?.CODE)
      );
      imagesData[0].FORMAT === 1 &&
        dispatch(
          getImageOffset(imagesData[0].SERIES_NUMBER, imagesData[0]?.CODE)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesData]);

  useEffect(() => {
    if (imagesData?.length === 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [imagesData]);
  useEffect(() => {
    setTransform(canvRef, scale);
  }, [scale]);

  useEffect(() => {
    function handleKeyUp(event) {
      event.preventDefault();
      if (event.ctrlKey && event.keyCode === 113) {
        setOpenInp(true);
      }
    }
    window.addEventListener("keyup", handleKeyUp);

    return () => window.removeEventListener("keyup", handleKeyUp);
  });

  let hasNext = currentImgIndex < images?.length - 1;

  useEffect(() => {
    if (
      images[currentImgIndex + 1] !== undefined &&
      taggedData.length > 0 &&
      taggedData.length === currentIndex
    ) {
      // setCurrentImgIndex(currentImgIndex + 1);
      setCurrentIndex(0);
    }
  }, [currentImgIndex, currentIndex, taggedData.length]);
  const handleCloseFields = () => {
    setOpenInp(false);
  };
  const handleClose = () => {
    setOpen(false);
    history.push("/");
  };

  useEffect(() => {
    window.addEventListener("resize", settingRation);

    // settingRation();
    // }, [widthRatio, heightRatio]);
  }, [window]);

  useEffect(() => {
    if (document) {
      settingRation();
    }
  }, [document.querySelector("#imagesss")?.naturalWidth]);

  useEffect(() => {
    if (query !== "") {
      setIsShowSearchBar(true);
    }
  }, [query]);
  useEffect(() => {
    if (imageURL) {
      settingRation();
    }
  }, [imageURL]);
  //=====================================================
  //sideeffects and others end
  //=======================================================

  //=====================================================
  //Getting the height and width ratio start
  //=======================================================

  function settingRation() {
    var windowSize = document.querySelectorAll(".imgdiv");

    var w = windowSize[0]?.clientWidth;
    var h = windowSize[0]?.clientHeight;

    var myImg = document.querySelector("#imagesss");
    var realWidth = myImg?.naturalWidth;

    var realHeight = myImg?.naturalHeight;
    setImageHW({ width: realWidth, height: realHeight });

    var widthRatios = w / realWidth;
    var heightRatios = h / realHeight;
    setWidthRatio(widthRatios);
    setHeightRatio(heightRatios);
  }

  //=====================================================
  //Getting the height and width ratio end
  //=======================================================

  //=====================================================
  //click enter to goto next Page start
  //=======================================================
  const goToNextSlide = () => {
    if (hasNext) {
      // setCurrentIndex(currentIndex + 1);
    }
    // else setOpen(true);
    // } else history.replace("/series");
  };

  //=====================================================
  //click enter to goto next Page End
  //=======================================================

  //=======================================================
  //Handle Button Key Press start
  //=====================================================
  //=======================================================
  //click enter to goto next field start
  //=====================================================

  async function handleEnter(event) {
    if ((event.keyCode === 9 || event.keyCode === 13) && !event.shiftKey) {
      event.preventDefault();
      setIsShowSearchBar(false);
      const newEntryBody = entryBody;
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);

      const records = {
        imageNumber: imageCoordinates.IMAGE_NUMBER,
        fieldName: event.target.name,
        fieldValue: event.target.value === "" ? null : event.target.value,
        line: imageCoordinates.LINE,
      };
      const isValidate = event.target.value
        ? validateFunc(event.target.name, event.target.value)
        : true;
      if (!isValidate) {
        notifyWarn(`Please enter a valid ${event.target.name} format`);
        // `Please enter a valid ${event.target.name} format: \n\t\n00/month/0000`

        // form.elements[index].focus();
      }
      newEntryBody.push(records);
      setEntryBody(newEntryBody);

      // customFields[customFields.length - 1].active==true?
      index + 1 > activeFields.length - 1
        ? handleSubmit(event, newEntryBody)
        : form.elements[index + 1].focus();
    }
    if (event.keyCode === 13 && event.shiftKey) {
      event.preventDefault();
      validateFunc(event.target.name, event.target.value);
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);

      index == 0 ? void 0 : form.elements[index - 1].focus();
    }
    if (event.ctrlKey && event.keyCode === 50) {
      //ctrl+2
      event.preventDefault();
      const newEntryBody = {
        SERIES_NUMBER: selectedSeries,
        CODE: imageCoordinates?.CODE,
        EVENT_TYPE: imageCoordinates?.EVENT_TYPE,
        fieldName: getFieldNameCombiningNames(event.target.name),
      };
      dispatch(ctrlTwoDataInsert(newEntryBody));
    }
    if (event.ctrlKey && event.keyCode === 49) {
      //ctrl+1
      event.preventDefault();
      const newEntryBody = {
        SERIES_NUMBER: selectedSeries,
        CODE: imageCoordinates?.CODE,
        EVENT_TYPE: imageCoordinates?.EVENT_TYPE,
        fieldName: getFieldNameCombiningNames(event.target.name),
      };
      dispatch(ctrlOneDataInsert(newEntryBody));
    }
    if (event.ctrlKey && event.keyCode === 83) {
      //ctrl+s
      event.preventDefault();
      const bounds = canvRef.current.getBoundingClientRect();
      const mainBox = mainSectionRef.current;
      var element = document.getElementById("focusedPosition");
      // let xValue =
      //   (element.getBoundingClientRect().left - canvasPosition.x) / scale;
      // let yValue =
      //   (element.getBoundingClientRect().top - canvasPosition.y) / scale;
      let xValue = (mainBox.scrollLeft + mainBox.clientWidth / 2) / scale;
      let yValue = (mainBox.scrollTop + mainBox.clientHeight / 2) / scale;

      const newEntryBody = [
        {
          seriesNumber: selectedSeries,
          code: imageCoordinates.CODE,
          // format: imageCoordinates.FORMAT,
          format: 1,
          fieldName: event.target.name,
          x_value: xValue / widthRatio,
          y_value: cardClicked ? yValue / heightRatio : null,
        },
      ];
      dispatch(setImageOffset(newEntryBody));
    }
    if (event.key === "0") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ÿ");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "ñ");
    } else if (event.key === "1") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "î");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "à");
    } else if (event.key === "2") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ï");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "á");
    } else if (event.key === "3") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ò");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "ä");
    } else if (event.key === "4") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ó");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "â");
    } else if (event.key === "5") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ö");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "ç");
    } else if (event.key === "6") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ô");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "é");
    } else if (event.key === "7") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "û");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "è");
    } else if (event.key === "8") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ù");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "ê");
    } else if (event.key === "9") {
      screenKeyboard === 16 && onscreenKeyboardHandleOnEntry(event, "ü");
      screenKeyboard === 17 && onscreenKeyboardHandleOnEntry(event, "ë");
    }
    if (event.which === 16 || event.which === 17) {
      setScreenKeyboard(event.which);
      setScreenKeyboardOpen(true);
    } else {
      setScreenKeyboard(null);
    }
    if (event.which === 27) {
      setScreenKeyboardOpen(false);
    }
    if (event.which === 114) {
      if (selectedInputField.includes("NAME")) {
        event.preventDefault();
        setCustomFields((customFields) =>
          addPrefixSuffix(selectedInputField, customFields)
        );
      }
    }
  }
  //=====================================================
  //click enter to goto next field end
  //=======================================================

  //=====================================================
  //get tagging vlues start
  //=======================================================

  const handleChanges = (e) => {
    const { name, value } = e.target;

    activeFields.map((ek) => {
      if (ek.fieldName == name) {
        ek.inpVal = value;
      }
    });

    setEntryData({
      ...entryData,
      [name]: value,
    });
  };

  //=====================================================
  //get tagging vlues end
  //=======================================================

  //=====================================================
  //submitting vlues start
  //=======================================================

  const handleSubmit = (e, newEntryBody) => {
    setQuery("");
    if (entryData) {
      setData([...data, newEntryBody]);
      dispatch(imageEntryDataInsert(newEntryBody));
      setEntryData([]);
      var selectLastTag = document.querySelectorAll(".inputCls");
      selectLastTag.forEach((el) => (el.value = ""));
      if (taggedData.length > currentIndex) {
        setCurrentIndex((currentIndex) => currentIndex + 1);
      }
    }
    if (
      images[currentImgIndex + 1] === undefined &&
      taggedData.length - 1 === currentImgIndex
    ) {
      setOpen(true);
    } else {
      // goToNextSlide();
      // const form = e.target.form;
      // form.elements[0].focus();
    }

    // splitCordinate()
    // handlefocus(
    //   taggedData[currentIndex + 1]?.tagged[0]?.coordinateX,
    //   taggedData[currentIndex + 1]?.tagged[0]?.coordinateY
    // );
  };

  //=====================================================
  //submitting vlues end
  //=======================================================

  //=====================================================
  //for changing image if current image tagging is start
  //=======================================================

  //=====================================================
  //for changing image if current image tagging is finished
  //=======================================================

  //=====================================================
  //dragging div start
  //=======================================================

  //=====================================================
  //dragging div finished
  //=======================================================

  //=====================================================
  //tagging show on field focus start
  //=======================================================

  // The conditions here are for checking Continue and First Record Connection images
  // A image can be only Continue or First Record Connection image but the fields could be marked previously with CTRL+2
  // So the first record connection criteria is in separate if block because it could be alreay a ctrlTwo positive. And
  // Whethe the image is continue or not the first record connection rule need to be applied.
  const handleCtrlPlus2 = () => {
    if (
      selectedInputField !== "" &&
      selectedImage !== null &&
      imageCoordinates !== null
    ) {
      settingRation();
      // Checking only for continue image
      if (
        ctrlTwo !== null &&
        ctrlTwo[getFieldNameCombiningNames(selectedInputField, true)] === 2 &&
        continueImage !== 0
      ) {
        setImageUrl(`${imageBaseUrl}${selectedSeries}/${continueImage}`);
      } else {
        setImageUrl(
          `${imageBaseUrl}${selectedSeries}/${selectedImage?.IMAGE_NAME}`
        );
      }
      // The continue image will be overridden by the below check for First Record Connection rule
      if (imageCoordinates["FORMAT"] === 2) {
        if (imageCoordinates[`${selectedInputField}_POS`] !== undefined) {
          if (imageCoordinates[`${selectedInputField}_POS`] !== null) {
            if (imageCoordinates[`${selectedInputField}_PAGE`] !== null) {
              setImageUrl(
                `${imageBaseUrl}${selectedSeries}/${
                  imageCoordinates[`${selectedInputField}_PAGE`]
                }`
              );
            } else if (
              ctrlTwo !== null &&
              ctrlTwo[getFieldNameCombiningNames(selectedInputField, true)] ===
                2 &&
              continueImage !== 0
            ) {
              setImageUrl(`${imageBaseUrl}${selectedSeries}/${continueImage}`);
            } else {
              setImageUrl(
                `${imageBaseUrl}${selectedSeries}/${selectedImage?.IMAGE_NAME}`
              );
            }
            const x = imageCoordinates[`${selectedInputField}_POS`].split(";");

            setFixedLeftData(x);
            handlefocus(x[0], x[1], x[3], x[2], selectedInputField);
            setPreviousCoordinateData(x);
          } else {
            handlefocus(
              previousCoordinateData[0],
              previousCoordinateData[1],
              previousCoordinateData[3],
              previousCoordinateData[2],
              selectedInputField,
              0
            );
          }
        } else {
          handlefocus(
            previousCoordinateData[0],
            previousCoordinateData[1],
            previousCoordinateData[3],
            previousCoordinateData[2],
            selectedInputField,
            0
          );
        }
      } else if (imageCoordinates["FORMAT"] === 1) {
        handlefocus(
          imageCoordinates["LEFT_XCORD"],
          imageCoordinates["TOP_YCORD"],
          imageCoordinates["BOTTOM_YCORD"],
          imageCoordinates["RIGHT_XCORD"],
          selectedInputField
        );
        setFixedLeftData(null);
      }
    }
  };

  useEffect(() => {
    handleCtrlPlus2();
  }, [ctrlTwo, continueImage]);

  useEffect(() => {
    handleCtrlPlus2();
  }, [selectedInputField, selectedImage, imageCoordinates]);

  const splitCordinate = (cordinateData) => {
    setSelectedInputField(getSplittedCordinateForNames(cordinateData));
  };

  const handlefocus = (x, y, h, w, cordinateData, opacityData = 1) => {
    const appendRef = canvRef.current;
    const canv1 = createCanvas(
      "focusedPosition",
      x * widthRatio + "px",
      y * heightRatio + "px",
      w + "px",
      h + "px",
      "red",
      opacityData
    );

    let canv2;
    appendRef.appendChild(canv1);
    if (
      newFocus &&
      newFocus[`X_${cordinateData}`] &&
      newFocus[`X_${cordinateData}`] !== null
    ) {
      if (
        newFocus[`Y_${cordinateData}`] &&
        newFocus[`Y_${cordinateData}`] !== null
      ) {
        canv2 = createCanvas(
          "focusedPosition2",
          newFocus[`X_${cordinateData}`] * widthRatio + "px",
          newFocus[`Y_${cordinateData}`] * heightRatio + "px",
          1 + "px",
          1 + "px",
          "none",
          0
        );
        appendRef.appendChild(canv2);
      } else {
        canv2 = createCanvas(
          "focusedPosition2",
          newFocus[`X_${cordinateData}`] * widthRatio + "px",
          y * heightRatio + "px",
          5 + "px",
          1 + "px",
          "none",
          0
        );
        appendRef.appendChild(canv2);
      }
      appendRef.appendChild(canv2);
      canv2.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      canv1.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    appendRef.style.transform = "scale(" + scale + ")";
    // appendRef.style.transform = "scale(" + 2 + ")";

    // appendRef.getBoundingClientRect().left
    setTimeout(() => {
      setCanvasPosition({
        x: canv1.getBoundingClientRect().left,
        y: canv1.getBoundingClientRect().top,
      });
    }, 1000);
    // setCanvas(element);
    setPreviousLeftForFullLine(x * widthRatio + "px");
    var selectLastTag = document.querySelectorAll("canvas");
    if (selectLastTag.length > 1) {
      appendRef.removeChild(selectLastTag[0]);
    }
    setTagIndex(tagIndex + 1);
    // var taggNew = document.querySelector("canvas");
    // taggNew.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    //   inline: "center",
    // });
  };

  //=====================================================
  //tagging show on field focus finished
  //=======================================================
  //=======================================================
  //Full Line Drawing of canvas start
  //=====================================================

  const fixedLeft = (e) => {
    const { checked } = e.target;
    setScale(2.9);
    const zoomImg = canvRef.current;
    const zoomImgClientWidth = imgRef.current.naturalWidth;

    const fixedLeftFocus = createCanvas(
      "focus2",
      200 * widthRatio + "px",
      fixedLeftData[1] * heightRatio + "px",
      "1px",
      "1px",
      "none"
    );
    zoomImg.appendChild(fixedLeftFocus);
    if (checked) {
      if (fixedLeftData[0] >= zoomImgClientWidth / 2) {
        fixedLeftFocus.style.left =
          (zoomImgClientWidth / 2 + 200) * widthRatio + "px";
      }
      zoomImg.style.transform = "scale(" + scale + ")";
      fixedLeftFocus.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!checked) {
      setScale(3);
      handlefocus(
        fixedLeftData[0],
        fixedLeftData[1],
        fixedLeftData[3],
        fixedLeftData[2],
        selectedInputField,
        1
      );
    }
  };
  //=====================================================
  //Full Line Drawing of canvas end
  //=======================================================
  const zooming = (e) => {
    // var element = document.querySelector("canvas").getBoundingClientRect();
    // setFocusData({
    //   x: element.left,
    //   y: element.top,
    // });
    // setFocusData({
    //   x: element.left / widthRatio,
    //   y: element.top / heightRatio,
    // });
    const canvRect = canvRef.current.getBoundingClientRect().left;
    // var windowSize = document.querySelectorAll("#imagesss");
    // windowSize.onMouseEnter(() => {
    //   alert("hey");
    // });

    if (!e.altKey && !e.shiftKey) {
      disableScroll.on();
      var delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
      delta > 0
        ? setScale((prevScale) => (prevScale *= 1.08).toFixed(2))
        : setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
      // setTransform(e);
    } else {
      disableScroll.off();
    }
  };

  return (
    <>
      <div>
        <Sidebar></Sidebar>
        <FullScreen></FullScreen>
        {open && (
          <AlertBox
            type="alert"
            open={open}
            onClose={handleClose}
            header="No data to entry"
            title="  Your entry is finished.To entry new data please tag
                    again"
          ></AlertBox>
        )}
        {openInp && (
          <AlertBox
            type="fieldSelection"
            open={openInp}
            onClose={handleCloseFields}
            header="Choose field sequence"
            code={imageCoordinates?.CODE}
            selectedSeries={selectedSeries}
            fieldSequenceProps={fieldSequence}
            setFieldSequenceProps={setFieldSequence}
            dataType={dataType}
          ></AlertBox>
        )}

        <Box
          sx={{
            flexGrow: 1,
            height: "50vh",
            overflow: "scroll",
            resize: "vertical",
          }}
          ref={mainSectionRef}
        >
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Box>
                <div
                  ref={canvRef}
                  // style={{ position: "relative" }}
                  // className="imgdiv"
                  className="zoom imgdiv"
                  onWheel={(e) => zooming(e)}
                  onMouseLeave={() => disableScroll.off()}
                  onMouseEnter={() => disableScroll.on()}
                  onMouseOver={() => disableScroll.on()}
                >
                  <img
                    className="imageContainer"
                    tabIndex="-1"
                    ref={imgRef}
                    // src={images[currentImgIndex].location}
                    src={images && imageURL}
                    alt="img"
                    // objectfit="cover"
                    id="imagesss"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>{" "}
        </Box>
        {/* <Divider /> */}
        {screenKeyboardOpen && <Keyboard screenKeyboard={screenKeyboard} />}

        <div className="pt-1 px-2 d-flex justify-content-between">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-Between",
            }}
          >
            <Stack
              spacing={1}
              sx={{ display: "flex", alignItems: "left", height: "4px" }}
            >
              <Typography
                variant="span"
                color="rgb(153, 30, 41)"
                fontSize={10}
                sx={{
                  fontWeight: "bold",
                  background: "white",
                  marginTop: "-4px",
                  marginLeft: "4px",
                }}
              >
                Project : N22060 | Series : {selectedSeries} | Image :{" "}
                {selectedImage?.IMAGE_NAME} | Line: ( {imageCoordinates?.LINE}{" "}
                out of {imageCoordinates?.TOTTAL_LINE} )
              </Typography>
            </Stack>

            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="mx-1 check-dialog"
                  onChange={(e) => drawFullLine(e, previousLeftForFullLine)}
                  name="fullLine"
                  id="fullLine"
                  value="full-line"
                  type="checkbox"
                  disabled={imageCoordinates?.FORMAT === 2 ? true : false}
                ></input>
                <label
                  className=" check-dialog"
                  style={{ marginRight: "5px" }}
                  htmlFor="fullLine"
                >
                  Full Line
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="mx-1 check-dialog"
                  onChange={() => {
                    setCardClicked(!cardClicked);
                  }}
                  name="card"
                  id="card"
                  value="card"
                  type="checkbox"
                  disabled={imageCoordinates?.FORMAT === 2 ? true : false}
                ></input>
                <label
                  className=" check-dialog"
                  style={{ marginRight: "5px" }}
                  htmlFor="card"
                >
                  Card
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="mx-1 check-dialog"
                  onChange={fixedLeft}
                  name="fixedLeft"
                  id="fixedLeft"
                  value="fixed-left"
                  type="checkbox"
                  disabled={
                    imageCoordinates?.FORMAT === 2 && fixedLeftData !== null
                      ? false
                      : true
                  }
                ></input>
                <label className=" check-dialog" htmlFor="fixedLeft">
                  Fixed Left
                </label>
              </div>
            </div>
          </div>

          <p className="m-0" style={{ fontWeight: "bold", color: "#991e29" }}>
            {dataType}
          </p>
          {/* <p className="m-0" style={{ fontWeight: "bold", color: "#991e29" }}>
            {`N22060 | ${selectedSeries} | ${selectedImage?.IMAGE_NAME}`}
          </p> */}

          <Button
            sx={{ fontSize: "12px" }}
            // sx={{ fontSize: "12px", position: "absolute", right: "10px" }}
            startIcon={<RotateRightIcon />}
            color="primary"
            onClick={() => handleRotation(imgRef)}
            variant="outlined"
          >
            Rotate
          </Button>
        </div>
      </div>

      <div>
        <Box sx={{ flexGrow: 1, height: "20vh", m: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              className="form-fields entryFieldContainer"
              ref={searchRef}
            >
              {activeFields?.length !== 0 &&
                activeFields?.map((field, i) => (
                  <Grid
                    item
                    lg={1}
                    key={i}
                    className="inputContainer"
                    style={{ maxWidth: "100%" }}
                    hidden={!field.active}
                  >
                    <h6
                      className="text-danger"
                      style={{ height: "10px", fontSize: "12px" }}
                    >
                      {field.inpVal ? field.inpVal.substring(0, 15) : ""}
                      {field.inpVal.length > 15 && "..."}
                    </h6>
                    {/* <div className="d-block"> */}
                    <div className="entryFieldItemContainer">
                      <label>
                        {field.labelName.includes("DATE")
                          ? `${field.labelName} (dd/mm/yyyy)`
                          : field.labelName}
                      </label>
                      <input
                        style={{
                          border:
                            ctrlTwo &&
                            ctrlTwo[
                              getFieldNameCombiningNames(field?.fieldName, true)
                            ] === 2 &&
                            "1px solid #ffc107",
                        }}
                        key={i}
                        // placeholder={
                        //   field.labelName.includes("DATE") ? "dd/mm/yyyy" : ""
                        // }
                        className="inputCls adaptive_input"
                        autoComplete="off"
                        type={field.inpType}
                        onKeyDown={(e) => handleEnter(e)}
                        onChange={(e) => {
                          handleChanges(e);
                          if (
                            e.target.name.includes("GIVENNAME") ||
                            e.target.name.includes("SURNAME")
                          ) {
                            setQuery(e.target.value);
                          } else if (e.target.name.includes("DATE")) {
                            e.target.value = setModifiedDateValue(
                              e.target.value
                            );
                          } else {
                            setQuery("");
                            setIsShowSearchBar(false);
                          }
                          setCurrentField(e.target.name);
                        }}
                        onBlur={(e) => {
                          e.target.value = isProperCase
                            ? e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                            : e.target.value;
                        }}
                        name={field.fieldName}
                        onFocus={() => {
                          splitCordinate(field.fieldName);
                          dispatch(setEmptySearchData());
                        }}
                      ></input>
                    </div>
                    {isShowSearchBar &&
                      currentField === field.fieldName &&
                      field.searchField && (
                        <SearchBar
                          currentField={currentField}
                          query={query}
                          setIsProperCase={setIsProperCase}
                        ></SearchBar>
                      )}
                  </Grid>
                ))}
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}
