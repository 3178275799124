import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import style from "./Tagging.css";
import Checkbox from "@mui/material/Checkbox";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { useLocation } from "react-router-dom";
import FullScreen from "./../FullScreen/FullScreen";
import Sidebar from "./../Sidebar/Sidebar";
import disableScroll from "disable-scroll";
import { useDispatch, useSelector } from "react-redux";
import {
  getImageData,
  updateImageComment,
  imageDataInsertIndex,
  imageDataInsertForFRC,
} from "../../Redux/Actions/imageTaggingAction";
import { useHistory } from "react-router-dom";
import { handleRotation } from "../Helper/commonHelper";
import { setFieldSequence } from "../../Redux/Actions/fieldSequenceActions";
import {
  fieldSequenceGenerator,
  getDataObjectByImgCode,
  pascalToCamelCase,
} from "../Helper/library";
import { notifyWarn } from "../Helper/toasts";
import {
  birth,
  death,
  burial,
  baptism,
  marriage,
  marriageIntent,
  dataTypeCode,
  languageCode,
  allFieldsObject,
} from "../Helper/entryField";
import { getBBDStatusCode } from "../Helper/taggingHelper";
import {
  DEFAULT_TYPE,
  DEFAULT_TYPE_CODE,
  MIXED_TYPE_CODE,
} from "../Helper/constants";
import { toast } from "react-toastify";

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export default function Indextagging() {
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const imgRef = useRef(null);
  const canvRef = useRef(null);
  const multipleType = useRef(null);
  const mainSectionRef = useRef(null);

  const imagesData = useSelector((state) => state.imageTaggingReducer);

  const [selectedSeries, setSelectedSeries] = useState(selectedSeriesfromLocal);
  const [disabled, setDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const [params, setParams] = useState({
  //   type: "Baptism",
  // });
  const [bbdCheck, setBbdCheck] = useState([]);
  const [open, setOpen] = useState(false);
  const [imgComment, setImgComment] = useState("");
  const [imgType, setImgType] = useState({
    type: Object.entries(languageCode)[0][0],
    status: Object.entries(languageCode)[0][1],
  }); //Language
  const [records, setRecords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState(imagesData);

  const [lineNo, setLineNo] = useState(0);
  const [localData, setLocalData] = useState({});
  const [scale, setScale] = useState(1);

  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [imgCode, setImgCode] = useState(0);
  const [imgEvent, setImgEvent] = useState(null); //MultipleType
  const [imageHW, setImageHW] = useState(null);
  const [fieldSequenceState, setFieldSequenceState] = useState([]);

  //=====================================================
  //sideeffects and others start
  //=======================================================

  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];

    document.title = "Index Tagging | Image Tagging | Data Keying";
    dispatch(getImageData(selectedSeries));

    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedSeriesfromLocal) {
      setSelectedSeries(selectedSeriesfromLocal);
      dispatch(getImageData(selectedSeriesfromLocal));
    }
    // Returns true if the request was successful.
  }, [selectedSeriesfromLocal]);

  useEffect(() => {
    setImages(imagesData);
  }, [imagesData]);

  useEffect(() => {
    window.addEventListener("resize", settingRation);
    let timer1 = setTimeout(() => settingRation(), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [widthRatio, heightRatio]);

  //=====================================================
  //sideeffects and others end
  //=======================================================

  //-----------------Language start---------------
  const handleLanguage = (event) => {
    const selectedType = event.target.value;
    setImgType({ type: selectedType, status: languageCode[selectedType] });
  };
  //-----------------Language End---------------

  //=====================================================
  //Getting the height and width ratio start
  //=======================================================

  function settingRation() {
    if (document !== undefined) {
      var windowSize = document.querySelectorAll("#imagesss");
      var w = windowSize[0].clientWidth;
      var h = windowSize[0].clientHeight;
      var myImg = document.querySelector("#imagesss");
      var realWidth = myImg.naturalWidth;
      var realHeight = myImg.naturalHeight;
      setImageHW({ width: realWidth, height: realHeight });

      var widthRatios = w / realWidth;
      var heightRatios = h / realHeight;

      setWidthRatio(widthRatios);
      setHeightRatio(heightRatios);
    }
  }

  //=====================================================
  //Getting the height and width ratio end
  //=======================================================

  //=====================================================
  //--------------------images Bbd check  start----------------------
  // Opens a dialog for bbd confirmation also set clicked status whether it is bad or blank or duplicate.
  const handleClickOpen = (name) => {
    setImgComment(name);
    setOpen(true);
  };

  // Closes the bbd confirmation dialog.
  const handleClose = () => {
    setOpen(false);
  };

  // Checks and set the BBD status for an image.
  const handleBBD = (name) => {
    //for  select image as bbd
    const status = getBBDStatusCode(imgComment);
    images.map((image) => {
      if (image.IMAGE_NUMBER === name.IMAGE_NUMBER) {
        return dispatch(
          updateImageComment({
            COMMENTS: status,
            IMAGE_NUMBER: image.IMAGE_NUMBER,
            SERIES_NUMBER: image.SERIES_NUMBER,
            IMAGE_WIDTH: imageHW?.width,
            IMAGE_HEIGHT: imageHW?.height,
          })
        );
      } else {
        return image;
      }
    });

    setBbdCheck([
      ...bbdCheck,
      {
        imageType: imgComment,
        imageName: name.name,
        imageLocation: name.location,
        imageId: name.id,
      },
    ]);

    setOpen(false);
    goToNextSlide();
  };

  //-------------------images Bbd check end---------------------
  //=======================================================

  // Removes all matched elements.
  const removeElements = (elms) => {
    //for removing child element
    elms.forEach((el) => el.remove());
  };

  //=====================================================
  //prev and next images start
  //=======================================================

  let hasNext = currentIndex < images.length - 1;

  const goToNextSlide = () => {
    setLineNo(0);
    if (hasNext) {
      setCurrentIndex(currentIndex + 1);
    }
    removeElements(document.querySelectorAll("canvas"));
  };

  //=====================================================
  //prev and next images end
  //=======================================================

  //=====================================================
  //radio button click menu change start
  //=======================================================

  const handleDataType = (event) => {
    const selectedType = event.target.value;
    setLineNo(0);
    clearTag(event);
    if (selectedType === "Mixed") {
      setImgCode(MIXED_TYPE_CODE);
      setImgEvent(DEFAULT_TYPE);
    } else {
      const dataTypeObject = allFieldsObject[pascalToCamelCase(selectedType)];
      setImgCode(dataTypeObject.imgCode);
      setImgEvent(null);
    }
  };
  const handleMultipleType = (event) => {
    const selectedType = event.target.value;
    if (
      allFieldsObject[selectedType].status === "active" &&
      selectedType !== "Mixed"
    ) {
      setImgEvent(selectedType);
    }
  };

  //=====================================================
  //radio button click menu change end
  //=======================================================

  //=====================================================
  //enable and disabling button start
  //=======================================================

  const checkingTag = () => {
    let cavCount = document.querySelectorAll(".hidden-tag");
    cavCount.length > 0 ? setDisabled(true) : setDisabled(false);
  };

  useEffect(() => {
    checkingTag();
  });

  //=====================================================
  //enable and disabling button end
  //=======================================================

  //=====================================================
  //image zooming start
  //=======================================================

  var setTransform = () => {
    const zoomImg = canvRef.current;
    zoomImg.style.transform = "scale(" + scale + ")";
  };
  useEffect(() => {
    setTransform();
  }, [scale]);
  const zooming = (e) => {
    if (!e.altKey && !e.shiftKey) {
      disableScroll.on();
      var delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
      delta > 0
        ? setScale((prevScale) => (prevScale *= 1.08).toFixed(2))
        : setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
      setTransform();
    } else {
      disableScroll.off();
    }
  };

  //=====================================================
  //image zooming end
  //=======================================================

  //=====================================================
  //onclick menu showing and event handling start
  //=======================================================

  const handleMenu = (e) => {
    e.preventDefault();
    const appendRef = canvRef.current;
    const bounds = appendRef.getBoundingClientRect();
    const newPosx =
      scale > 0
        ? (e.clientX - bounds.left) / scale
        : (e.clientX - bounds.left) * scale;
    const newPosy =
      scale > 0
        ? (e.clientY - bounds.top) / scale
        : (e.clientY - bounds.top) * scale;

    let newPos = { x: newPosx / widthRatio, y: newPosy / heightRatio };
    newPos.x !== Infinity && getFieldValue(e, newPos);
  };

  //=====================================================
  //onclick menu showing and event handling end
  //=======================================================

  //=======================================================
  //Mixed DATA TYPE handeling start
  //=====================================================
  useEffect(() => {
    if (imgCode !== 0) {
      const fields = getDataObjectByImgCode(allFieldsObject, imgCode).fields;
      const fieldSecuences = fieldSequenceGenerator(
        fields,
        selectedSeries,
        imgCode === MIXED_TYPE_CODE ? DEFAULT_TYPE : imgCode
      );

      setFieldSequenceState([fieldSecuences]);

      multipleType.current.disabled =
        imgCode === MIXED_TYPE_CODE ? false : true;
    }
  }, [imgCode]);

  useEffect(() => {
    if (imgEvent !== null) {
      const dataTypeObject = allFieldsObject[pascalToCamelCase(imgEvent)];
      const fieldSecuences = fieldSequenceGenerator(
        dataTypeObject.fields,
        selectedSeries,
        dataTypeObject.imgCode
      );

      setFieldSequenceState((prevState) => {
        if (prevState.some(({ CODE }) => CODE === fieldSecuences.CODE)) {
          return prevState;
        } else return [...prevState, fieldSecuences];
      });
    }
  }, [imgEvent]);

  //=====================================================
  //Mixed DATA TYPE handeling end
  //=======================================================

  //=====================================================
  //tagging on image start
  //=======================================================

  const createTag = (e, positionx, positiony) => {
    e.preventDefault();

    const appendTag = canvRef.current;

    var tagLine = document.createElement("div");

    tagLine.style.display = "none";
    tagLine.style.width = "200px";
    tagLine.style.background = "blue";
    tagLine.style.height = "3px";
    tagLine.style.position = "absolute";
    tagLine.style.left = positionx * widthRatio + "px";
    tagLine.style.top = positiony * widthRatio + "px";
    tagLine.className = "hidden-tag";

    appendTag.appendChild(tagLine);

    // handleSubmit();
  };

  //=====================================================
  //tagging on image end
  //=======================================================

  //=====================================================
  //right click hide start
  //=======================================================

  const handleMenuShowing = (e) => {
    const isClicked = !mainSectionRef.current.contains(e.target);
  };

  const getFieldValue = (e, newpos) => {
    let positionx = parseInt(newpos.x);
    let positiony = parseInt(newpos.y);
    let imageName = images[currentIndex].IMAGE_NAME;
    let imageId = images[currentIndex].IMAGE_NUMBER;

    setRecords([
      ...records,
      {
        LEFT_XCORD: positionx,
        RIGHT_XCORD: 200,
        TOP_YCORD: positiony,
        BOTTOM_YCORD: 3,
        IMAGE_NAME: imageName,
        IMAGE_TYPE: imgType.status,
        imageId: imageId,
        line: lineNo + 1,
        SERIES_NUMBER: selectedSeries,
        code: imgCode,
        EVENT_TYPE: imgEvent,
        IMAGE_WIDTH: imageHW?.width,
        IMAGE_HEIGHT: imageHW?.height,
      },
    ]);
    createTag(e, positionx, positiony);
  };

  //=====================================================
  //right button click menu end
  //=======================================================

  //=====================================================
  //remove last tag - start
  //=======================================================

  const clearTag = (e) => {
    e.preventDefault();
    var blueLastTag = document.querySelectorAll(".hidden-tag");
    if (e.type === "contextmenu") {
      if (blueLastTag.length !== 0) {
        blueLastTag[blueLastTag.length - 1].remove();
        records.pop();
        setRecords([...records]);
      }
    } else {
      for (let i = 0; i < blueLastTag.length; i++) {
        blueLastTag[i].remove();
      }
      setRecords([]);
    }
  };

  //=====================================================
  //remove last tag - end
  //=======================================================

  //=====================================================
  //save data to localstorage and retrieve start
  //=======================================================

  const handleSave = () => {
    setImgComment((prevSate) => {
      return prevSate + "Tag";
    });
    setIsChecked(false);
    setIsDisabled(false);

    var localStorageValues = JSON.parse(localStorage.getItem("taggedData"));
    if (localStorageValues) {
      dispatch(imageDataInsertIndex(localStorageValues));
      localStorage.removeItem("taggedData");

      // Sets the data fields secuence for the selected data type.
      if (fieldSequenceState.length > 0) {
        dispatch(setFieldSequence(fieldSequenceState));
        setFieldSequenceState([]);
      }
      setRecords([]);
      // handleBBD(images[currentIndex]);

      removeElements(document.querySelectorAll(".hidden-tag"));
      goToNextSlide();
    }
    if (!hasNext) {
      history.replace("/series");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [records]);
  const handleSubmit = () => {
    localStorage.setItem("taggedData", JSON.stringify(records));
    setLineNo(records?.length);

    document.querySelectorAll(".hidden-tag").forEach((el) => {
      el.style.display = "block";
    });
  };
  // get tagged data from local storage

  useEffect(() => {
    var localStorageValues = JSON.parse(localStorage.getItem("taggedData"));
    if (localStorageValues) {
      setLocalData([...localStorageValues]);
    }
  }, []);

  //remove data from localstorage every minute
  useEffect(() => {
    const interval = setInterval(() => {
      localStorage.removeItem("taggedData");
    }, 60 * 1000);
    return () => clearInterval(interval);
  });

  //=====================================================
  //save data to localstorage  and retrieve end
  //=======================================================

  //=====================================================
  //image rotation  start
  //=======================================================

  // var current_rotation = 0;
  // const handleRotation2 = () => {
  //   const imgRot = imgRef.current;
  //   current_rotation += 90; // rotate clockwise by 90 degrees
  //   imgRot.style.transform = "rotate(" + current_rotation + "deg)";
  // };

  //=====================================================
  //image rotation  end
  //=======================================================

  //=====================================================
  //Handle First Record Connection   Start
  //=======================================================

  const handleFirstRecordConnection = (e) => {
    if (records) {
      setRecords([]);
      removeElements(document.querySelectorAll(".hidden-tag"));
      dispatch(imageDataInsertForFRC(records));
      setIsChecked(true);
      setIsDisabled(true);
    } else {
      toast.warn("Tag the image first");
    }
  };

  //=====================================================
  //Handle First Record Connection   end
  //=======================================================

  return (
    <>
      <FullScreen></FullScreen>
      <Sidebar></Sidebar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        status={imgComment}
      >
        <DialogTitle id="alert-dialog-title">
          Current image has been marked as
          <span style={{ textTransform: "uppercase" }}> {imgComment}</span>{" "}
          image
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() => handleBBD(images[currentIndex])}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{ flexGrow: 1, height: "70vh", overflow: "scroll" }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                className="zoom"
                onMouseLeave={() => disableScroll.off()}
                onMouseEnter={() => disableScroll.on()}
                onMouseOver={() => disableScroll.on()}
                onWheel={(e) => zooming(e)}
              >
                {images.length > 0 && (
                  <img
                    style={{ position: "relative" }}
                    className="imageContainer"
                    tabIndex="-1"
                    ref={imgRef}
                    src={`${imageBaseUrl}${selectedSeries}/${images[currentIndex]?.IMAGE_NAME}`}
                    onContextMenu={(e) => clearTag(e)}
                    onClick={(e) =>
                      imgCode === 0
                        ? notifyWarn("Select a Data Type First.")
                        : handleMenu(e)
                    }
                    alt="img"
                    id="imagesss"
                  />
                )}
              </div>

              {/* must use tabIndex  otherwise keyboard event wont work */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box
        sx={{ flexGrow: 1, height: "20vh", px: 3 }}
        onClick={(e) => handleMenuShowing(e)}
      >
        <Grid
          container
          item
          lg={12}
          spacing={2}
          py={0.4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item sx={{ height: "26px", paddingTop: "6px!important" }}>
            <Typography
              variant="p"
              color="error"
              fontSize={10}
              sx={{ fontWeight: "bold" }}
            >
              Showing : ({currentIndex + 1} of {imagesData?.length}) | Series:{" "}
              {selectedSeries} | Image : {images[currentIndex]?.IMAGE_NAME} |
              Tag Count: {lineNo}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} spacing={1} py={0.4}>
          <Grid item lg={6}>
            <FormControl component="fieldset" disabled={disabled}>
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
              >
                Language
              </FormLabel>
              <RadioGroup
                row
                aria-label="language"
                name="row-radio-buttons-group"
                defaultValue="German"
                value={imgType.type}
                onChange={handleLanguage}
                sx={{ my: 0 }}
              >
                {Object.entries(languageCode).map(([key, value]) => (
                  <FormControlLabel
                    key={value}
                    sx={{ marginBottom: 0 }}
                    value={key}
                    control={<Radio size="small" />}
                    label={<span style={{ fontSize: "13px" }}>{key}</span>}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {/* <Button
                disabled={!hasNext}
                sx={{ fontSize: "12px" }}
                color="primary"
                onClick={goToNextSlide}
                variant="outlined"
              >
                Next Image
              </Button> */}

              {/* <Button onClick={goToPrevSlide} color="secondary" variant="outlined" >
                                        Prev Image
                                        </Button> */}
              <Button
                sx={{ fontSize: "12px", my: 0.4 }}
                color="success"
                disabled={!disabled}
                variant="outlined"
                onClick={handleSave}
                size="small"
              >
                Save
              </Button>

              {/* <Typography variant="p" color="error" fontSize={13}>
                Showing : ({currentIndex + 1} of {imagesData?.length}) Name :{" "}
                {images[currentIndex]?.IMAGE_NAME}
              </Typography> */}
            </Stack>
          </Grid>
          <Grid item lg={6}>
            <Stack direction="row">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleFirstRecordConnection}
                      // disabled={isDisabled}
                      disabled
                      size="small"
                    />
                  }
                  sx={{ my: 0, mx: 0 }}
                  label={
                    <span style={{ fontSize: "13px", mx: 0 }}>
                      First record connection
                    </span>
                  }
                />
                {/* <FormControlLabel control={<Checkbox />} label="CopyZone" /> */}
              </FormGroup>
              <Box>
                <Button
                  sx={{ mx: 1, my: 1, fontSize: "12px" }}
                  color="primary"
                  variant="outlined"
                  onClick={() => handleClickOpen("Continue")}
                  size="small"
                >
                  continue
                </Button>
                <Button
                  sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                  color="secondary"
                  variant="outlined"
                  onClick={(e) => clearTag(e)}
                  size="small"
                >
                  Clear All Tag
                </Button>
                <Button
                  sx={{ fontSize: "12px" }}
                  startIcon={<RotateLeftIcon />}
                  color="primary"
                  onClick={() => handleRotation(imgRef)}
                  variant="outlined"
                  size="small"
                >
                  Rotate
                </Button>
              </Box>
            </Stack>
            <Stack direction="row">
              <Button
                sx={{ fontSize: "12px", mx: 1, my: 0.4 }}
                color="info"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Blank")}
                size="small"
              >
                Blank
              </Button>
              <Button
                sx={{ fontSize: "12px", mx: 1, my: 0.4 }}
                color="error"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Bad")}
                size="small"
              >
                Bad
              </Button>
              <Button
                sx={{ fontSize: "12px", my: 0.4 }}
                color="warning"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Duplicate")}
                size="small"
              >
                Duplicate
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="primary"
                variant="outlined"
                onClick={() => handleClickOpen("NoExtDataImage")}
                size="small"
              >
                No Ext DataImage
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="secondary"
                variant="outlined"
                size="small"
              >
                Other Language
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} spacing={2} py={0.4}>
          <Grid item lg={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
              >
                Data Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="types"
                name="row-radio-buttons-group"
                defaultValue=""
                // value={params.type}
                onChange={handleDataType}
              >
                {Object.values(allFieldsObject)
                  .filter((field) => field.status === "active")
                  .map((field) => (
                    <FormControlLabel
                      key={field.value}
                      value={field.value}
                      control={<Radio size="small" />}
                      label={
                        <span style={{ fontSize: "13px" }}>{field.name}</span>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl
              component="fieldset"
              ref={multipleType}
              disabled={imgCode !== MIXED_TYPE_CODE}
            >
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
              >
                Multiple Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="types"
                name="row-radio-buttons-group"
                value={imgCode === MIXED_TYPE_CODE && imgEvent}
                onChange={handleMultipleType}
              >
                {Object.values(allFieldsObject)
                  .filter(
                    (field) =>
                      field.status === "active" && field.name !== "Mixed"
                  )
                  .map((field) => (
                    <FormControlLabel
                      key={field.value}
                      value={field.value}
                      control={<Radio size="small" />}
                      label={
                        <span style={{ fontSize: "13px" }}>{field.name}</span>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
