import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

export default function FloatingMenu({
  rightMenu,
  setSelectedSort,
  selectedSort,
  closePopupMenu,
}) {
  const [menuOptionArray, setMenuOptionArray] = useState([
    { name: "Sort Ascending", id: 1, disabled: null },
    { name: "Sort Descending", id: 2, disabled: null },
    { name: "Clear Sorting", id: 3, disabled: true },
  ]);
  useEffect(() => {
    if (selectedSort !== null) {
      setMenuOptionArray(
        menuOptionArray.map((obj) =>
          obj.name === selectedSort
            ? { ...obj, disabled: true }
            : { ...obj, disabled: null }
        )
      );
    }
  }, [selectedSort]);
  return (
    <>
      <Box
        sx={{
          maxWidth: 250,
          bgcolor: "background.paper",
          boxShadow: "2px 2px 3px #888888",
          paddingX: "20px",
        }}
        ref={rightMenu}
        className="container__menu container__menu_hidden"
      >
        <List>
          {menuOptionArray?.map((option) => {
            return (
              <ListItem
                key={option.id}
                disablePadding
                sx={{ borderBottom: "1px solid #d1d1d199" }}
              >
                <ListItemButton
                  component="a"
                  onClick={(e) => {
                    setSelectedSort(e.target.textContent);
                    // setMenuOptionArray(
                    //   menuOptionArray.map((obj) =>
                    //     obj.id === option.id
                    //       ? { ...obj, disabled: true }
                    //       : { ...obj, disabled: null }
                    //   )
                    // );
                    closePopupMenu();
                  }}
                  disabled={option.disabled}
                  name={option.name}
                  //   ref={keyevent}
                  sx={{ fontSize: "12.5px", paddingY: "2px" }}
                >
                  <ListItemText
                    primary={option?.name}
                    sx={{ margin: 0, fontSize: "10px" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </>
  );
}
