//databaseNameGenerator
//standardNameGenerator
//newFieldObjectGenerator
//newFieldObjectGeneratorForParent
//menuFieldsGenerator
//allFieldsGenerator
//parentFieldsGenerator
//fieldSequenceOnjectGenerator
//fieldSequenceOnjectListGenerator
//fieldSequenceObjectGenerator
//addPrefixSuffix
//onscreenKeyboardHandle
//onscreenKeyboardHandleOnEntry
//createCanvas
//handleTableRaw
//handleAction
//calculateTotalFromArray
//travelAcrossArray
//handleDataError
//handleDataError

import { blankUpdate } from "../../Redux/Actions/allCheckingAction";
import {
  changeAImageStatus,
  entryComplete,
  retagAImage,
} from "../../Redux/Actions/bbdCheckingAction";
import { MIXED_TYPE_CODE } from "./constants";
import { handleTableHeader } from "./editHelper";
import { allFieldsObject } from "./entryField";
import { validateFunc } from "./entryHelper";

// Function to convert PascalCase to camelCase
export const pascalToCamelCase = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

// Generates database field name for a field name.
const databaseNameGenerator = (name) => {
  const newName = name.replace(/ +/g, "_");
  return newName.toUpperCase();
};
// Generates database field name for a field name.
const standardNameGenerator = (name) => {
  const newName = name?.toLowerCase()?.replace(/_pos+/g, "");
  return newName?.replace(/_+/g, " ");
};

// Generates a new field object from field name and a key.
// It receive tag or entry as type. and generate two different formate of object.
const newFieldObjectGenerator = (type, field, index, code = 0) => {
  return type == "tag"
    ? {
        id: index + 1,
        name: field.name,
        dbName: databaseNameGenerator(field.dbName || field.name),
        disabled: null,
        keyCode: field.keyCode,
        keyName: String.fromCharCode(field.keyCode),
        taggedTime: "",
      }
    : {
        labelName:
          code === 8
            ? field === "MARRIAGE_DATE"
              ? "MARRIAGE_INTENT_DATE"
              : field === "MARRIAGE_DATE_SECOND"
              ? "MARRIAGE_DATE"
              : field
            : field,
        fieldName: databaseNameGenerator(
          code === 8
            ? field === "Marriage Intent Date"
              ? "MARRIAGE_DATE"
              : field === "Marriage Date"
              ? "MARRIAGE_DATE_SECOND"
              : field
            : field
        ),
        active: field.toUpperCase().includes("SUFFIX")
          ? false
          : field.toUpperCase().includes("PREFIX")
          ? false
          : true,
        fieldIndex: index + 1,
        inpType: "text",
        inpVal: "",
        searchField: true,
      };
};
const newFieldObjectGeneratorForParent = (field, index, isActive, code = 0) => {
  return {
    labelName:
      code === 8
        ? field === "Marriage Date"
          ? "Marriage Intent Date "
          : field === "Marriage Date Second"
          ? "Marriage Date"
          : field
        : field,
    fieldName: databaseNameGenerator(field),
    active: isActive,
    fieldIndex: index + 1,
    inpType: "text",
    inpVal: "",
    searchField: true,
  };
};

/**
 * Get the dataObject associated with a given imgCode from the provided allFields object.
 * If the imgCode is 7 (indicating "Mixed"), return the first dataObject.
 * @param {Object} allFieldsObject - An object containing fields information keyed by category.
 * @param {number} imgCode - The imgCode for which fields are to be retrieved.
 * @returns {Object} dataObject- An object containing the all the necessary props ({name, value, imgCode, fields}) associated with the specified imgCode.
 */
export const getDataObjectByImgCode = (allFieldsObject, imgCode) => {
  const MIXED_TYPE_CODE = 7;
  if (imgCode === MIXED_TYPE_CODE) {
    return Object.values(allFieldsObject)[0];
  } else {
    const matchingDataObject = Object.values(allFieldsObject).find(
      (value) => value.imgCode === imgCode
    );
    return matchingDataObject || Object.values(allFieldsObject)[0];
  }
};

// Generates a menulist as an array of object.
export const menuFieldsGenerator = (type, fields) => {
  const tempfields = [...fields];
  return tempfields.map((field, index) =>
    newFieldObjectGenerator(type, field, index)
  );
};

// Generates ENTRY Field array for the given PARENT fields.
export const allFieldsGenerator = (type, fields, code) => {
  const tempfields = [...fields];
  let newArr = [];
  const SUFFIX = "GIVENNAME";
  const PREFIX = "SURNAME";
  const tempF = ["SUFFIX", "GIVENNAME", "SURNAME", "PREFIX"];

  /*
  * Uncomment the following line if maidenname is required by the project.
  const tempM = ["SUFFIX", "GIVENNAME", "MAIDENNAME", "SURNAME", "PREFIX"];
  */

  tempfields.map((field) => {
    const fieldNameArray = field.split("_");
    if (fieldNameArray[fieldNameArray.length - 1] === "NAMES") {
      /*
       * Uncomment this block if maidenname is required by the project.
      
      if (field.includes("MOTHER") || field.includes("SPOUSE")) {
        tempM.map((t) => {
          fieldNameArray[fieldNameArray.length - 1] = t;
          newArr.push(fieldNameArray.join("_"));
        });
      } else
      */
      tempF.map((t) => {
        fieldNameArray[fieldNameArray.length - 1] = t;
        newArr.push(fieldNameArray.join("_"));
      });
    } else newArr.push(field);
  });
  return newArr.map((field, index) =>
    newFieldObjectGenerator(type, field, index, code)
  );
};

// Generates a parent list of fields for CTRLF2 menu(UPDATED).
export const parentFieldsGenerator = (fields, selectedFields, code = 0) => {
  const tempfields = fields.map((field) => field.dbName || field.name);
  return tempfields.map((field, index) =>
    selectedFields.includes(databaseNameGenerator(field))
      ? newFieldObjectGeneratorForParent(field, index, true, code)
      : newFieldObjectGeneratorForParent(field, index, false, code)
  );
};
// Generates a parent list of fields for CTRLF2 menu.
// export const parentFieldsGenerator = (type, fields) => {
//   const tempfields = [...fields];
//   const temp = ["Prefix", "GivenName", "MaidenName", "SurName", "Suffix"];
//   for (let t in temp) {
//     tempfields.map((field, index) => {
//       const fieldNameArray = field.split(" ");
//       if (fieldNameArray[fieldNameArray.length - 1] == temp[t]) {
//         tempfields[index] = tempfields[index].replace(temp[t], "Names");
//       }
//     });
//   }
//   return [...new Set(tempfields)].map((field, index) =>
//     newFieldObjectGenerator(type, field, index)
//   );
// };

// Generates a fields sequence object to update the sequence from entry page.
export const fieldSequenceOnjectGenerator = (fields, selectedSeries, code) => {
  return {
    SERIES_NUMBER: selectedSeries,
    fieldName: fieldSequenceOnjectListGenerator(fields),
    CODE: code,
    LINE: 1,
  };
};

// Generates fields sequence for the entry page.
const fieldSequenceOnjectListGenerator = (fields) => {
  const tempfields = [...fields];
  const newFields = [];
  tempfields.map((field, index) => {
    newFields.push(fieldSequenceObjectGenerator(field, index));
  });
  return newFields;
};

// Generates a fields sequence object for the Tag page.
export const fieldSequenceGenerator = (
  fields,
  selectedSeries,
  code,
  imgEvent
) => {
  return {
    SERIES_NUMBER: selectedSeries,
    fieldName: fieldSequenceList(fields, code),
    CODE: code,
    LINE: 1,
  };
};

// Generates a fields sequence for the Tag page.
const fieldSequenceList = (fields, code = 0) => {
  const tempfields = [...fields];
  return tempfields.map((field, index) =>
    fieldSequenceObjectGenerator(field.name, index, code)
  );
};
// Generates a object dtructure for a single field.
const fieldSequenceObjectGenerator = (field, index, code) => {
  if (code === 8) {
    if (field === "Marriage Intent Date") {
      return {
        fieldName: databaseNameGenerator("Marriage Date"),
        value: index + 1,
      };
    } else if (field === "Marriage Date") {
      return {
        fieldName: databaseNameGenerator("Marriage Date Second"),
        value: index + 1,
      };
    } else
      return {
        fieldName: databaseNameGenerator(field),
        value: index + 1,
      };
  } else
    return {
      fieldName: databaseNameGenerator(field),
      value: index + 1,
    };
};

export const addPrefixSuffix = (selectedField, fields) => {
  const tempfields = [...fields];
  let newArr = [];
  if (selectedField === "GIVENNAME" || selectedField === "SURNAME") {
    tempfields.map((field) => {
      if (field.fieldName === "SUFFIX" || field.fieldName === "PREFIX") {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  } else if (
    selectedField === "FATHER_GIVENNAME" ||
    selectedField === "FATHER_SURNAME"
  ) {
    tempfields.map((field) => {
      if (
        field.fieldName === "FATHER_SUFFIX" ||
        field.fieldName === "FATHER_PREFIX"
      ) {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  } else if (
    selectedField === "MOTHER_GIVENNAME" ||
    selectedField === "MOTHER_SURNAME"
    // || selectedField === "MOTHER_MAIDENNAME"
  ) {
    tempfields.map((field) => {
      if (
        field.fieldName === "MOTHER_SUFFIX" ||
        field.fieldName === "MOTHER_PREFIX"
      ) {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  } else if (
    selectedField === "SPOUSE_GIVENNAME" ||
    selectedField === "SPOUSE_SURNAME"
    // || selectedField === "SPOUSE_MAIDENNAME"
  ) {
    tempfields.map((field) => {
      if (
        field.fieldName === "SPOUSE_SUFFIX" ||
        field.fieldName === "SPOUSE_PREFIX"
      ) {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  } else if (
    selectedField === "SPOUSE_FATHER_GIVENNAME" ||
    selectedField === "SPOUSE_FATHER_SURNAME"
  ) {
    tempfields.map((field) => {
      if (
        field.fieldName === "SPOUSE_FATHER_SUFFIX" ||
        field.fieldName === "SPOUSE_FATHER_PREFIX"
      ) {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  } else if (
    selectedField === "SPOUSE_MOTHER_GIVENNAME" ||
    selectedField === "SPOUSE_MOTHER_SURNAME"
    // || selectedField === "SPOUSE_MOTHER_MAIDENNAME"
  ) {
    tempfields.map((field) => {
      if (
        field.fieldName === "SPOUSE_MOTHER_SUFFIX" ||
        field.fieldName === "SPOUSE_MOTHER_PREFIX"
      ) {
        newArr.push({ ...field, active: true });
      } else newArr.push(field);
    });
  }
  return newArr;
};

export const onscreenKeyboardHandle = (e, value, setUpdatedValue) => {
  e.preventDefault();
  // get old value
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  var oldValue = e.target.value;

  // replace point and change input value
  var newValue = oldValue.slice(0, start) + value + oldValue.slice(end);
  e.target.value = newValue;
  setUpdatedValue(newValue);
  // replace cursor
  e.target.selectionStart = e.target.selectionEnd = start + 1;
};
export const onscreenKeyboardHandleOnEntry = (e, value) => {
  e.preventDefault();
  // get old value
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  var oldValue = e.target.value;

  // replace point and change input value
  var newValue = oldValue.slice(0, start) + value + oldValue.slice(end);
  e.target.value = newValue;
  // replace cursor
  e.target.selectionStart = e.target.selectionEnd = start + 1;
};

//Canvas Creator //Final version
export const createCanvas = (
  canvasId,
  fieldName,
  x,
  y,
  w,
  h,
  color,
  opacityData
) => {
  const cvs = document.createElement("canvas");
  cvs.title = standardNameGenerator(fieldName);
  cvs.id = canvasId;
  cvs.className = "canvasClass";
  cvs.style.position = "absolute";
  cvs.style.left = x;
  cvs.style.top = y;
  cvs.style.width = w;
  cvs.style.height = h;
  cvs.style.background = color;
  cvs.style.opacity = opacityData;

  return cvs;
};

// export const handleTableRaw = (imagesData, selectedType) => {
//   if (selectedType == "BIRTH") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 1) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "DEATH") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 2) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "MARRIAGE") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 3) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "BANKS") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 4) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "BAPTISM") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 5) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "BURIAL") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 6) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "MIXED") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 7) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "REGISTER") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 8) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "COVERPAGE") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["CODE"] == 9) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "BAD") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -9) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "BLANK") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -99) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "DUPLICATE") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -999) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "CONTINUE") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -8) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "CAPTURE") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -88) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "INDEX") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == -888) {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "TARGET") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] ==  {
//         x.push(images);
//       }
//     });
//     return x;
//   } else if (selectedType == "ENTRY") {
//     let x = [];
//     imagesData.filter((images) => {
//       if (images["COMMENTS"] == 1) {
//         x.push(images);
//       }
//     });
//     return x;
//   }
// };

export const handleTableRaw = (imagesData, selectedType) => {
  return imagesData.filter((images) => {
    if (selectedType == "BIRTH") {
      return images["CODE"] === 1;
    } else if (selectedType === "DEATH") {
      return images["CODE"] === 2;
    } else if (selectedType === "MARRIAGE") {
      return images["CODE"] === 3;
    } else if (selectedType === "BANKS") {
      return images["CODE"] === 4;
    } else if (selectedType === "BAPTISM") {
      return images["CODE"] === 5;
    } else if (selectedType === "BURIAL") {
      return images["CODE"] === 6;
    } else if (selectedType === "MIXED") {
      return images["CODE"] === 7;
    } else if (selectedType === "MARRIAGE_INTENT") {
      return images["CODE"] === 8;
    } else if (selectedType === "COVERPAGE") {
      return images["CODE"] === 9;
    } else if (selectedType === "BAD") {
      return images["COMMENTS"] === -9;
    } else if (selectedType == "BLANK") {
      return images["COMMENTS"] === -99;
    } else if (selectedType == "DUPLICATE") {
      return images["COMMENTS"] === -999;
    } else if (selectedType == "CONTINUE") {
      return images["COMMENTS"] === -8;
    } else if (selectedType == "CAPTURE") {
      return images["COMMENTS"] === -88;
    } else if (selectedType == "INDEX") {
      return images["COMMENTS"] === -888;
    } else if (selectedType == "TARGET") {
      return images["COMMENTS"] === -8888;
    } else if (selectedType == "ENTRY") {
      return images["COMMENTS"] === 1;
    } else if (selectedType == "ERROR") {
      return (
        images["COMMENTS"] !== 1 &&
        images["COMMENTS"] !== -8888 &&
        images["COMMENTS"] !== -888 &&
        images["COMMENTS"] !== -88 &&
        images["COMMENTS"] !== -8 &&
        images["COMMENTS"] !== -999 &&
        images["COMMENTS"] !== -99 &&
        images["COMMENTS"] !== -9 &&
        images["CODE"] !== 1 &&
        images["CODE"] !== 2 &&
        images["CODE"] !== 3 &&
        images["CODE"] !== 4 &&
        images["CODE"] !== 5 &&
        images["CODE"] !== 6 &&
        images["CODE"] !== 7 &&
        images["CODE"] !== 8 &&
        images["CODE"] !== 9
      );
    }
  });
};

export const handleAction = (dispatch, action, image, close) => {
  if (action === "RETAG") {
    dispatch(retagAImage(image?.IMAGE_NUMBER));
  }
  if (action === "ENTRY_COMPLETE") {
    dispatch(entryComplete(image?.IMAGE_NUMBER));
  }
  if (action === "BLANK_UPDATE") {
    dispatch(blankUpdate(image));
  }
  if (action == "BAD") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -9, image?.CODE));
  }
  if (action == "BLANK") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -99, image?.CODE));
  }
  if (action == "DUPLICATE") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -999, image?.CODE));
  }
  if (action === "CONTINUE") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -8, image?.CODE));
  }
  if (action === "CAPTURE") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -88, image?.CODE));
  }
  if (action === "INDEX") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -888, image?.CODE));
  }
  if (action === "TARGET") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, -8888, image?.CODE));
  }
  if (action === "BIRTH") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 1));
  }
  if (action === "DEATH") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 2));
  }
  if (action === "MARRIAGE") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 3));
  }
  if (action === "BAPTISM") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 5));
  }
  if (action === "BURIAL") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 6));
  }
  if (action === "MIXED") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 7));
  }
  if (action === "REGISTER") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 8));
  }
  if (action === "COVERPAGE") {
    dispatch(changeAImageStatus(image?.IMAGE_NUMBER, image?.COMMENTS, 9));
  }
  close();
  // return imagesData.filter((images) => {
  //   if (selectedType == "BIRTH") {
  //     return images["CODE"] === 1;
  //   } else if (selectedType === "DEATH") {
  //     return images["CODE"] === 2;
  //   } else if (selectedType === "MARRIAGE") {
  //     return images["CODE"] === 3;
  //   } else if (selectedType === "BANKS") {
  //     return images["CODE"] === 4;
  //   } else if (selectedType === "BAPTISM") {
  //     return images["CODE"] === 5;
  //   } else if (selectedType === "BURIAL") {
  //     return images["CODE"] === 6;
  //   } else if (selectedType === "MIXED") {
  //     return images["CODE"] === 7;
  //   } else if (selectedType === "REGISTER") {
  //     return images["CODE"] === 8;
  //   } else if (selectedType === "COVERPAGE") {
  //     return images["CODE"] === 9;
  //   } else if (selectedType === "BAD") {
  //     return images[""] === -9;
  //   } else if (selectedType == "BLANK") {
  //     return images["COMMENTS"] === -99;
  //   } else if (selectedType == "DUPLICATE") {
  //     return images["COMMENTS"] === -999;
  //   } else if (selectedType == "CONTINUE") {
  //     return images["COMMENTS"] === -8;
  //   } else if (selectedType == "CAPTURE") {
  //     return images["COMMENTS"] === -88;
  //   } else if (selectedType == "INDEX") {
  //     return images["COMMENTS"] === -888;
  //   } else if (selectedType == "TARGET") {
  //     return images["COMMENTS"] === -8888;
  //   } else if (selectedType == "ENTRY") {
  //     return images["COMMENTS"] === 1;
  //   } else if (selectedType == "ERROR") {
  //     return (
  //       images["COMMENTS"] !== 1 &&
  //       images["COMMENTS"] !== -8888 &&
  //       images["COMMENTS"] !== -888 &&
  //       images["COMMENTS"] !== -88 &&
  //       images["COMMENTS"] !== -8 &&
  //       images["COMMENTS"] !== -999 &&
  //       images["COMMENTS"] !== -99 &&
  //       images["COMMENTS"] !== -9 &&
  //       images["CODE"] !== 1 &&
  //       images["CODE"] !== 2 &&
  //       images["CODE"] !== 3 &&
  //       images["CODE"] !== 4 &&
  //       images["CODE"] !== 5 &&
  //       images["CODE"] !== 6 &&
  //       images["CODE"] !== 7 &&
  //       images["CODE"] !== 8 &&
  //       images["CODE"] !== 9
  //     );
  //   }
  // });
};

export const calculateTotalFromArray = (selectedFieldData) => {
  return selectedFieldData.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.TOTAL_COUNT,
    0
  );
};
export const travelAcrossArray = (array, currentIndex, direction) => {
  if (direction === "negative") {
    if (currentIndex === 0) {
      return array.length - 1;
    } else return currentIndex - 1;
  } else if (direction === "positive") {
    if (currentIndex === array.length - 1) {
      return 0;
    } else return currentIndex + 1;
  }
};

const dateFormatError = (imagesData, fieldName) => {
  let x = [];
  imagesData.filter((images) => {
    if (!validateFunc(fieldName, images[fieldName].toString())) {
      x.push(images);
    }
  });
  return x;
};
const checkTitleCase = (imagesData, fieldName) => {
  let x = [];
  imagesData.filter((images) => {
    if (!isTitleCase(images[fieldName].toString())) {
      x.push(images);
    }
  });
  return x;
};
const isTitleCase = (str) => {
  const words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word[0] !== word[0].toUpperCase()) {
      return false;
    }
  }
  return true;
};

export const handleDataError = (imagesData, errorType, fieldName) => {
  if (errorType === "ERROR_DATA") {
    if (fieldName.includes("_DATE")) {
      return dateFormatError(imagesData, fieldName);
    } else {
      let x = [];
      imagesData.filter((images) => {
        if (images[fieldName].toString().includes("_DATE")) {
          x.push(images);
        }
      });
      return x;
    }
  } else if (errorType === "DATE_FORMAT_ERROR") {
    return dateFormatError(imagesData, fieldName);
  } else if (errorType === "CASE_ERROR") {
    if (!fieldName.includes("_DATE")) {
      return checkTitleCase(imagesData, fieldName);
    }
  } else return imagesData;

  // } else if (selectedType.value == "11") {
  //   return [];
  // } else if (selectedType.value == "12") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (images["GENDER"] !== null) {
  //       if (
  //         images["GENDER"].toString().toUpperCase() === "MALE" &&
  //         images["GIVENNAME"] !== null &&
  //         (images["GIVENNAME"].toString().toUpperCase().endsWith("A") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith(" A") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("IE") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("INE") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("YOU"))
  //       ) {
  //         x.push(images);
  //       }
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "13") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (images["GENDER"] !== null) {
  //       if (
  //         images["GENDER"].toString().toUpperCase() === "FEMALE" &&
  //         images["GIVENNAME"] !== null &&
  //         (images["GIVENNAME"].toString().toUpperCase().endsWith("O") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("AS") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("US") ||
  //           images["GIVENNAME"].toString().toUpperCase().endsWith("IUS"))
  //       ) {
  //         x.push(images);
  //       }
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "14") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (images["GENDER"] !== null) {
  //       if (images["GENDER"].toString().toUpperCase() === "FEMALE") {
  //         if (
  //           images["SPOUSE_GIVENNAME"] !== null &&
  //           (images["SPOUSE_GIVENNAME"]
  //             .toString()
  //             .toUpperCase()
  //             .endsWith("A") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith(" A") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("IE") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("INE") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("YOU"))
  //         ) {
  //           x.push(images);
  //         }
  //       } else if (images["GENDER"].toString().toUpperCase() === "MALE") {
  //         if (
  //           images["SPOUSE_GIVENNAME"] !== null &&
  //           (images["SPOUSE_GIVENNAME"]
  //             .toString()
  //             .toUpperCase()
  //             .endsWith("O") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("AS") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("US") ||
  //             images["SPOUSE_GIVENNAME"]
  //               .toString()
  //               .toUpperCase()
  //               .endsWith("IUS"))
  //         ) {
  //           x.push(images);
  //         }
  //       }
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "15") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["FATHER_GIVENNAME"] !== null &&
  //       (images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("A") ||
  //         images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith(" A") ||
  //         images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("IE") ||
  //         images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("INE") ||
  //         images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("YOU"))
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "16") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["MOTHER_GIVENNAME"] !== null &&
  //       (images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("O") ||
  //         images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("AS") ||
  //         images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("US") ||
  //         images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("IUS"))
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "17") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["SPOUSE_FATHER_GIVENNAME"] !== null &&
  //       (images["SPOUSE_FATHER_GIVENNAME"]
  //         .toString()
  //         .toUpperCase()
  //         .endsWith("A") ||
  //         images["SPOUSE_FATHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith(" A") ||
  //         images["SPOUSE_FATHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("IE") ||
  //         images["SPOUSE_FATHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("INE") ||
  //         images["SPOUSE_FATHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("YOU"))
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "18") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["SPOUSE_MOTHER_GIVENNAME"] !== null &&
  //       (images["SPOUSE_MOTHER_GIVENNAME"]
  //         .toString()
  //         .toUpperCase()
  //         .endsWith("O") ||
  //         images["SPOUSE_MOTHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("AS") ||
  //         images["SPOUSE_MOTHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("US") ||
  //         images["SPOUSE_MOTHER_GIVENNAME"]
  //           .toString()
  //           .toUpperCase()
  //           .endsWith("IUS"))
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "19") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["SURNAME"] !== null &&
  //       images["FATHER_SURNAME"] !== null &&
  //       images["SURNAME"].toString().toUpperCase() !==
  //         images["FATHER_SURNAME"].toString().toUpperCase()
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "20") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["SPOUSE_SURNAME"] !== null &&
  //       images["SPOUSE_FATHER_SURNAME"] !== null &&
  //       images["SPOUSE_SURNAME"].toString().toUpperCase() !==
  //         images["SPOUSE_FATHER_SURNAME"].toString().toUpperCase()
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "21") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (
  //       images["SPOUSE_SURNAME"] === null &&
  //       images["SPOUSE_GIVENNAME"] === null &&
  //       images["SPOUSE_MAIDEN_NAME"] === null
  //     ) {
  //       x.push(images);
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "22") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (images["BIRTH_DATE"] !== null && images["BAPTISM_DATE"] !== null) {
  //       const date1 = new Date(`${images["BIRTH_DATE"]}`);
  //       const date2 = new Date(`${images["BAPTISM_DATE"]}`);
  //       if (date1 > date2) {
  //         x.push(images);
  //       }
  //     }
  //   });
  //   return x;
  // } else if (selectedType.value == "23") {
  //   let x = [];
  //   imagesData.filter((images) => {
  //     if (images["DEATH_DATE"] !== null && images["BURIAL_DATE"] !== null) {
  //       const date1 = new Date(`${images["DEATH_DATE"]}`);
  //       const date2 = new Date(`${images["BURIAL_DATE"]}`);
  //       if (date1 > date2) {
  //         x.push(images);
  //       }
  //     }
  //   });
  //   return x;
  // }
};
