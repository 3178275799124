import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import RotateLeftIcon from "@mui/icons-material/RotateRight";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import "./DataEdit.css";
import pointing from "../../images/logo/pointing1.png";
import { useEffect, useState } from "react";
import { handleRotation } from "../Helper/commonHelper";
import { handleDuplicateImage, handleLanguage } from "../Helper/editHelper";
import { useDispatch } from "react-redux";
import { createLine, deleteImage } from "../../Redux/Actions/dataEditAction";
import { updateImageComment } from "../../Redux/Actions/dataEditAction";
import PopupDialog from "../CommonUI/PopupDialog";
import { allFieldsObject } from "../Helper/entryField";

const activeDataTypes = Object.values(allFieldsObject)
  .filter((field) => field.status === "active")
  .map((value) => ({
    name: value.name,
    value: value.imgCode.toString(),
  }));

const radioButton = [
  { name: "All", value: "100" },
  { name: "??", value: "99" },
  { name: "**", value: "98" },

  ...activeDataTypes,

  { name: "Titlebox", value: "11" },
  { name: "Coverpage", value: "9" },

  { name: "GName Erro (Male)", value: "12" },
  { name: "GName Erro (Female)", value: "13" },
  { name: "S.GName Erro", value: "14" },

  { name: "F.GName Erro", value: "15" },
  { name: "M.GName Erro", value: "16" },
  { name: "S.F.GName Erro", value: "17" },

  { name: "S.M.GName Erro", value: "18" },
  { name: "SName<>F.SName", value: "19" },
  { name: "S. SName <> S.F.SName", value: "20" },

  { name: "Spouse Name Missing", value: "21" },
  { name: "Birth date > Baptism date", value: "22" },
  { name: "Death date > Burial date", value: "23" },
];

const chunkSize = 3;
const chunks = [];
for (let i = 0; i < radioButton.length; i += chunkSize) {
  chunks.push(radioButton.slice(i, i + chunkSize));
}

export default function Footer({
  selectedImage,
  imagesData,
  setSelectedDirection,
  imgRef,
  selectedType,
  selectedImageNumber,
  selectedSeries,
  setSelectedType,
  setTableBody,
  setOpenFloatingTable,
  setClickedButton,
  selectedField,
  selectedImageLine,
  getDataForEditTable,
  setDialogOpen,
  setImgComment,
  isBlankOffsetChecked,
  setIsBlankOffsetChecked,
  setBlankOffsetOpen,
  setOffsetValue,
  record,
}) {
  const dispatch = useDispatch();
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [imgComment, setImgComment] = useState(null);
  const [isDownChecked, setIsDownChecked] = useState(false);
  const [isRightChecked, setIsRightChecked] = useState(true);
  useEffect(() => {
    if (isRightChecked) {
      setSelectedDirection("right");
    } else if (isRightChecked) {
      setSelectedDirection("down");
    }
  }, [isDownChecked, isRightChecked]);

  const handleDownRight = (which) => {
    if (which === "down") {
      setIsDownChecked(!isDownChecked);
      setIsRightChecked(false);
      setSelectedDirection(which);
      if (isDownChecked) {
        setSelectedDirection(null);
      }
    } else if (which === "right") {
      setIsRightChecked(!isRightChecked);
      setIsDownChecked(false);
      setSelectedDirection(which);
      if (isRightChecked) {
        setSelectedDirection(null);
      }
    }
  };

  const handleReEntry = () => {
    if (selectedImageNumber !== null) {
      dispatch(deleteImage(selectedSeries, selectedImageNumber));
      // setTableBody([]);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, background: "#d9ebff" }}>
        <Grid
          container
          item
          direction="row"
          lg={12}
          spacing={2}
          my={0}
          py={0.4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0px",
            width: "100%",
            paddingTop: 0,
          }}
        >
          <Stack
            spacing={1}
            sx={{
              display: "flex",
              alignContent: "flex-start",
            }}
            className="col-4"
          >
            <Typography
              variant="p"
              color="error"
              fontSize={10}
              sx={{
                fontWeight: "bold",
                background: "white",
                borderRadius: "5px",
                lineHeight: "12px",
              }}
            >
              ID : {imagesData[0]?.OPERATOR_ID} | Entry : {imagesData?.length}{" "}
              Total Record : {record?.totalRecord} | Series : {selectedImage} |
              Image : {selectedImage}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormGroup
              direction="row"
              sx={{
                border: ".001rem solid #1976d2",
                borderRadius: "5px",
                paddingRight: "4px",
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDownChecked}
                    onChange={() => handleDownRight("down")}
                    sx={{
                      color: "#1976d2",
                      height: "5px",
                      width: "5px",
                      margin: "0px 0px 0px 4px",
                    }}
                    size="small"
                  />
                }
                sx={{ my: 0, mx: 0, height: "25px" }}
                label={
                  <img
                    src={pointing}
                    style={{
                      width: "30px",
                      height: "23px",
                    }}
                  />
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRightChecked}
                    onChange={() => handleDownRight("right")}
                    sx={{
                      color: "#1976d2",
                      height: "5px",
                      width: "5px",
                      margin: "0px 0px 0px 4px",
                    }}
                    size="small"
                  />
                }
                sx={{ my: 0, mx: 0, height: "25px" }}
                label={
                  <img
                    src={pointing}
                    style={{
                      width: "30px",
                      height: "23px",
                      transform: "rotate(270deg)",
                    }}
                  />
                }
              />
            </FormGroup>
            <FormGroup
              direction="column"
              sx={{
                border: ".001rem solid #1976d2",
                borderRadius: "5px",
                paddingRight: "4px",
              }}
            >
              <FormControlLabel
                control={
                  // <Switch color="primary" label="Blank Offset" size="small" />
                  <Checkbox
                    checked={isBlankOffsetChecked}
                    onChange={() =>
                      setIsBlankOffsetChecked(!isBlankOffsetChecked)
                    }
                    onClick={() => {
                      if (!isBlankOffsetChecked) {
                        setBlankOffsetOpen(true);
                      } else
                        setOffsetValue({
                          top: 0,
                          left: 0,
                        });
                    }}
                    // disabled={isDisabled}
                    sx={{
                      color: "#1976d2",
                      height: "5px",
                      width: "5px",
                      margin: "4px",
                    }}
                    size="small"
                  />
                }
                sx={{ my: 0, mx: 0, height: "25px" }}
                label={
                  <span
                    style={{
                      fontSize: "10px",
                      mx: 0,
                      color: "#1976d2",
                      textTransform: "uppercase",
                    }}
                  >
                    Blank Offset
                  </span>
                }
              />
            </FormGroup>{" "}
            <Button
              sx={{ fontSize: "10px" }}
              startIcon={
                <AddRoadIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
              }
              color="primary"
              onClick={() => handleReEntry()}
              variant="outlined"
              size="small"
            >
              ReEntry
            </Button>
            <Button
              sx={{ fontSize: "10px" }}
              startIcon={
                <AutorenewIcon
                  sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                />
              }
              color="primary"
              onClick={() => {
                getDataForEditTable(selectedSeries);
                setSelectedType({
                  name: "All",
                  value: 100,
                });
              }}
              variant="outlined"
              size="small"
            >
              Refresh
            </Button>
            <Button
              sx={{ fontSize: "10px" }}
              startIcon={
                <AddRoadIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
              }
              color="primary"
              onClick={() => {
                setDialogOpen(true);
                setImgComment("Duplicate");
              }}
              variant="outlined"
              size="small"
            >
              Duplicate
            </Button>
            <Button
              sx={{ fontSize: "10px" }}
              startIcon={
                <AddRoadIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
              }
              color="primary"
              onClick={() => {
                setDialogOpen(true);
                setImgComment("Missing Insert");
              }}
              variant="outlined"
              size="small"
            >
              Missing Insert
            </Button>
            <Button
              sx={{ fontSize: "10px" }}
              startIcon={
                <RotateLeftIcon
                  sx={{ marginLeft: "-5px", marginRight: "-5px" }}
                />
              }
              color="primary"
              onClick={() => handleRotation(imgRef)}
              variant="outlined"
              size="small"
            >
              Rotate
            </Button>
          </Stack>
        </Grid>
        <Grid
          container
          item
          lg={12}
          py={0.4}
          sx={{ justifyContent: "space-between", py: 0 }}
        >
          {/* <Grid item lg={8}> */}
          <Grid item>
            <FormControl
              component="fieldset"
              sx={{
                display: "flex",
                border: "1px solid #b1b1b1",
                borderRadius: "5px",
                px: "4px",
                marginTop: "-5px",
              }}
            >
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "12px", width: "auto" }}
                color="primary"
              >
                Type
              </FormLabel>

              <RadioGroup
                className="footerBottom"
                aria-label="language"
                name="row-radio-buttons-group"
                defaultValue="German"
                value={selectedType.value}
                onChange={(e) => {
                  setSelectedType({
                    name: e.target.name,
                    value: e.target.value,
                  });
                  setTableBody([]);
                }}
                sx={{
                  my: 0,
                  px: 1,
                  paddingBottom: 0.5,
                  paddingTop: -0.5,
                }}
              >
                {chunks.map((chunk, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {chunk.map((item, innerIndex) => (
                      <FormControlLabel
                        key={innerIndex}
                        sx={{
                          marginBottom: 0,
                          height: "20px",
                        }}
                        value={item.value}
                        name={item.name}
                        control={<Radio size="small" sx={{ py: 0, px: 1 }} />}
                        label={
                          <span
                            style={{
                              fontSize: "11px",
                              fontWeight: "600",
                            }}
                          >
                            {item.name}
                          </span>
                        }
                      />
                    ))}
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item lg={3}> */}
          <Grid item>
            <Stack
              direction="row"
              spacing={2}
              py={0.4}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Button
                sx={{ fontSize: "10px" }}
                name="DateBrack"
                onClick={(e) =>
                  selectedField !== null && selectedField.includes("_DATE")
                    ? (setOpenFloatingTable(true),
                      setClickedButton(e.target.name))
                    : (setOpenFloatingTable(false),
                      alert("Please select the correct field"))
                }
                color="success"
                variant="outlined"
                size="small"
              >
                Date Brack
              </Button>
              <Button
                disabled
                sx={{ fontSize: "10px" }}
                startIcon={
                  <PlaylistAddCheckCircleIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                name="AuditReport"
                onClick={(e) => {
                  setOpenFloatingTable(true);
                  setClickedButton(e.target.name);
                }}
                variant="outlined"
                size="small"
              >
                Audit Report
              </Button>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              py={0.4}
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <Button
                sx={{ fontSize: "10px" }}
                name="YearBrack"
                onClick={(e) =>
                  selectedField !== null && selectedField.includes("DATE")
                    ? (setOpenFloatingTable(true),
                      setClickedButton(e.target.name))
                    : (setOpenFloatingTable(false),
                      alert("Please select the correct field"))
                }
                color="success"
                variant="outlined"
                size="small"
              >
                Year Brack
              </Button>
              <Button
                sx={{ fontSize: "10px" }}
                onClick={(e) => {
                  setOpenFloatingTable(true);
                  setClickedButton(e.target.name);
                }}
                color="success"
                name="DuplicateEntryNo"
                variant="outlined"
                size="small"
              >
                Duplicate Entry No
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {/* <Divider /> */}
      </Box>
    </>
  );
}
