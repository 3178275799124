import {
  GET_IMAGE_ENTRY_DATA,
  GET_IMAGE_COORDINATES,
  IMAGE_ENTRY_DATA_INSERT,
  SET_EMPTY_COORDINATES,
} from "../Actions/types";

const initialState = null;

function imageEntryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IMAGE_ENTRY_DATA:
      return payload;

    case GET_IMAGE_COORDINATES:
      return payload;

    case IMAGE_ENTRY_DATA_INSERT:
      return payload;

    case SET_EMPTY_COORDINATES:
      return payload;

    default:
      return state;
  }
}

export default imageEntryReducer;
