import http from "../../utilities/http-common";

const getImageCoordinates = async (image_number) => {
  return await http.get(`/Image/FieldTagImageCoordinateGet/${image_number}`);
};
const getImageEntryData = async (data) => {
  return await http.get(`/Image/GetFieldEntry/${data}`);
};
const imageEntryDataInsert = async (data) => {
  return await http.post("/Image/FieldTagImageDataInsert", data);
};
const getContinueImageData = async (data) => {
  return await http.get(`/Image/GetContinueImage/${data}`);
};
const ImageEntryService = {
  getImageCoordinates,
  getImageEntryData,
  imageEntryDataInsert,
  getContinueImageData,
};

export default ImageEntryService;
