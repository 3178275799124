import {
  GET_IMAGE_DATA,
  UPDATE_IMAGE_COMMENT,
  IMAGE_DATA_INSERT,
  SET_EMPTY_TAGGING,
} from "../Actions/types";

const initialState = [];

function imageTaggingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IMAGE_DATA:
      return payload;

    case SET_EMPTY_TAGGING:
      return payload;

    case UPDATE_IMAGE_COMMENT:
      return state.map((image) => {
        if (image.IMAGE_NUMBER === payload.IMAGE_NUMBER) {
          return {
            ...state,
            ...payload,
          };
        } else {
          return image;
        }
      });

    default:
      return state;
  }
}

export default imageTaggingReducer;
