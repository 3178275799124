import FieldSequenceService from "../Services/fieldSequenceService";
import {
  GET_FIELD_SEQUENCE,
  SET_EMPTY_FIELD_SEQUENCE,
  SET_FIELD_SEQUENCE,
  UPDATE_FIELD_SEQUENCE,
} from "./types";

export const getFieldSequence =
  (selectedSeries, code, line) => async (dispatch) => {
    try {
      const res = await FieldSequenceService.getDataFieldSequence(
        selectedSeries,
        code,
        line
      );
      dispatch({
        type: GET_FIELD_SEQUENCE,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
export const setFieldSequence = (data) => async (dispatch) => {
  try {
    const res = await FieldSequenceService.setDataFieldSequence(data);
    dispatch({
      type: SET_FIELD_SEQUENCE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const updateFieldSequence = (data) => async (dispatch) => {
  try {
    const res = await FieldSequenceService.updateDataFieldSequence(data);
    dispatch({
      type: UPDATE_FIELD_SEQUENCE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
// export const setDataFields = (data) => async (dispatch) => {
//   try {
//     const res = await ImageTaggingService.setDataFields(data);
//     return Promise.resolve(res);
//   } catch (err) {
//     console.log(err);
//     return Promise.reject(err);
//   }
// };
export const setEmptyFieldSequence = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_FIELD_SEQUENCE,
      payload: [],
    });
  } catch (err) {
    console.log(err);
  }
};
