import http from "../../utilities/http-common";

const setImageOffset = async (data) => {
  return await http.post("/Image/InsertImageOffset", data);
};
const getImageOffset = async (seriesNumber, code) => {
  return await http.get(`/Image/GetImageOffset/${seriesNumber}/${code}`);
};
const FocusImageService = {
  setImageOffset,
  getImageOffset,
};

export default FocusImageService;
