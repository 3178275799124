import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./AllChecking.css";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { handleTableHeader, handleTableRaw } from "../Helper/editHelper";
// import AllCheckingTable from "./AllCheckingTable";
import AllCheckingImage from "./AllCheckingImage";
import { getAllFields } from "../../Redux/Actions/allCheckingAction";
import { getEditPageData } from "../../Redux/Actions/dashboardAction";
import DataEditTable from "../DataEdit/DataEditTable";
import { useLocation } from "react-router-dom";
import { getContinueImageData } from "../../Redux/Actions/continueImageAction";
import { getImageData } from "../../Redux/Actions/dataEditAction";
export default function AllChecking() {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const location = useLocation();
  const fieldData = useSelector((state) => state.allCheckingReducer?.fields);
  const imagesData = useSelector((state) => state.dataEditReducer);
  const selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const [selectedSeries, setSelectedSeries] = useState(selectedSeriesfromLocal);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageNumber, setSelectedImageNumber] = useState(null);
  const [switchImageAndTable, setSwitchImageAndTable] = useState(true);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const getData = () => {
    dispatch(getAllFields());
  };

  useEffect(() => {
    document.title = "All Checking | Data Keying";
    getData();
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.which === 114) {
          e.preventDefault();
          setSwitchImageAndTable((previousValue) => !previousValue);
        }
        if (e.which === 27) {
          setOpenFloatingTable(false);
        }
        // if (e.which === 120) {
        //   e.preventDefault();
        //   dispatch(getImageData(selectedSeriesfromLocal));
        // }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getImageData(selectedSeriesfromLocal));
  }, [selectedSeriesfromLocal]);

  useEffect(() => {
    if (imagesData.length > 0) {
      setTableBody(imagesData);
      setTableHeader(handleTableHeader(imagesData));
    }
  }, [imagesData]);

  const record = useSelector((state) => state.dashboardReducer);

  const [selectedField, setSelectedField] = useState(null);
  const [underlineCoordinates, setUnderlineCoordinates] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImageLine, setSelectedImageLine] = useState([]);
  const [selectedType, setSelectedType] = useState({
    name: "All",
    value: "100",
  });
  const [isCardClicked, setIsCardClicked] = useState(false);
  const [isSecondImageClicked, setIsSecondImageClicked] = useState(false);
  const [isFixedLeftClicked, setIsFixedLeftClicked] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [openFloatingTable, setOpenFloatingTable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imgComment, setImgComment] = useState(null);
  const [doubleClickFocusData, setDoubleClickFocusData] = useState(null);
  const [blankOffsetOpen, setBlankOffsetOpen] = useState(false);
  const [isBlankOffsetChecked, setIsBlankOffsetChecked] = useState(false);
  const [offsetValue, setOffsetValue] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    setSelectedSeries(selectedSeriesfromLocal);
  }, [location]);
  useEffect(() => {
    dispatch(getImageData(selectedSeries));
    dispatch(getEditPageData(selectedSeries));
  }, [selectedSeries]);
  useEffect(() => {
    if (imagesData.length > 0) {
      setTableBody(imagesData);
      setTableHeader(handleTableHeader(imagesData));
    }
  }, [imagesData]);
  useEffect(() => {
    if (selectedImageNumber !== null) {
      dispatch(getContinueImageData(selectedImageNumber));
    }
  }, [selectedImageNumber]);

  useEffect(() => {
    if (selectedType.value == "100") {
      setTableBody(imagesData);
    } else {
      let rawData = handleTableRaw(imagesData, selectedType);
      setTableBody(rawData);
    }
  }, [selectedType, imagesData]);

  return (
    <div>
      <div>
        <Sidebar></Sidebar>
      </div>
      <div>
        {switchImageAndTable ? (
          <AllCheckingImage
            imgRef={imgRef}
            fieldData={fieldData}
            selectedImage={selectedImage}
            selectedSeries={selectedSeries}
            underlineCoordinates={underlineCoordinates}
            selectedField={selectedField}
          />
        ) : (
          // <AllCheckingTable tableHeader={tableHeader} tableBody={tableBody} />
          <DataEditTable
            imagesData={imagesData}
            // selectedDirection={selectedDirection}
            selectedType={selectedType}
            tableBody={tableBody}
            tableHeader={tableHeader}
            selectedField={selectedField}
            setSelectedImage={setSelectedImage}
            setSelectedImageNumber={setSelectedImageNumber}
            setUnderlineCoordinates={setUnderlineCoordinates}
            setSelectedField={setSelectedField}
            setSelectedImageLine={setSelectedImageLine}
            setDialogOpen={setDialogOpen}
            setImgComment={setImgComment}
            doubleClickFocusData={doubleClickFocusData}
            tableHeight="52vh"
          />
        )}
        <Footer
          imgRef={imgRef}
          imagesData={imagesData}
          radioButtonList={fieldData}
          selectedSeries={selectedSeriesfromLocal}
          setSelectedImageNumber={setSelectedImageNumber}
          setSelectedImage={setSelectedImage}
          selectedImageNumber={selectedImageNumber}
          setUnderlineCoordinates={setUnderlineCoordinates}
        />
      </div>
    </div>
  );
}
