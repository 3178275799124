import { FormControl, FormLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import "./AllChecking.css";

export default function ValueUpadte({ selectedFieldValue, setUpdatedValue }) {
  return (
    <>
      <Grid item>
        <FormControl
          component="fieldset"
          sx={{
            display: "flex",
            border: "1px solid #b1b1b1",
            borderRadius: "5px",
            px: "2px",
            marginTop: "10px",
            width: "auto",
          }}
        >
          <FormLabel
            component="legend"
            sx={{ margin: 0, fontSize: "12px", width: "auto" }}
            color="primary"
          >
            Value Update
          </FormLabel>
          <div className="d-flex">
            <TextField
              placeholder="Current Value"
              size="small"
              className="p-1 w-100"
              value={selectedFieldValue}
            />
            <TextField
              placeholder="New Value"
              size="small"
              className="p-1 w-100 bottomFooterInput"
              onChange={(e) => setUpdatedValue(e.target.value)}
            />
          </div>
        </FormControl>
      </Grid>
    </>
  );
}
