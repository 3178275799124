import React, { useRef } from "react";
import { useState } from "react";
import { links } from "./data";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./Sidebar.module.css";
import logo from "../Menu/ghit.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Actions/authAction";
import ImageSeries from "../ImageSeries/ImageSeries";
import { getImageSeries } from "../../Redux/Actions/imageSeriesAction";
import {
  clearDashboard,
  getDashboardData,
} from "../../Redux/Actions/dashboardAction";
import {
  getImageDataForEntry,
  setImageEmptyTagging,
} from "../../Redux/Actions/imageTaggingAction";
export default function Sidebar() {
  let history = useHistory();
  const sideBar = useRef(null);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleClose = (newValue) => {
    setOpen(false);
  };
  const handleClickListItem = (type) => {
    dispatch(getDashboardData());
    var selectedSeries = JSON.parse(sessionStorage.getItem("selectedSeries"));
    setType(type);

    if (selectedSeries) {
      if (type !== "tag") {
        dispatch(setImageEmptyTagging());
        dispatch(getImageDataForEntry(selectedSeries));
      }

      history.push({
        pathname: `/${type}`,
        state: selectedSeries,
      });
    } else if (type.includes("entry-count")) {
      history.push("/entry-count");
    } else setOpen(true);
  };

  window.onmousedown = (e) => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };
  window.onkeydown = (e) => {
    if (e.which === 27 && isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <>
      <main className={styles.main_sec}>
        <button onClick={openSidebar} className={styles.sidebar_toggle}>
          <MenuIcon />
        </button>
      </main>

      <aside
        ref={sideBar}
        style={{ backgroundColor: "#dee2e6" }}
        className={
          isSidebarOpen
            ? `${styles.sidebar} ${styles.show_sidebar}`
            : `${styles.sidebar}`
        }
        onBlur={() => {
          setIsSidebarOpen(false);
        }}
      >
        <div className={styles.sidebar_header}>
          <img src={logo} className={styles.logo} alt="GHIT" />
          <button className={styles.close_btn} onClick={closeSidebar}>
            <CloseIcon />
          </button>
        </div>
        <ul className={styles.links}>
          {links.map((link) => {
            const { id, url, text, icon, type } = link;
            const handleSubmit = () => {
              if (type === "logout") {
                dispatch(logout());
              } else if (type === "tag") {
                dispatch(getImageSeries());
                handleClickListItem("tag");
              } else if (type === "tag-index") {
                dispatch(getImageSeries());
                handleClickListItem("tag-index");
              } else if (type === "entry") {
                dispatch(getImageSeries());
                handleClickListItem("entry");
              } else if (type === "edit") {
                dispatch(getImageSeries());
                handleClickListItem("edit");
              } else if (type === "entry-count") {
                handleClickListItem("entry-count");
              } else if (type === "ai") {
                handleClickListItem("ai");
              } else if (type === "tag-checking") {
                handleClickListItem("tag-checking");
              } else if (type === "all-checking") {
                handleClickListItem("all-checking");
              } else if (type === "bbd-checking") {
                handleClickListItem("bbd-checking");
              } else if (type === "dashboard") {
                dispatch(clearDashboard());
              }
              setIsSidebarOpen(false);
            };
            return (
              <li key={id}>
                <Link to={url} onClick={handleSubmit}>
                  {icon}
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </aside>

      {open && (
        <ImageSeries
          id="image_series"
          keepMounted
          type={type}
          open={open}
          onClose={handleClose}
        />
      )}
    </>
  );
}
