import http from "../../utilities/http-common";

const getEntryCountData = async (startDate, endDate) => {
  return await http.get(`/EntryCount/${startDate}/${endDate}`);
};

const entryCountService = {
  getEntryCountData,
};

export default entryCountService;
