import { Box, Grid } from "@mui/material";
import disableScroll from "disable-scroll";
import img2 from "../../images/white.png";
import React, { useEffect, useRef, useState } from "react";
import { settingRatio, setTransform, zooming } from "../Helper/commonHelper";
import { createCanvas, createCanvas2 } from "../Helper/editHelper";
import { drawFullLineGeneral } from "../Helper/commonHelper";

export default function AllCheckingImage({
  imgRef,
  continueImage,
  isFullLineClicked,
  isCardClicked,
  isSecondImageClicked,
  isFixedLeftClicked,
  setFocusData,
  selectedSeries,
  selectedImage,
  underlineCoordinates,
  selectedField,
  offsetValue,
}) {

  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const mainSectionRef = useRef(null);
  const canvRef = useRef(null);

  const [hwRatio, setHWRatio] = useState(null);
  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [imgDiv, setImgDiv] = useState(null);
  const [img, setImg] = useState(null);
  const [scale, setScale] = useState(1);
  const [previousLeftForFullLine, setPreviousLeftForFullLine] = useState(null);

  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    setTransform(canvRef, scale);
  }, [scale]);
  useEffect(() => {
    if (document) {
      document.getElementById("imagesss").addEventListener("load", () => {
        setImg({
          height: document.getElementById("imagesss").naturalHeight,
          width: document.getElementById("imagesss").naturalWidth,
        });
        setImgDiv({
          height: document.getElementById("imageContainer").clientHeight,
          width: document.getElementById("imageContainer").clientWidth,
        });
      });
    }
  }, [selectedImage]);
  useEffect(() => {
    if (imgDiv !== null && img !== null) {
      setHWRatio(settingRatio(imgDiv, img));
    }
  }, [img, imgDiv]);

  useEffect(() => {
    if (hwRatio !== null) {
      setHeightRatio(hwRatio.heightRatio);
      setWidthRatio(hwRatio.widthRatio);
    }
  }, [hwRatio]);

  useEffect(() => {
    if (document) {
      var selectLastTag = document.querySelectorAll("#focusedPosition");
      selectLastTag.length > 0 &&
        selectLastTag.forEach((element) => {
          canvRef?.current?.removeChild(element);
        });
    }
    if (underlineCoordinates?.length > 0) {
      underlineCoordinates.map((item) =>
        handlefocus(
          item[0],
          item[1],
          item[3],
          item[2],
          selectedField,
          isFixedLeftClicked,
          offsetValue
        )
      );
    }
    // if (document) {
    //   var selectLastTag2 = document.querySelectorAll("#fixedLeftFocus");
    //   if (selectLastTag2.length > 0) {
    //     canvRef?.current?.removeChild(selectLastTag2[0]);
    //   }
    // }
  }, [underlineCoordinates, heightRatio, widthRatio, offsetValue]);
  useEffect(() => {
    drawFullLineGeneral(isFullLineClicked, previousLeftForFullLine);
  }, [isFullLineClicked, previousLeftForFullLine]);
  useEffect(() => {
    if (isFixedLeftClicked) {
      setScale(2);
    }
    setScale(1);
  }, [isFixedLeftClicked]);
  useEffect(() => {
    if (isFixedLeftClicked) {
      setScale(2);
      const zoomImg = canvRef.current;
      const zoomImgClientWidth = imgRef.current.naturalWidth;

      let fixedLeftFocus = null;
      if (
        underlineCoordinates &&
        underlineCoordinates[0] >= zoomImgClientWidth / 2
      ) {
        fixedLeftFocus = createCanvas2(
          "fixedLeftFocus",
          (zoomImgClientWidth / 2 + 200) * widthRatio + "px",
          underlineCoordinates[1] * heightRatio + "px"
        );

        // fixedLeftFocus.style.left =
        //   (zoomImgClientWidth / 2 + 200) * widthRatio + "px";
      } else
        fixedLeftFocus = underlineCoordinates
          ? createCanvas2(
              "fixedLeftFocus",
              200 * widthRatio + "px",
              underlineCoordinates[1] * heightRatio + "px"
            )
          : createCanvas2("fixedLeftFocus", 200 * widthRatio + "px", "0px");
      zoomImg.appendChild(fixedLeftFocus);
      zoomImg.style.transform = "scale(" + scale + ")";
      fixedLeftFocus.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
      var selectLastTag = document.querySelectorAll("#fixedLeftFocus");
      if (selectLastTag.length > 1) {
        zoomImg.removeChild(selectLastTag[0]);
      }
    } else {
      if (underlineCoordinates?.length > 0) {
        underlineCoordinates.map((item) =>
          handlefocus(
            item[0],
            item[1],
            item[3],
            item[2],
            selectedField,
            isFixedLeftClicked,
            offsetValue
          )
        );
      }
      // underlineCoordinates &&
      //   handlefocus(
      //     underlineCoordinates[0],
      //     underlineCoordinates[1],
      //     underlineCoordinates[3],
      //     underlineCoordinates[2],
      //     selectedField,
      //     1
      //   );
    }
  }, [isFixedLeftClicked, selectedImage, underlineCoordinates]);

  const handlefocus = (
    x,
    y,
    h,
    w,
    selectedCell,
    isFixedLeftClicked,
    opacityData = 1
  ) => {
    const appendRef = canvRef.current;

    const canv1 = createCanvas(
      "focusedPosition",
      x * widthRatio + "px",
      y * heightRatio + "px",
      w + "px",
      h + "px",
      "red",
      opacityData
    );

    appendRef.appendChild(canv1);

    // pointHere(canv1);
    setPreviousLeftForFullLine(x * widthRatio + "px");
  };
  const pointHere = (point) => {
    point.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          // height: "45vh",
          // maxHeight: "45vh",
          height: "52vh",
          maxHeight: "52vh",
          overflow: "scroll",
          resize: "vertical",
          background: "#FFFFE6",
          padding: "0px",
          // marginTop: "7vh",
        }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                id="imageContainer"
                className="zoom imgdiv"
                onWheel={(e) => zooming(e, canvRef, setFocusData, setScale)}
                onMouseLeave={() => disableScroll.off()}
                onMouseEnter={() => disableScroll.on()}
                onMouseOver={() => disableScroll.on()}
              >
                <img
                  id="imagesss"
                  className="imageContainer"
                  tabIndex="-1"
                  ref={imgRef}
                  src={
                    selectedImage === null
                      ? img2
                      : isSecondImageClicked && continueImage
                      ? `${imageBaseUrl}${selectedSeries}/${continueImage}`
                      : `${imageBaseUrl}${selectedSeries}/${selectedImage}`
                  }
                  alt="img"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
