import {
  BANNS_TYPE_CODE,
  BAPTISM_TYPE_CODE,
  BIRTH_TYPE_CODE,
  BIRTH_DATE_KEY_CODE,
  BURIAL_TYPE_CODE,
  DEATH_TYPE_CODE,
  FRENCH_LANGUAGE_CODE,
  GENDER_KEY_CODE,
  ITALIAN_LANGUAGE_CODE,
  LATIN_LANGUAGE_CODE,
  MARRIAGE_TYPE_CODE,
  MARRIAGE_INTENT_TYPE_CODE,
  MIXED_TYPE_CODE,
  NAMES_KEY_CODE,
  HEADER_PARISH_KEY_CODE,
  HEADER_COUNTY_KEY_CODE,
  HEADER_YEAR_KEY_CODE,
  AGE_KEY_CODE,
  DEATH_DATE_KEY_CODE,
  BIRTH_PLACE_KEY_CODE,
  OCCUPATION_KEY_CODE,
  SPOUSE_GIVENNAME_KEY_CODE,
  SPOUSE_AGE_KEY_CODE,
  SPOUSE_BIRTH_PLACE_KEY_CODE,
  SPOUSE_OCCUPATION_KEY_CODE,
  SPOUSE_FATHER_NAME_KEY_CODE,
  SPOUSE_MOTHER_NAME_KEY_CODE,
  MARRIAGE_DATE_KEY_CODE,
  MARRIAGE_INTENT_DATE_KEY_CODE,
  FATHER_NAMES_KEY_CODE,
  MOTHER_NAMES_KEY_CODE,
  BAPTISM_DATE_KEY_CODE,
  BURIAL_DATE_KEY_CODE,
} from "./constants";

export const dataTypeCode = {
  birth: BIRTH_TYPE_CODE,
  death: DEATH_TYPE_CODE,
  marriage: MARRIAGE_TYPE_CODE,
  banns: BANNS_TYPE_CODE,
  baptism: BAPTISM_TYPE_CODE,
  burial: BURIAL_TYPE_CODE,
  marriageIntent: MARRIAGE_INTENT_TYPE_CODE,
  mixed: MIXED_TYPE_CODE,
};

//All the fields
export const baptism = [
  { name: "Header Parish", keyCode: HEADER_PARISH_KEY_CODE },
  { name: "Header County", keyCode: HEADER_COUNTY_KEY_CODE },
  { name: "Header Year", keyCode: HEADER_YEAR_KEY_CODE },
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Birth Date", keyCode: BIRTH_DATE_KEY_CODE },
  { name: "Gender", keyCode: GENDER_KEY_CODE },
  { name: "Baptism Date", keyCode: BAPTISM_DATE_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
];

export const burial = [
  { name: "Header Parish", keyCode: HEADER_PARISH_KEY_CODE },
  { name: "Header County", keyCode: HEADER_COUNTY_KEY_CODE },
  { name: "Header Year", keyCode: HEADER_YEAR_KEY_CODE },
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Age", keyCode: AGE_KEY_CODE },
  { name: "Gender", keyCode: GENDER_KEY_CODE },
  { name: "Burial Date", keyCode: BURIAL_DATE_KEY_CODE },
  { name: "Spouse Names", keyCode: SPOUSE_GIVENNAME_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
];

export const birth = [
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Gender", keyCode: GENDER_KEY_CODE },
  { name: "Birth Date", keyCode: BIRTH_DATE_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
];
export const marriage = [
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Age", keyCode: AGE_KEY_CODE },
  { name: "Marriage Date", keyCode: MARRIAGE_DATE_KEY_CODE },
  { name: "Birth Place", keyCode: BIRTH_PLACE_KEY_CODE },
  { name: "Occupation", keyCode: OCCUPATION_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
  { name: "Spouse Names", keyCode: SPOUSE_GIVENNAME_KEY_CODE },
  { name: "Spouse Age", keyCode: SPOUSE_AGE_KEY_CODE },
  { name: "Spouse Birth Place", keyCode: SPOUSE_BIRTH_PLACE_KEY_CODE },
  { name: "Spouse Occupation", keyCode: SPOUSE_OCCUPATION_KEY_CODE },
  { name: "Spouse Father Names", keyCode: SPOUSE_FATHER_NAME_KEY_CODE },
  { name: "Spouse Mother Names", keyCode: SPOUSE_MOTHER_NAME_KEY_CODE },
];

export const banns = [...marriage];

export const marriageIntent = [
  { name: "Header Parish", keyCode: HEADER_PARISH_KEY_CODE },
  { name: "Header County", keyCode: HEADER_COUNTY_KEY_CODE },
  { name: "Header Year", keyCode: HEADER_YEAR_KEY_CODE },
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Age", keyCode: AGE_KEY_CODE },
  { name: "Gender", keyCode: GENDER_KEY_CODE },
  {
    name: "Marriage Intent Date",
    keyCode: MARRIAGE_INTENT_DATE_KEY_CODE,
    dbName: "Marriage Date",
  },
  {
    name: "Marriage Date",
    keyCode: MARRIAGE_DATE_KEY_CODE,
    dbName: "Marriage Date Second",
  },
  { name: "Spouse Names", keyCode: SPOUSE_GIVENNAME_KEY_CODE },
  { name: "Spouse Age", keyCode: SPOUSE_AGE_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
  { name: "Spouse Father Names", keyCode: SPOUSE_FATHER_NAME_KEY_CODE },
  { name: "Spouse Mother Names", keyCode: SPOUSE_MOTHER_NAME_KEY_CODE },
];

export const death = [
  { name: "Names", keyCode: NAMES_KEY_CODE },
  { name: "Age", keyCode: AGE_KEY_CODE },
  { name: "Gender", keyCode: GENDER_KEY_CODE },
  { name: "Death Date", keyCode: DEATH_DATE_KEY_CODE },
  { name: "Birth Date", keyCode: BIRTH_DATE_KEY_CODE },
  { name: "Birth Place", keyCode: BIRTH_PLACE_KEY_CODE },
  { name: "Occupation", keyCode: OCCUPATION_KEY_CODE },
  { name: "Father Names", keyCode: FATHER_NAMES_KEY_CODE },
  { name: "Mother Names", keyCode: MOTHER_NAMES_KEY_CODE },
  { name: "Spouse Names", keyCode: SPOUSE_GIVENNAME_KEY_CODE },
  { name: "Spouse Age", keyCode: SPOUSE_AGE_KEY_CODE },
];

export const allFieldsObject = {
  birth: {
    imgCode: BIRTH_TYPE_CODE,
    name: "Birth",
    value: "Birth",
    status: "active",
    fields: [...birth],
  },
  death: {
    imgCode: DEATH_TYPE_CODE,
    name: "Death",
    value: "Death",
    status: "active",
    fields: [...death],
  },
  marriage: {
    imgCode: MARRIAGE_TYPE_CODE,
    name: "Marriage",
    value: "Marriage",
    status: "active",
    fields: [...marriage],
  },
  banns: {
    imgCode: BANNS_TYPE_CODE,
    name: "Banns",
    value: "Banns",
    status: "active",
    fields: [...banns],
  },
  baptism: {
    imgCode: BAPTISM_TYPE_CODE,
    name: "Baptism",
    value: "Baptism",
    status: "inactive",
    fields: [...baptism],
  },
  burial: {
    imgCode: BURIAL_TYPE_CODE,
    name: "Burial",
    value: "Burial",
    status: "inactive",
    fields: [...burial],
  },
  marriageIntent: {
    imgCode: MARRIAGE_INTENT_TYPE_CODE,
    name: "Marriage Intent",
    value: "MarriageIntent",
    status: "inactive",
    fields: [...marriageIntent],
  },
  mixed: {
    imgCode: MIXED_TYPE_CODE,
    name: "Mixed",
    value: "Mixed",
    status: "active",
  },
};

export const languageCode = {
  Italian: ITALIAN_LANGUAGE_CODE,
  Latin: LATIN_LANGUAGE_CODE,
  French: FRENCH_LANGUAGE_CODE,
};

//=============
// Menu Heights
//=============
export const seriesMenuHeights = {
  birth: 448,
  death: 448,
  baptism: 448,
  burial: 544,
  marriage: 688,
  banns: 688,
  marriageIntent: 744,
  default: 488,
};

//============
// Menu States
//============

export const DEPARTEMENT = {
  labelName: "Departement",
  fieldName: "DEPARTEMENT",
  active: false,
  fieldIndex: 1,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const ARRONDISSEMENT = {
  labelName: "Arrondissement",
  fieldName: "ARRONDISSEMENT",
  active: false,
  fieldIndex: 2,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const HEADER_COMMUNE = {
  labelName: "Header Commune",
  fieldName: "HEADER_COMMUNE",
  active: false,
  fieldIndex: 3,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const HEADER_YEAR = {
  labelName: "Header Year",
  fieldName: "HEADER_YEAR",
  active: false,
  fieldIndex: 4,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const ENTRY_NO = {
  labelName: "Entry No",
  fieldName: "ENTRY_NO",
  active: false,
  fieldIndex: 5,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const EVENT_COMMUNE = {
  labelName: "Event Commune",
  fieldName: "EVENT_COMMUNE",
  active: false,
  fieldIndex: 6,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const GIVENNAME = {
  labelName: "GivenNames",
  fieldName: "GIVENNAME",
  active: false,
  fieldIndex: 7,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SURNAME = {
  labelName: "SurNames",
  fieldName: "SURNAME",
  active: false,
  fieldIndex: 8,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MAIDEN_NAME = {
  labelName: "MaidenNames",
  fieldName: "MAIDEN_NAME",
  active: false,
  fieldIndex: 9,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const GENDER = {
  labelName: "Gender",
  fieldName: "GENDER",
  active: false,
  fieldIndex: 10,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const BAPTISM_DATE = {
  labelName: "Baptism_Date",
  fieldName: "BAPTISM_DATE",
  active: false,
  fieldIndex: 11,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const R_BAPTISM_DATE = {
  labelName: "R_Baptism_Date",
  fieldName: "R_BAPTISM_DATE",
  active: false,
  fieldIndex: 12,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const FATHER_GIVENNAME = {
  labelName: "Father Givenames",
  fieldName: "FATHER_GIVENNAME",
  active: false,
  fieldIndex: 13,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const FATHER_SURNAME = {
  labelName: "Father Surnames",
  fieldName: "FATHER_SURNAME",
  active: false,
  fieldIndex: 14,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const FATHER_AGE = {
  labelName: "Father Age",
  fieldName: "FATHER_AGE",
  active: false,
  fieldIndex: 15,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const FATHER_BIRTH_PLACE = {
  labelName: "Father Birth Place",
  fieldName: "FATHER_BIRTH_PLACE",
  active: false,
  fieldIndex: 16,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_GIVENNAME = {
  labelName: "Mother Givennames",
  fieldName: "MOTHER_GIVENNAME",
  active: false,
  fieldIndex: 17,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_SURNAME = {
  labelName: "Mother Surnames",
  fieldName: "MOTHER_SURNAME",
  active: false,
  fieldIndex: 18,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_MAIDEN_NAME = {
  labelName: "Mother Maidennames",
  fieldName: "MOTHER_MAIDEN_NAME",
  active: false,
  fieldIndex: 19,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_AGE = {
  labelName: "Mother Age",
  fieldName: "MOTHER_AGE",
  active: false,
  fieldIndex: 20,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_BIRTH_PLACE = {
  labelName: "Mother Birth Place",
  fieldName: "MOTHER_BIRTH_PLACE",
  active: false,
  fieldIndex: 21,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const BURIAL_DATE = {
  labelName: "Burial_Date",
  fieldName: "BURIAL_DATE",
  active: false,
  fieldIndex: 22,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const R_BURIAL_DATE = {
  labelName: "R_Burial_Date",
  fieldName: "R_BURIAL_DATE",
  active: false,
  fieldIndex: 23,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const AGE = {
  labelName: "Age",
  fieldName: "AGE",
  active: false,
  fieldIndex: 24,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const DEATH_DATE = {
  labelName: "Death Date",
  fieldName: "DEATH_DATE",
  active: false,
  fieldIndex: 25,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const R_DEATH_DATE = {
  labelName: "R Death Date",
  fieldName: "R_DEATH_DATE",
  active: false,
  fieldIndex: 26,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const MARRIAGE_DATE = {
  labelName: "Marriage_Date",
  fieldName: "MARRIAGE_DATE",
  active: false,
  fieldIndex: 27,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const R_MARRIAGE_DATE = {
  labelName: "R_Marriage_Date",
  fieldName: "R_MARRIAGE_DATE",
  active: false,
  fieldIndex: 28,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const BIRTH_DATE = {
  labelName: "Birth Date",
  fieldName: "BIRTH_DATE",
  active: false,
  fieldIndex: 29,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const R_BIRTH_DATE = {
  labelName: "R Birth Date",
  fieldName: "R_BIRTH_DATE",
  active: false,
  fieldIndex: 30,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const BIRTH_PLACE = {
  labelName: "Birth Place",
  fieldName: "BIRTH_PLACE",
  active: false,
  fieldIndex: 31,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const SPOUSE_GIVENNAME = {
  labelName: "Spouse Givenname",
  fieldName: "SPOUSE_GIVENNAME",
  active: false,
  fieldIndex: 32,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_SURNAME = {
  labelName: "Spouse Surname",
  fieldName: "SPOUSE_SURNAME",
  active: false,
  fieldIndex: 33,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MAIDEN_NAME = {
  labelName: "Spouse Maidename",
  fieldName: "SPOUSE_MAIDEN_NAME",
  active: false,
  fieldIndex: 34,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_AGE = {
  labelName: "Spouse Age",
  fieldName: "SPOUSE_AGE",
  active: false,
  fieldIndex: 35,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_BIRTH_DATE = {
  labelName: "Spouse Birth Date",
  fieldName: "SPOUSE_BIRTH_DATE",
  active: false,
  fieldIndex: 36,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_R_BIRTH_DATE = {
  labelName: "Spouse R Birth Date",
  fieldName: "SPOUSE_R_BIRTH_DATE",
  active: false,
  fieldIndex: 37,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_BIRTH_PLACE = {
  labelName: "Spouse Birth Place",
  fieldName: "SPOUSE_BIRTH_PLACE",
  active: false,
  fieldIndex: 38,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const SPOUSE_GENDER = {
  labelName: "Spouse Gender",
  fieldName: "SPOUSE_GENDER",
  active: false,
  fieldIndex: 39,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_FATHER_GIVENNAME = {
  labelName: "Spouse Father Givenname",
  fieldName: "SPOUSE_FATHER_GIVENNAME",
  active: false,
  fieldIndex: 40,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_FATHER_SURNAME = {
  labelName: "Spouse Father Surname",
  fieldName: "SPOUSE_FATHER_SURNAME",
  active: false,
  fieldIndex: 41,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_FATHER_BIRTH_PLACE = {
  labelName: "Spouse Father Birth Place",
  fieldName: "SPOUSE_FATHER_BIRTH_PLACE",
  active: false,
  fieldIndex: 42,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MOTHER_GIVENNAME = {
  labelName: "Spouse Mother Name",
  fieldName: "SPOUSE_MOTHER_GIVENNAME",
  active: false,
  fieldIndex: 43,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MOTHER_SURNAME = {
  labelName: "Spouse Mother Surname",
  fieldName: "SPOUSE_MOTHER_SURNAME",
  active: false,
  fieldIndex: 44,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MOTHER_MAIDEN_NAME = {
  labelName: "Spouse Mother Maiden Name",
  fieldName: "SPOUSE_MOTHER_MAIDEN_NAME",
  active: false,
  fieldIndex: 45,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MOTHER_BIRTH_PLACE = {
  labelName: "Spouse Mother Birth Place",
  fieldName: "SPOUSE_MOTHER_BIRTH_PLACE",
  active: false,
  fieldIndex: 46,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const NAME = {
  labelName: "Name",
  fieldName: "NAME",
  active: false,
  fieldIndex: 47,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const FATHER_NAME = {
  labelName: "Father Name",
  fieldName: "FATHER_NAME",
  active: false,
  fieldIndex: 48,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MOTHER_NAME = {
  labelName: "Mother Name",
  fieldName: "MOTHER_NAME",
  active: false,
  fieldIndex: 49,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_NAME = {
  labelName: "Spouse Name",
  fieldName: "SPOUSE_NAME",
  active: false,
  fieldIndex: 50,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_FATHER_NAME = {
  labelName: "Spouse Father Name",
  fieldName: "SPOUSE_FATHER_NAME",
  active: false,
  fieldIndex: 51,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_MOTHER_NAME = {
  labelName: "Spouse Mother Name",
  fieldName: "SPOUSE_MOTHER_NAME",
  active: false,
  fieldIndex: 52,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const MARRIAGE_INTENT_DATE = {
  labelName: "Marriage_Intent_Date",
  fieldName: "MARRIAGE_INTENT_DATE",
  active: false,
  fieldIndex: 53,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const R_MARRIAGE_INTENT_DATE = {
  labelName: "R_Marriage_Intent_Date",
  fieldName: "R_MARRIAGE_INTENT_DATE",
  active: false,
  fieldIndex: 54,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const OCCUPATION = {
  labelName: "Occupation",
  fieldName: "OCCUPATION",
  active: false,
  fieldIndex: 55,
  inpType: "text",
  inpVal: "",
  searchField: true,
};
export const SPOUSE_OCCUPATION = {
  labelName: "Spouse_Occupation",
  fieldName: "SPOUSE_OCCUPATION",
  active: false,
  fieldIndex: 56,
  inpType: "text",
  inpVal: "",
  searchField: true,
};

export const NAMES = [GIVENNAME, SURNAME, MAIDEN_NAME];
export const FATHER_NAMES = [FATHER_GIVENNAME, FATHER_SURNAME];
export const MOTHER_NAMES = [
  MOTHER_GIVENNAME,
  MOTHER_SURNAME,
  MOTHER_MAIDEN_NAME,
];
export const SPOUSE_NAMES = [
  SPOUSE_GIVENNAME,
  SPOUSE_SURNAME,
  SPOUSE_MAIDEN_NAME,
];
export const SPOUSE_FATHER_NAMES = [
  SPOUSE_FATHER_GIVENNAME,
  SPOUSE_FATHER_SURNAME,
];
export const SPOUSE_MOTHER_NAMES = [
  SPOUSE_MOTHER_GIVENNAME,
  SPOUSE_MOTHER_SURNAME,
  SPOUSE_MOTHER_MAIDEN_NAME,
];
export const DEPARTEMENTS = [
  DEPARTEMENT,
  ARRONDISSEMENT,
  HEADER_COMMUNE,
  HEADER_YEAR,
  ENTRY_NO,
  EVENT_COMMUNE,
];

export const birthMenuState = [
  NAME,
  GENDER,
  BIRTH_DATE,
  R_BIRTH_DATE,
  FATHER_GIVENNAME,
  FATHER_SURNAME,
  MOTHER_GIVENNAME,
  MOTHER_SURNAME,
];

export const marriageMenuState = [
  NAME,
  AGE,
  MARRIAGE_DATE,
  R_MARRIAGE_DATE,
  BIRTH_PLACE,
  OCCUPATION,
  FATHER_NAME,
  FATHER_BIRTH_PLACE,
  MOTHER_NAME,
  MOTHER_BIRTH_PLACE,
  SPOUSE_GIVENNAME,
  SPOUSE_SURNAME,
  SPOUSE_AGE,
  SPOUSE_BIRTH_PLACE,
  SPOUSE_OCCUPATION,
  SPOUSE_FATHER_NAME,
  SPOUSE_FATHER_BIRTH_PLACE,
  SPOUSE_MOTHER_NAME,
  SPOUSE_MOTHER_BIRTH_PLACE,
];

export const deathMenuState = [
  NAME,
  AGE,
  GENDER,
  DEATH_DATE,
  R_DEATH_DATE,
  BIRTH_DATE,
  R_BIRTH_DATE,
  BIRTH_PLACE,
  OCCUPATION,
  FATHER_NAME,
  FATHER_BIRTH_PLACE,
  MOTHER_NAME,
  MOTHER_BIRTH_PLACE,
  SPOUSE_GIVENNAME,
  SPOUSE_SURNAME,
];
