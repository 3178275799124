import ImageEntryService from "../Services/imageEntryService";
import {
  GET_IMAGE_ENTRY_DATA,
  GET_IMAGE_COORDINATES,
  IMAGE_ENTRY_DATA_INSERT,
  SET_EMPTY_COORDINATES,
} from "./types";

export const getImageEntryData = (data) => async (dispatch) => {
  try {
    const res = await ImageEntryService.getImageEntryData(data);
    dispatch({
      type: GET_IMAGE_ENTRY_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getImageCoordinates = (data) => async (dispatch) => {
  try {
    const res = await ImageEntryService.getImageCoordinates(data);
    dispatch({
      type: GET_IMAGE_COORDINATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const imageEntryDataInsert = (data) => async (dispatch) => {
  try {
    const res = await ImageEntryService.imageEntryDataInsert(data);
    dispatch({
      type: IMAGE_ENTRY_DATA_INSERT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setImageEmptyCoordinates = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_COORDINATES,
      payload: [],
    });
  } catch (err) {
    console.log(err);
  }
};
