import http from "../../utilities/http-common";

const getSeriesDataForEdit = async (seriesNumber) => {
  return await http.get(`/Edit/GetEditImage/${seriesNumber}`);
};
const updateData = async (data) => {
  return await http.post(`/Edit/UpdateData/`, data);
};
const deleteImageFromEdit = async (seriesNumber, imageNumber) => {
  return await http.get(`/Edit/DeleteData/${seriesNumber}/${imageNumber}`);
};
const deleteImageLineFromEdit = async (data) => {
  return await http.delete(`/Edit/DeleteLine/`, { data });
};
const createLine = async (data) => {
  return await http.post(`/Edit/UpdateCreateLine/`, data);
};
const updateImageComment = async (data) => {
  return await http.put("/Edit/UpdateComment", data);
};

const dataEditService = {
  getSeriesDataForEdit,
  updateData,
  deleteImageFromEdit,
  deleteImageLineFromEdit,
  createLine,
  updateImageComment,
};

export default dataEditService;
