import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { splitCordinate } from "../Helper/editHelper";
import { deleteLine, updateData } from "../../Redux/Actions/dataEditAction";
import { setModifiedDateValue, validateFunc } from "../Helper/entryHelper";
import { onscreenKeyboardHandle } from "../Helper/library";
import { notifyWarn } from "../Helper/toasts";
export default function DataInputField({
  type,
  searchTarget,
  row,
  image,
  setSelectedImage,
  setSelectedImageNumber,
  setUnderlineCoordinates,
  setSelectedField,
  tableRef,
  selectedDirection,
  setSelectedImageLine,
  handleContextMenu,
  setDialogOpen,
  setImgComment,
  doubleClickFocusData,
  setOpenSearchField,
  setScreenKeyboardOpen,
  setScreenKeyboard,
  screenKeyboard,
  setSearchTarget,
}) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  // const [updatedValue, setUpdatedValue] = useState("");
  const [updatedValue, setUpdatedValue] = useState(row[image] ?? "");
  const [backgroundColor, setBackgroundColor] = useState("white");

  useEffect(() => {
    setUpdatedValue(row[image] ?? "");
  }, [row]);

  useEffect(() => {
    if (searchTarget !== null) {
      if (searchTarget.value !== "") {
        if (row[image] !== "") {
          if (image === searchTarget.field) {
            if (type === "partial") {
              if (new RegExp(searchTarget.value, "i").test(row[image])) {
                setBackgroundColor("yellow");
                return;
              }
            } else if (type === "full") {
              if (
                new RegExp("^" + row[image] + "$", "i").test(searchTarget.value)
              ) {
                setBackgroundColor("yellow");
                return;
              }
            }
          }
        }
      }
    }
    setBackgroundColor("white");
  }, [searchTarget]);
  useEffect(() => {
    if (
      doubleClickFocusData !== null &&
      row.IMAGE_NUMBER === doubleClickFocusData.imageNumber &&
      row.LINE === doubleClickFocusData.line &&
      image === doubleClickFocusData.fieldName
    ) {
      inputRef.current.focus();
    }
  }, [doubleClickFocusData]);
  // useEffect(() => {
  //   Mousetrap.bind("ctrl+s", function (e) {
  //   });
  // }, []);
  const handleSubmit = (e, image, line, imageNumber, row) => {
    if (!e.altKey && !e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
      updateSingleData(image, line, imageNumber);

      const selectedIndex = Array.from(tableRef.current.children).findIndex(
        (child) => {
          return child === e.target.parentElement.parentElement;
        }
      );
      const selectedFieldIndex = Array.from(
        tableRef.current.children[selectedIndex].children
      ).findIndex((child) => {
        return child === e.target.parentElement;
      });

      if (selectedDirection === "down") {
        tableRef.current.children[selectedIndex + 1]?.children[
          selectedFieldIndex
        ].firstChild.focus();
      } else if (selectedDirection === "right") {
        e.target.parentElement.nextSibling.firstChild.focus();
      }
    } else if (e.which === 37) {
      // left
      if (isAtStart) {
        e.target.closest("td").previousSibling?.firstChild.focus();
      }
    } else if (e.which === 38) {
      // up
      e.target
        .closest("tr")
        .previousSibling?.children[
          e.target.closest("td").cellIndex
        ].firstChild.focus();
    } else if (e.which === 39) {
      // right
      if (isAtEnd) {
        e.target.closest("td").nextSibling?.firstChild.focus();
      }
    } else if (e.which === 40) {
      // down
      e.target
        .closest("tr")
        .nextSibling?.children[
          e.target.closest("td").cellIndex
        ].firstChild.focus();
    } else if (e.keyCode === 46 && !e.ctrlKey && e.shiftKey) {
      setDialogOpen(true);
      setImgComment("Delete");
    } else if (e.keyCode === 113 && !e.ctrlKey && !e.shiftKey) {
      setOpenSearchField(true);
    }
    if (e.key === "0") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ÿ", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "ñ", setUpdatedValue);
    } else if (e.key === "1") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "î", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "à", setUpdatedValue);
    } else if (e.key === "2") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ï", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "á", setUpdatedValue);
    } else if (e.key === "3") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ò", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "ä", setUpdatedValue);
    } else if (e.key === "4") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ó", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "â", setUpdatedValue);
    } else if (e.key === "5") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ö", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "ç", setUpdatedValue);
    } else if (e.key === "6") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ô", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "é", setUpdatedValue);
    } else if (e.key === "7") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "û", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "è", setUpdatedValue);
    } else if (e.key === "8") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ù", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "ê", setUpdatedValue);
    } else if (e.key === "9") {
      screenKeyboard === 16 && onscreenKeyboardHandle(e, "ü", setUpdatedValue);
      screenKeyboard === 17 && onscreenKeyboardHandle(e, "ë", setUpdatedValue);
    }
    if (e.which === 16 || e.which === 17) {
      setScreenKeyboard(e.which);
      setScreenKeyboardOpen(true);
    } else {
      setScreenKeyboard(null);
    }
    if (e.which === 27) {
      setScreenKeyboardOpen(false);
    }
  };

  const updateSingleData = (image, line, imageNumber) => {
    const isValidate = updatedValue ? validateFunc(image, updatedValue) : true;

    const updatedData = {
      imageNumber: imageNumber,
      fieldName: image,
      fieldValue: updatedValue === "" ? null : updatedValue,
      line: line,
    };
    if (!isValidate) {
      notifyWarn(`Please enter a valid ${image} format`);
    }
    updatedValue !== null && dispatch(updateData(updatedData));
  };

  const handleCellSelect = (e) => {
    if (e.target.value.length === 0) {
      setIsAtEnd(true);
      setIsAtStart(true);
    } else {
      if (e.target.selectionStart === 0) {
        setIsAtEnd(false);
        setIsAtStart(true);
      } else if (e.target.selectionStart === e.target.value.length) {
        setIsAtEnd(true);
        setIsAtStart(false);
      } else {
        setIsAtEnd(false);
        setIsAtStart(false);
      }
    }
  };
  return (
    <>
      <input
        ref={inputRef}
        type="text"
        autoComplete="off"
        readOnly={
          image === "IMAGE_NUMBER" ||
          image === "LINE" ||
          image === "EVENT_TYPE" ||
          image === "FORMAT" ||
          image === "STATUS" ||
          image === "CODE" ||
          image === "ENTRY_TIME"
            ? true
            : false
        }
        name={image}
        value={
          image === "IMAGE_NUMBER" ? updatedValue.toString() : updatedValue
        }
        style={{
          width: image === "IMAGE_NUMBER" ? "230px" : "100%",
          padding: "2px 4px",
          border: "none",
          background: backgroundColor && backgroundColor,
        }}
        onKeyDown={(e) =>
          handleSubmit(e, image, row["LINE"], row["IMAGE_NUMBER"], row)
        }
        onBlur={() => updateSingleData(image, row["LINE"], row["IMAGE_NUMBER"])}
        onFocus={(e) => {
          e.target.name !== searchTarget?.field && setSearchTarget(null);
          setSelectedField(e.target.name);
          setUpdatedValue(e.target.value);
          e.target.setSelectionRange(
            e.target.value.length,
            e.target.value.length
          );
          setSelectedImage(row["IMAGE_NAME"]);
          setSelectedImageNumber(row["IMAGE_NUMBER"]);
          setSelectedImageLine(row);
          setUnderlineCoordinates(splitCordinate(row, image));
        }}
        onChange={(e) => {
          if (e.target.name.includes("DATE")) {
            e.target.value = setModifiedDateValue(e.target.value);
          }
          setUpdatedValue(e.target.value);
        }}
        onSelect={(e) => handleCellSelect(e)}
        onContextMenu={(e) => handleContextMenu(e)}
      />
    </>
  );
}
