import http from "../../utilities/http-common";

const getImageSeries = async () => {
  return await http.get("/Image/GetSeries");
};
const ImageSeriesService = {
  getImageSeries,
};

export default ImageSeriesService;
