import React, { useRef } from "react";

export default function FullScreen() {
  const icon = useRef(null);
  const mystyle = {
    position: "absolute",
    zIndex: "9999",
    right: "25px",
    top: "20px",
    border: "2px solid #dc3545",
  };
  const handleExpand = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      icon.current.className = "fas fa-compress-arrows-alt";
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        icon.current.className = "fas fa-arrows-alt";
      }
    }
  };
  return (
    <button
      className="btn btn-outline-danger  "
      onClick={handleExpand}
      style={mystyle}
    >
      <i ref={icon} className="fas fa-arrows-alt" aria-hidden="true"></i>
    </button>
  );
}
