import CtrlTwoService from "../Services/ctrlTwoService";
import {
  GET_CTRL_TWO_DATA,
  CTRL_TWO_RESPONSE_DATA,
  CTRL_ONE_RESPONSE_DATA,
} from "./types";

export const getCtrlTwoData = (data1, data2) => async (dispatch) => {
  try {
    const res = await CtrlTwoService.getCtrlTwoData(data1, data2);
    dispatch({
      type: GET_CTRL_TWO_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const ctrlTwoDataInsert = (data) => async (dispatch) => {
  try {
    const res = await CtrlTwoService.ctrlTwoDataInsert(data);
    dispatch({
      type: CTRL_TWO_RESPONSE_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const ctrlOneDataInsert = (data) => async (dispatch) => {
  try {
    const res = await CtrlTwoService.ctrlOneDataInsert(data);
    dispatch({
      type: CTRL_ONE_RESPONSE_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
