import ImageTaggingService from "../Services/imageTaggingService";
import {
  GET_IMAGE_DATA,
  UPDATE_IMAGE_COMMENT,
  IMAGE_DATA_INSERT,
  IMAGE_ENTRY_DATA_INSERT_FOR_FRC,
  SET_EMPTY_TAGGING,
} from "./types";

export const getImageData = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.getImageData(data);
    dispatch({
      type: GET_IMAGE_DATA,
      payload: res.data,
      // payload: res.data.filter((d, idx) => idx < 3),
    });
  } catch (err) {
    console.log(err);
  }
};
export const getImageDataForEntry = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.getImageDataForEntry(data);
    dispatch({
      type: GET_IMAGE_DATA,
      payload: res.data,
      // payload: res.data.filter((d, idx) => idx < 3),
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateImageComment = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.updateImageComment(data);
    dispatch({
      type: UPDATE_IMAGE_COMMENT,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const imageDataInsert = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.imageDataInsert(data);
    dispatch({
      type: IMAGE_DATA_INSERT,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const imageDataInsertIndex = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.imageDataInsertIndex(data);
    dispatch({
      type: IMAGE_DATA_INSERT,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
export const imageDataInsertForFRC =
  ({ data, link }) =>
  async (dispatch) => {
    try {
      const res =
        link === "FieldTag"
          ? await ImageTaggingService.imageDataInsertForFRCField(data)
          : await ImageTaggingService.imageDataInsertForFRCIndex(data);
      dispatch({
        type: IMAGE_ENTRY_DATA_INSERT_FOR_FRC,
        payload: data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const setDataFields = (data) => async (dispatch) => {
  try {
    const res = await ImageTaggingService.setDataFields(data);
    return Promise.resolve(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
export const setImageEmptyTagging = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_TAGGING,
      payload: [],
    });
  } catch (err) {
    console.log(err);
  }
};
