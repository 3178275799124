import http from "../../utilities/http-common";

const getSearchData = async (data) => {
  return await http.get(`/Image/GetGivenName/${data}`);
};

const SearchDataService = {
  getSearchData,
};

export default SearchDataService;
