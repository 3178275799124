import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFieldDataImageLine } from "../../Redux/Actions/allCheckingAction";
import { splitCordinate } from "../Helper/editHelper";
import { calculateTotalFromArray } from "../Helper/library";

export default function BottomFooterTableTwo({
  imagesData,
  selectedField,
  setFromImage,
  setToImage,
  selectedFieldValue,
  setSelectedImageNumber,
  setSelectedImage,
  selectedImageNumber,
  setUnderlineCoordinates,
}) {
  const dispatch = useDispatch();
  const selectedFieldDataImage = useSelector(
    (state) => state.allCheckingReducer?.fieldDataImage
  );

  useEffect(() => {
    dispatch(
      getFieldDataImageLine(
        selectedImageNumber,
        selectedField,
        selectedFieldValue
      )
    );
  }, [selectedImageNumber]);

  useEffect(() => {
    if (imagesData.length > 0) {
      const selectedCordinate = imagesData
        .filter(
          (row) =>
            row.IMAGE_NUMBER === selectedImageNumber &&
            row[selectedField] === selectedFieldValue
        )
        .map((item) => splitCordinate(item, selectedField));
      setUnderlineCoordinates(selectedCordinate);
    }
  }, [imagesData, selectedImageNumber, selectedField]);
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "26vh",
          maxHeight: "26vh",
          overflow: "scroll",
          background: "#FFFFE6",
          padding: "0px",
          marginTop: "1vh",
        }}
        className="col-2"
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "2px solid #b1b1b1",
                  borderTop: "0px solid",
                  borderBottom: "0px solid",
                  background: "#d3d3d3",
                  fontSize: "12px",
                  padding: "4px",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  width: "80%",
                  overflowWrap: "anywhere",
                }}
                size="small"
              >
                Images (
                {selectedFieldDataImage !== undefined &&
                  calculateTotalFromArray(selectedFieldDataImage)}
                )
              </TableCell>
              <TableCell
                sx={{
                  border: "2px solid #b1b1b1",
                  borderTop: "0px solid",
                  borderBottom: "0px solid",
                  background: "#d3d3d3",
                  fontSize: "12px",
                  padding: "4px",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                }}
                size="small"
              >
                Count
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedFieldDataImage?.map((row, index) => (
              <TableRow
                key={index}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                sx={{
                  padding: "0px",
                  margin: "0px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  dispatch(
                    getFieldDataImageLine(
                      row["IMAGE_NUMBER"],
                      selectedField,
                      selectedFieldValue
                    )
                  );
                  if (e.ctrlKey) {
                    setToImage(row["IMAGE_NUMBER"]);
                  } else {
                    setFromImage(row["IMAGE_NUMBER"]);
                    setToImage(row["IMAGE_NUMBER"]);
                  }
                  setSelectedImageNumber(row["IMAGE_NUMBER"]);
                  setSelectedImage(`${row["IMAGE_NUMBER"]}.jpg`);
                }}
              >
                <TableCell
                  sx={{
                    border: "2px solid #b1b1b1!important",
                    padding: "0px",
                    borderBottom: "0px",
                    width: "80%",
                  }}
                  size="small"
                >
                  <input
                    value={row["IMAGE_NUMBER"]}
                    type="text"
                    style={{
                      width: "100%",
                      padding: "2px 4px",
                      border: "none",
                      background:
                        row["IMAGE_NUMBER"] === selectedImageNumber &&
                        "#00a1ffc4",
                      cursor: "pointer",
                    }}
                    readOnly
                  />
                </TableCell>
                <TableCell
                  sx={{
                    border: "2px solid #b1b1b1!important",
                    padding: "0px",
                    borderBottom: "0px",
                  }}
                  size="small"
                >
                  <input
                    value={row["TOTAL_COUNT"]}
                    type="text"
                    style={{
                      width: "100%",
                      padding: "2px 4px",
                      border: "none",
                      background:
                        row["IMAGE_NUMBER"] === selectedImageNumber &&
                        "#00a1ffc4",
                      cursor: "pointer",
                    }}
                    readOnly
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
