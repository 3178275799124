import FocusImageService from "../Services/focusImageService";
import { GET_IMAGE_OFFSET, SET_IMAGE_OFFSET } from "./types";

export const setImageOffset = (data) => async (dispatch) => {
  try {
    const res = await FocusImageService.setImageOffset(data);
    dispatch({
      type: SET_IMAGE_OFFSET,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getImageOffset = (series, code) => async (dispatch) => {
  try {
    const res = await FocusImageService.getImageOffset(series, code);
    dispatch({
      type: GET_IMAGE_OFFSET,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
