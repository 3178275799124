import entryCountService from "../Services/entryCountService";
import { GET_ENTRY_COUNT_DATA } from "./types";

export const getEntryCountData = (startDate, endDate) => async (dispatch) => {
  try {
    const res = await entryCountService.getEntryCountData(startDate, endDate);
    dispatch({
      type: GET_ENTRY_COUNT_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
