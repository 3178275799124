import dataEditService from "../Services/dataEditService";
import {
  CREATE_LINE,
  DELETE_DATA,
  DELETE_LINE,
  GET_SERIES_DATA_FOR_EDIT,
  UPDATE_DATA,
  UPDATE_IMAGE_COMMENT,
} from "./types";

export const getImageData = (seriesNumber) => async (dispatch) => {
  try {
    const res = await dataEditService.getSeriesDataForEdit(seriesNumber);
    dispatch({
      type: GET_SERIES_DATA_FOR_EDIT,
      payload: res.data,
      // payload: res.data.filter((d, idx) => idx < 3),
    });
  } catch (err) {
    console.log(err);
  }
};
export const updateData = (data) => async (dispatch) => {
  try {
    const res = await dataEditService.updateData(data);
    dispatch({
      type: UPDATE_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const deleteImage = (seriesNumber, imageNumber) => async (dispatch) => {
  try {
    const res = await dataEditService.deleteImageFromEdit(
      seriesNumber,
      imageNumber
    );
    dispatch({
      type: DELETE_DATA,
      payload: imageNumber,
    });
  } catch (err) {
    console.log(err);
  }
};
export const deleteLine = (data) => async (dispatch) => {
  try {
    const res = await dataEditService.deleteImageLineFromEdit(data);
    dispatch({
      type: DELETE_LINE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const createLine = (data) => async (dispatch) => {
  try {
    const res = await dataEditService.createLine(data);
    dispatch({
      type: CREATE_LINE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const updateImageComment = (data) => async (dispatch) => {
  try {
    const res = await dataEditService.updateImageComment(data);
    dispatch({
      type: UPDATE_IMAGE_COMMENT,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
