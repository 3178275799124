import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Input,
  Dialog,
  DialogContent,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
export default function SearchField({
  open,
  onClose,
  type,
  setType,
  selectedField,
  setSearchTarget,
}) {
  useEffect(() => {
    setType("full");
  }, []);
  const onSubmit = (e) => {
    if (e.which === 13) {
      setSearchTarget({
        field: selectedField,
        value: e.target.value,
      });
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        className="d-flex align-items-center"
        style={{ padding: "10px 15px" }}
      >
        <RadioGroup
          className="footerBottom"
          aria-label="language"
          name="row-radio-buttons-group"
          defaultValue="German"
          value={type}
          sx={{
            my: 0,
            px: 1,
            paddingBottom: 0.5,
            paddingTop: -0.5,
          }}
          onChange={(e) => setType(e.target.name)}
        >
          <FormControlLabel
            sx={{
              marginBottom: 0,
              height: "20px",
            }}
            value="full"
            name="full"
            control={<Radio size="small" sx={{ py: 0, px: 1 }} />}
            label={
              <span
                style={{
                  fontSize: "11px",
                  fontWeight: "600",
                }}
              >
                full
              </span>
            }
          />{" "}
          <FormControlLabel
            sx={{
              marginBottom: 0,
              height: "20px",
            }}
            value="partial"
            name="partial"
            control={<Radio size="small" sx={{ py: 0, px: 1 }} />}
            label={
              <span
                style={{
                  fontSize: "11px",
                  fontWeight: "600",
                }}
              >
                partial
              </span>
            }
          />
        </RadioGroup>
        <Input
          placeholder="search text"
          onKeyUp={(e) => onSubmit(e)}
          autoFocus
        />
        <CloseIcon onClick={onClose} sx={{ marginLeft: "20px" }} />
      </DialogContent>
    </Dialog>
  );
}
