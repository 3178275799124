import {
  CTRL_ONE_RESPONSE_DATA,
  CTRL_TWO_RESPONSE_DATA,
  GET_CTRL_TWO_DATA,
} from "../Actions/types";

const initialState = 0;

function continueImageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CTRL_TWO_DATA:
      return payload;

    case CTRL_TWO_RESPONSE_DATA:
      return payload;

    case CTRL_ONE_RESPONSE_DATA:
      return payload;

    default:
      return state;
  }
}

export default continueImageReducer;
