import tagCheckingService from "../Services/tagCheckingService";
import { GET_All_TAGGED_IMAGE, GET_SINGLE_TAGGED_IMAGE_DATA } from "./types";

export const getAllTaggedImages = (seriesNumber) => async (dispatch) => {
  try {
    const res = await tagCheckingService.getAllTaggedImages(seriesNumber);
    dispatch({
      type: GET_All_TAGGED_IMAGE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getSingleTaggedImageDetails =
  (imageNumber) => async (dispatch) => {
    try {
      const res = await tagCheckingService.getSingleTaggedImageDetails(
        imageNumber
      );
      dispatch({
        type: GET_SINGLE_TAGGED_IMAGE_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
