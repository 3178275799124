import { Box, Grid } from "@mui/material";
import disableScroll from "disable-scroll";
import img2 from "../../images/white.png";
import React, { useEffect, useRef, useState } from "react";
import { settingRatio, setTransform, zooming } from "../Helper/commonHelper";
import { splitCordinate } from "./tagCheckingHelper";
import { createCanvas } from "../Helper/library";
import { useWindowSize } from "../../hooks/useWindowSize";

export default function TagCheckingImage({
  imgRef,
  imagesData,
  continueImage,
  isSecondImageClicked,
  setFocusData,
  selectedSeries,
  selectedImage,
}) {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const mainSectionRef = useRef(null);
  const canvRef = useRef(null);

  const [hwRatio, setHWRatio] = useState(null);
  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [imgDiv, setImgDiv] = useState(null);
  const [img, setImg] = useState(null);
  const [scale, setScale] = useState(1);
  const [previousLeftForFullLine, setPreviousLeftForFullLine] = useState(null);
  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    setTransform(canvRef, scale);
  }, [scale]);
  useEffect(() => {
    if (document) {
      document.getElementById("imagesss").addEventListener("load", () => {
        setImg({
          height: document.getElementById("imagesss").naturalHeight,
          width: document.getElementById("imagesss").naturalWidth,
        });
        setImgDiv({
          height: document.getElementById("imageContainer").clientHeight,
          width: document.getElementById("imageContainer").clientWidth,
        });
      });
    }
  }, [selectedImage]);
  useEffect(() => {
    if (imgDiv !== null && img !== null) {
      setHWRatio(settingRatio(imgDiv, img));
    }
  }, [img, imgDiv]);

  useEffect(() => {
    if (hwRatio !== null) {
      setHeightRatio(hwRatio.heightRatio);
      setWidthRatio(hwRatio.widthRatio);
    }
  }, [hwRatio]);
  useEffect(() => {
    if (imagesData !== null) {
      var selectLastTag = document?.querySelectorAll("canvas");
      if (selectLastTag?.length > 0) {
        selectLastTag.forEach((item) => {
          canvRef.current.removeChild(item);
        });
      }
      imagesData?.details?.map((item) => {
        if (item.RIGHT_XCORD !== 0) {
          createFocusLine(
            "",
            item.LEFT_XCORD,
            item.TOP_YCORD,
            item.RIGHT_XCORD,
            item.BOTTOM_YCORD,
            1
          );
        } else {
          const keys = Object.keys(item);
          keys.map((key) => {
            if (key.includes("_POS") && item[key] !== null) {
              const focusData = splitCordinate(item[key]);
              createFocusLine(
                key,
                focusData[1],
                focusData[0],
                focusData[2],
                focusData[3],
                generateHexColor(item["LINE"]),
                1
              );
            }
          });
        }
      });
    }
  }, [imagesData]);

  const createFocusLine = (key, x, y, w, h, color = "red", opacityData = 1) => {
    const appendRef = canvRef.current;

    const canv = createCanvas(
      "focusedPosition",
      key,
      x * widthRatio + "px",
      y * heightRatio + "px",
      w + "px",
      h + "px",
      color,
      opacityData
    );
    appendRef.appendChild(canv);
  };

  function generateHexColor(num) {
    var hexColor = (num * 999 + 100).toString(16); //convert the number to hexadecimal
    while (hexColor.length < 6) {
      //append leading 0s if the hex color is less than 6 characters
      hexColor = hexColor + "0";
    }
    return "#" + hexColor;
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          maxHeight: "100vh",
          overflow: "scroll",
          resize: "vertical",
        }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                id="imageContainer"
                className="zoom imgdiv"
                onWheel={(e) => zooming(e, canvRef, setFocusData, setScale)}
                onMouseLeave={() => disableScroll.off()}
                onMouseEnter={() => disableScroll.on()}
                onMouseOver={() => disableScroll.on()}
              >
                <img
                  id="imagesss"
                  className="imageContainer"
                  tabIndex="-1"
                  ref={imgRef}
                  src={
                    selectedImage === null
                      ? img2
                      : isSecondImageClicked && continueImage
                      ? `${imageBaseUrl}${selectedSeries}/${continueImage}`
                      : `${imageBaseUrl}${selectedSeries}/${selectedImage}`
                  }
                  alt="img"
                />
              </div>
            </Box>
          </Grid>
        </Grid>{" "}
      </Box>
    </>
  );
}
