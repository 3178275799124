import {
  createLine,
  deleteLine,
  updateImageComment,
} from "../../Redux/Actions/dataEditAction";

export const handleTableRaw = (imagesData, selectedType) => {
  if (selectedType.value == "99") {
    let x = [];
    imagesData.filter((images) => {
      for (const field of handleTableHeader(imagesData)) {
        if (images[field] !== null && images[field].toString().includes("?")) {
          x.push(images);
          break;
        }
      }
    });
    return x;
  } else if (selectedType.value == "98") {
    let x = [];
    imagesData.filter((images) => {
      for (const field of handleTableHeader(imagesData)) {
        if (images[field] !== null && images[field].toString().includes("*")) {
          x.push(images);
          break;
        }
      }
    });
    return x;
  } else if (
    selectedType.value == "1" ||
    selectedType.value == "2" ||
    selectedType.value == "3" ||
    selectedType.value == "4" ||
    selectedType.value == "5" ||
    selectedType.value == "6" ||
    selectedType.value == "7" ||
    selectedType.value == "8" ||
    selectedType.value == "9"
  ) {
    let x = [];
    imagesData.filter((images) => {
      if (images["CODE"] == selectedType.value) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "11") {
    return [];
  } else if (selectedType.value == "12") {
    let x = [];
    imagesData.filter((images) => {
      if (images["GENDER"] !== null) {
        if (
          images["GENDER"].toString().toUpperCase() === "MALE" &&
          images["GIVENNAME"] !== null &&
          (images["GIVENNAME"].toString().toUpperCase().endsWith("A") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith(" A") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("IE") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("INE") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("YOU"))
        ) {
          x.push(images);
        }
      }
    });
    return x;
  } else if (selectedType.value == "13") {
    let x = [];
    imagesData.filter((images) => {
      if (images["GENDER"] !== null) {
        if (
          images["GENDER"].toString().toUpperCase() === "FEMALE" &&
          images["GIVENNAME"] !== null &&
          (images["GIVENNAME"].toString().toUpperCase().endsWith("O") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("AS") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("US") ||
            images["GIVENNAME"].toString().toUpperCase().endsWith("IUS"))
        ) {
          x.push(images);
        }
      }
    });
    return x;
  } else if (selectedType.value == "14") {
    let x = [];
    imagesData.filter((images) => {
      if (images["GENDER"] !== null) {
        if (images["GENDER"].toString().toUpperCase() === "FEMALE") {
          if (
            images["SPOUSE_GIVENNAME"] !== null &&
            (images["SPOUSE_GIVENNAME"]
              .toString()
              .toUpperCase()
              .endsWith("A") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith(" A") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("IE") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("INE") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("YOU"))
          ) {
            x.push(images);
          }
        } else if (images["GENDER"].toString().toUpperCase() === "MALE") {
          if (
            images["SPOUSE_GIVENNAME"] !== null &&
            (images["SPOUSE_GIVENNAME"]
              .toString()
              .toUpperCase()
              .endsWith("O") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("AS") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("US") ||
              images["SPOUSE_GIVENNAME"]
                .toString()
                .toUpperCase()
                .endsWith("IUS"))
          ) {
            x.push(images);
          }
        }
      }
    });
    return x;
  } else if (selectedType.value == "15") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["FATHER_GIVENNAME"] !== null &&
        (images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("A") ||
          images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith(" A") ||
          images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("IE") ||
          images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("INE") ||
          images["FATHER_GIVENNAME"].toString().toUpperCase().endsWith("YOU"))
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "16") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["MOTHER_GIVENNAME"] !== null &&
        (images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("O") ||
          images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("AS") ||
          images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("US") ||
          images["MOTHER_GIVENNAME"].toString().toUpperCase().endsWith("IUS"))
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "17") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["SPOUSE_FATHER_GIVENNAME"] !== null &&
        (images["SPOUSE_FATHER_GIVENNAME"]
          .toString()
          .toUpperCase()
          .endsWith("A") ||
          images["SPOUSE_FATHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith(" A") ||
          images["SPOUSE_FATHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("IE") ||
          images["SPOUSE_FATHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("INE") ||
          images["SPOUSE_FATHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("YOU"))
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "18") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["SPOUSE_MOTHER_GIVENNAME"] !== null &&
        (images["SPOUSE_MOTHER_GIVENNAME"]
          .toString()
          .toUpperCase()
          .endsWith("O") ||
          images["SPOUSE_MOTHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("AS") ||
          images["SPOUSE_MOTHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("US") ||
          images["SPOUSE_MOTHER_GIVENNAME"]
            .toString()
            .toUpperCase()
            .endsWith("IUS"))
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "19") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["SURNAME"] !== null &&
        images["FATHER_SURNAME"] !== null &&
        images["SURNAME"].toString().toUpperCase() !==
          images["FATHER_SURNAME"].toString().toUpperCase()
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "20") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["SPOUSE_SURNAME"] !== null &&
        images["SPOUSE_FATHER_SURNAME"] !== null &&
        images["SPOUSE_SURNAME"].toString().toUpperCase() !==
          images["SPOUSE_FATHER_SURNAME"].toString().toUpperCase()
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "21") {
    let x = [];
    imagesData.filter((images) => {
      if (
        images["SPOUSE_SURNAME"] === null &&
        images["SPOUSE_GIVENNAME"] === null &&
        images["SPOUSE_MAIDEN_NAME"] === null
      ) {
        x.push(images);
      }
    });
    return x;
  } else if (selectedType.value == "22") {
    let x = [];
    imagesData.filter((images) => {
      if (images["BIRTH_DATE"] !== null && images["BAPTISM_DATE"] !== null) {
        const date1 = new Date(`${images["BIRTH_DATE"]}`);
        const date2 = new Date(`${images["BAPTISM_DATE"]}`);
        if (date1 > date2) {
          x.push(images);
        }
      }
    });
    return x;
  } else if (selectedType.value == "23") {
    let x = [];
    imagesData.filter((images) => {
      if (images["DEATH_DATE"] !== null && images["BURIAL_DATE"] !== null) {
        const date1 = new Date(`${images["DEATH_DATE"]}`);
        const date2 = new Date(`${images["BURIAL_DATE"]}`);
        if (date1 > date2) {
          x.push(images);
        }
      }
    });
    return x;
  }
};

export const handleTableHeader = (imagesData) => {
  if (imagesData?.length > 0) {
    let newArr = [];
    Object.keys(imagesData[0])?.map((image) => {
      if (
        !image.includes("_POS") &&
        !image.includes("_PAGE") &&
        !image.includes("CORD") &&
        !image.includes("TEMP") &&
        !image.includes("SORTING")
      ) {
        newArr.push(image);
      }
    });
    return newArr;
  }
};

export const createCanvas = (canvasId, x, y, w, h, color, opacityData) => {
  const cvs = document.createElement("canvas");
  cvs.id = canvasId;
  cvs.style.position = "absolute";
  cvs.style.left = x;
  cvs.style.top = y;
  cvs.style.width = w;
  cvs.style.height = h;
  cvs.style.background = color;
  cvs.style.opacity = opacityData;

  return cvs;
};
export const createCanvas2 = (canvasId, x, y) => {
  const cvs = document.createElement("canvas");
  cvs.id = canvasId;
  cvs.style.position = "absolute";
  cvs.style.left = x;
  cvs.style.top = y;
  cvs.style.width = "1px";
  cvs.style.height = "1px";
  cvs.style.background = "red";
  cvs.style.opacity = 0;

  return cvs;
};
export const splitCordinate = (row, cell) => {
  let currenInputFeild;
  if (
    cell === "GIVENNAME" ||
    cell === "SURNAME" ||
    cell === "MAIDEN_NAME" ||
    cell === "SUFFIX" ||
    cell === "PREFIX"
  ) {
    currenInputFeild = "GIVENNAME";
  } else if (
    cell === "FATHER_GIVENNAME" ||
    cell === "FATHER_SURNAME" ||
    cell === "FATHER_SUFFIX"
  ) {
    currenInputFeild = "FATHER_GIVENNAME";
  } else if (
    cell === "MOTHER_GIVENNAME" ||
    cell === "MOTHER_SURNAME" ||
    cell === "MOTHER_MAIDEN_NAME" ||
    cell === "MOTHER_SUFFIX" ||
    cell === "MOTHER_PREFIX"
  ) {
    currenInputFeild = "MOTHER_GIVENNAME";
  } else if (
    cell === "SPOUSE_GIVENNAME" ||
    cell === "SPOUSE_SURNAME" ||
    cell === "SPOUSE_MAIDEN_NAME" ||
    cell === "SPOUSE_SUFFIX" ||
    cell === "SPOUSE_PREFIX"
  ) {
    currenInputFeild = "SPOUSE_GIVENNAME";
  } else if (
    cell === "SPOUSE_FATHER_GIVENNAME" ||
    cell === "SPOUSE_FATHER_SURNAME" ||
    cell === "SPOUSE_FATHER_SUFFIX"
  ) {
    currenInputFeild = "SPOUSE_FATHER_GIVENNAME";
  } else if (
    cell === "SPOUSE_MOTHER_GIVENNAME" ||
    cell === "SPOUSE_MOTHER_SURNAME" ||
    cell === "SPOUSE_MOTHER_MAIDEN_NAME" ||
    cell === "SPOUSE_MOTHER_PREFIX" ||
    cell === "SPOUSE_MOTHER_SUFFIX"
  ) {
    currenInputFeild = "SPOUSE_MOTHER_GIVENNAME";
  } else {
    currenInputFeild = cell;
  }
  // setSelectedInputField(cell);
  // if (
  //   ctrlTwo !== null &&
  //   ctrlTwo[
  //     cell === "FATHER_GIVENNAME" || cell === "FATHER_SURNAME"
  //       ? "FATHER_NAME"
  //       : cell === "MOTHER_GIVENNAME"
  //       ? "MOTHER_NAME"
  //       : cell === "SPOUSE_GIVENNAME"
  //       ? "SPOUSE_NAME"
  //       : cell
  //   ] === 2 &&
  //   continueImage !== 0
  // ) {
  //   setImageUrl(
  //     `${imageBaseUrl}${images[currentImgIndex]?.SERIES_NUMBER}/${continueImage}`
  //   );
  // } else {
  //   setImageUrl(
  //     `${imageBaseUrl}${images[currentImgIndex]?.SERIES_NUMBER}/${images[currentImgIndex]?.IMAGE_NAME}`
  //   );
  // }
  if (row[`${currenInputFeild}_POS`] !== undefined) {
    if (row[`${currenInputFeild}_POS`] !== null) {
      // if (row[`${currenInputFeild}_PAGE`] !== null) {
      //   setImageUrl(
      //     `${imageBaseUrl}${images[currentImgIndex]?.SERIES_NUMBER}/${
      //       row[`${currenInputFeild}_PAGE`]
      //     }`
      //   );
      // } else {
      //   setImageUrl(
      //     `${imageBaseUrl}${images[currentImgIndex]?.SERIES_NUMBER}/${images[currentImgIndex]?.IMAGE_NAME}`
      //   );
      // }
      const x = row[`${currenInputFeild}_POS`].split(";");
      // setFixedLeftData(x);
      return x;
      // handlefocus(x[0], x[1], x[3], x[2], currenInputFeild);
      // setPreviousCoordinateData(x);
    } else return [0, 0, 0, 0];
  }
  // else if (imageCoordinates["FORMAT"] === 1) {
  //   handlefocus(
  //     imageCoordinates["LEFT_XCORD"],
  //     imageCoordinates["TOP_YCORD"],
  //     imageCoordinates["BOTTOM_YCORD"],
  //     imageCoordinates["RIGHT_XCORD"],
  //     currenInputFeild
  //   );
  //   setFixedLeftData(null);
  // } else {
  //   handlefocus(
  //     previousCoordinateData[0],
  //     previousCoordinateData[1],
  //     previousCoordinateData[3],
  //     previousCoordinateData[2],
  //     currenInputFeild,
  //     0
  //   );
  //   setFixedLeftData(null);
  // }
};
export const handleDuplicateImage = (
  dispatch,
  handleClose,
  imageNumber,
  imageComment,
  seriesNumber,
  imageName,
  code,
  format,
  line
) => {
  if (imageComment === "Duplicate") {
    imageNumber !== null &&
      dispatch(
        updateImageComment({
          COMMENTS: -999,
          IMAGE_NUMBER: imageNumber,
          SERIES_NUMBER: seriesNumber,
        })
      );
  } else if (imageComment === "Missing Insert") {
    imageNumber !== null &&
      dispatch(
        createLine({
          seriesNumber: seriesNumber,
          imageNumber: imageNumber,
          imageName: imageName,
          CODE: code,
          FORMAT: format,
          LINE: line,
        })
      );
  } else if (imageComment === "Delete") {
    imageNumber !== null &&
      dispatch(
        deleteLine({
          seriesNumber: seriesNumber,
          imageNumber: imageNumber,
          imageName: imageName,
          CODE: code,
          FORMAT: format,
          LINE: line,
        })
      );
  }

  handleClose();
};

export const sorting = (tableBody, selectedSort, selectedField) => {
  let sortedArray = [];
  let newTable = [];
  tableBody.forEach((row) => {
    sortedArray.push({ value: row[selectedField], row: row });
  });
  if (selectedSort === "Sort Ascending") {
    if (selectedField.includes("_DATE")) {
      sortedArray.sort(
        (a, b) => new Date(a.value).getTime() - new Date(b.value).getTime()
      );
    } else {
      sortedArray.sort((a, b) => {
        if (
          containsNumbers(a.value) ||
          containsNumbers(b.value) ||
          a.value === null ||
          b.value === null
        ) {
          return a.value > b.value ? -1 : 1;
        } else return a.value.toUpperCase() > b.value.toUpperCase() ? -1 : 1;
      });
    }
  } else if (selectedSort === "Sort Descending") {
    if (selectedField.includes("_DATE")) {
      sortedArray.sort(
        (a, b) => new Date(b.value).getTime() - new Date(a.value).getTime()
      );
    } else
      sortedArray.sort((a, b) => {
        if (
          containsNumbers(a.value) ||
          containsNumbers(b.value) ||
          a.value === null ||
          b.value === null
        ) {
          return b.value > a.value ? -1 : 1;
        } else return b.value.toUpperCase() > a.value.toUpperCase() ? -1 : 1;
      });
  }
  sortedArray.forEach((indexNo) => {
    newTable.push(indexNo.row);
  });
  return newTable;
};

const containsNumbers = (str) => {
  return /[0-9]/.test(str);
};
