import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import disableScroll from "disable-scroll";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import FullScreen from "./../FullScreen/FullScreen";
import Sidebar from "./../Sidebar/Sidebar";

import {
  getImageData,
  updateImageComment,
  imageDataInsert,
  imageDataInsertForFRC,
} from "../../Redux/Actions/imageTaggingAction";
import { resetFieldTagMenu, getBBDStatusCode } from "../Helper/taggingHelper";
import {
  allFieldsObject,
  languageCode,
  seriesMenuHeights,
} from "../Helper/entryField";
import {
  fieldSequenceGenerator,
  getDataObjectByImgCode,
  menuFieldsGenerator,
  pascalToCamelCase,
} from "../Helper/library";
import { setFieldSequence } from "../../Redux/Actions/fieldSequenceActions";
import { notifyWarn } from "../Helper/toasts";
import { Zooming } from "../Helper/entryHelper";
import {
  DEFAULT_TYPE_CODE,
  DEFAULT_TYPE,
  MIXED_TYPE_CODE,
  ESCAPE_KEY_CODE,
} from "../Helper/constants";
import { toast } from "react-toastify";

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export default function Tagging() {
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const imgRef = useRef(null);
  const keyevent = useRef(null);
  const canvRef = useRef(null);
  const rightMenu = useRef(null);
  const multipleType = useRef(null);
  const mainSectionRef = useRef(null);

  const [disabled, setDisabled] = useState(false);
  const [disabledAddRecord, setDisabledAddRecord] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [bbdCheck, setBbdCheck] = useState([]);
  const [open, setOpen] = useState(false);
  const [imgComment, setImgComment] = useState("");
  const [imgType, setImgType] = useState({
    type: Object.entries(languageCode)[0][0],
    status: Object.entries(languageCode)[0][1],
  }); //Language
  const [imgCode, setImgCode] = useState(0); //DataType
  const [menuHeight, setMenuHeight] = useState(448);
  const [imgEvent, setImgEvent] = useState(null); //multiple Type
  const [fieldSequenceState, setFieldSequenceState] = useState([]);

  const [records, setRecords] = useState([]);
  const [positionXY, setPositionXY] = useState({
    x: 0,
    y: 0,
  });

  const [menuOptions, setMenuOptions] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [selectedSeries, setSelectedSeries] = useState(selectedSeriesfromLocal);
  const [lineNo, setLineNo] = useState(0);
  const [localData, setLocalData] = useState({});
  const [images, setImages] = useState([]);
  const [scale, setScale] = useState(1);

  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [imageHW, setImageHW] = useState(null);

  const imagesData = useSelector((state) => state.imageTaggingReducer);

  //=======================================================
  //sideeffects and others start
  //=====================================================
  //Ctrl+(+/-)-> Zoom In/Out
  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) != -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  // Generates the js for the DINAMIC menu.(testing)[start]
  useEffect(() => {
    if (imgCode !== 0) {
      const fields = getDataObjectByImgCode(allFieldsObject, imgCode).fields;
      const newMenus = menuFieldsGenerator("tag", fields);
      const fieldSecuences = fieldSequenceGenerator(
        fields,
        selectedSeries,
        imgCode === MIXED_TYPE_CODE ? DEFAULT_TYPE_CODE : imgCode
      );

      setMenuOptions(newMenus);
      setFieldSequenceState([fieldSecuences]);

      if (imgCode === MIXED_TYPE_CODE) {
        multipleType.current.disabled = false;
      } else {
        multipleType.current.disabled = true;
        setImgEvent(null);
      }
    }
  }, [imgCode]);

  useEffect(() => {
    if (imgEvent !== null) {
      const dataType = allFieldsObject[pascalToCamelCase(imgEvent)];
      const newMenus = menuFieldsGenerator("tag", dataType.fields);
      const fieldSecuences = fieldSequenceGenerator(
        dataType.fields,
        selectedSeries,
        dataType.imgCode
      );

      setMenuOptions(newMenus);

      setFieldSequenceState((prevState) => {
        if (prevState.some(({ CODE }) => CODE === fieldSecuences.CODE)) {
          return prevState;
        } else return [...prevState, fieldSecuences];
      });
    }
  }, [imgEvent]);

  // Generates the js for the DINAMIC menu.(testing)[end]

  useEffect(() => {
    document.title = "Field Tagging | Image Tagging | Data Keying";
    dispatch(getImageData(selectedSeriesfromLocal));
  }, []);
  useEffect(() => {
    if (selectedSeriesfromLocal) {
      setSelectedSeries(selectedSeriesfromLocal);
      dispatch(getImageData(selectedSeriesfromLocal));
    }
    // Returns true if the request was successful.
  }, [selectedSeriesfromLocal]);

  useEffect(() => {
    setImages(imagesData);
  }, [imagesData]);

  useEffect(() => {
    checking();
    checkingCanvas();
  });

  useEffect(() => {
    window.addEventListener("resize", settingRation);
    let timer1 = setTimeout(() => settingRation(), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [widthRatio, heightRatio]);

  //=====================================================
  //sideeffects and others end
  //=======================================================

  //=======================================================
  //Getting the height and width ratio start
  //=====================================================

  // Setting height and width ratio.
  function settingRation() {
    if (document !== undefined) {
      var windowSize = document?.querySelectorAll("#imagesss");
      var w = windowSize[0]?.clientWidth;
      var h = windowSize[0]?.clientHeight;
      var myImg = document.querySelector("#imagesss");
      var realWidth = myImg?.naturalWidth;
      var realHeight = myImg?.naturalHeight;
      setImageHW({ width: realWidth, height: realHeight });

      var widthRatios = w / realWidth;
      var heightRatios = h / realHeight;

      setWidthRatio(widthRatios);
      setHeightRatio(heightRatios);
    }
  }

  //=======================================================
  //--------------------images Bbd check  start----------------------
  // Opens a dialog for bbd confirmation also set clicked status whether it is bad or blank or duplicate.
  const handleClickOpen = (statusCode) => {
    setImgComment(statusCode);
    setOpen(true);
  };

  // Closes the bbd confirmation dialog.
  const handleClose = () => {
    setOpen(false);
  };

  // Checks and set the BBD status for an image.
  const handleBBD = (name) => {
    //for  select image as bbd
    const status = getBBDStatusCode(imgComment);
    images.map((image) => {
      if (image.IMAGE_NUMBER === name.IMAGE_NUMBER) {
        return dispatch(
          updateImageComment({
            COMMENTS: status,
            IMAGE_NUMBER: image.IMAGE_NUMBER,
            SERIES_NUMBER: image.SERIES_NUMBER,
            IMAGE_WIDTH: imageHW?.width,
            IMAGE_HEIGHT: imageHW?.height,
          })
        );
      } else {
        return image;
      }
    });

    setBbdCheck([
      ...bbdCheck,
      {
        imageType: imgComment,
        imageName: name.name,
        imageLocation: name.location,
        imageId: name.id,
      },
    ]);
    setOpen(false);
    // document && removeElements(document.querySelectorAll("hidden-tag"));
    goToNextSlide();
  };
  //-------------------images Bbd check end---------------------
  //=======================================================

  // Removes all matched elements.
  const removeElements = (elms) => {
    //for removing child element
    elms.forEach((el) => el.remove());
  };

  //=======================================================
  //-----------------prev and next images start-----------------

  // checking whether is there any next image availabe or not?
  let hasNext = currentIndex < imagesData?.length - 1;

  // Moves to the next slide.
  const goToNextSlide = () => {
    setLineNo(0);
    // removeElements(document?.querySelectorAll("hidden-tag"));
    if (hasNext) {
      setCurrentIndex(currentIndex + 1);
    }
    // removeElements(document?.querySelectorAll("canvas"));
    // setMenuOptions(baptismMenuState);
  };
  //------------------prev and next images end------------------
  //=======================================================

  //=======================================================
  //radio button click menu change start
  //=====================================================
  //-----------------Language start---------------
  const handleLanguage = (event) => {
    const selectedType = event.target.value;
    setImgType({ type: selectedType, status: languageCode[selectedType] });
  };
  //-----------------Language End---------------
  //==============
  //Event1 start
  //============
  // The data type event handler which actually set code(baptism/burial/marriage) for a line.
  const handleDataType = (event) => {
    const selectedType = event.target.value;
    const dataType = allFieldsObject[pascalToCamelCase(selectedType)];

    clearForDataTypeChange(event);
    setImgCode(dataType.imgCode);

    if (selectedType === "Mixed") {
      setImgEvent(DEFAULT_TYPE);
      multipleType.current.disabled = false;
    } else {
      setMenuHeight(
        seriesMenuHeights[pascalToCamelCase(selectedSeries)] ||
          seriesMenuHeights.default
      );
    }
  };
  //============
  // Event handler for multiple types which set EVENT TYPE(baptism/burial/marriage) for multiple .
  const handleMultipleType = (event) => {
    clearForMultipleTag(event);
    setImgEvent(event.target.value);
  };
  //===========
  //Event2 End
  //=============
  //=======================================================
  //radio button click menu change end
  //=======================================================

  //=======================================================
  //enable and disabling button start
  //=======================================================

  const checking = () => {
    var cavCount = document.querySelectorAll(".hidden-tag");
    cavCount.length > 0 ? setDisabled(true) : setDisabled(false);
  };
  const checkingCanvas = () => {
    var cavCount = document.querySelectorAll("canvas");
    cavCount.length > 0
      ? setDisabledAddRecord(true)
      : setDisabledAddRecord(false);
  };

  //=======================================================
  //enable and disabling button end
  //=======================================================

  //====================
  //image zooming start
  //====================
  var setTransform = () => {
    const zoomImg = canvRef.current;
    zoomImg.style.transform = "scale(" + scale + ")";
  };

  useEffect(() => {
    setTransform();
  }, [scale]);
  useEffect(() => {
    if (rightMenu.current.clientHeight !== 0) {
      setMenuHeight(rightMenu.current.clientHeight);
    } else {
      setMenuHeight(
        seriesMenuHeights[pascalToCamelCase(selectedSeries)] ||
          seriesMenuHeights.default
      );
    }
  }, [rightMenu?.current?.clientHeight, selectedSeries]);
  //==================
  //image zooming end
  //==================

  //=====================================================
  //onclick menu showing and event handling start
  //=======================================================

  const handleMenu = (e) => {
    e.preventDefault();
    setMenuHeight(rightMenu.current.clientHeight);
    const showingMenu = rightMenu.current;
    const mainSec = mainSectionRef.current;
    const appendRef = canvRef.current;
    const bounds = appendRef.getBoundingClientRect();
    const newPosx =
      scale > 0
        ? (e.clientX - bounds.left) / scale
        : (e.clientX - bounds.left) * scale;
    const newPosy =
      scale > 0
        ? (e.clientY - bounds.top) / scale
        : (e.clientY - bounds.top) * scale;

    let newPos = { x: newPosx / widthRatio, y: newPosy / heightRatio };

    //for menu click coordinate
    const rectMenu = mainSec.getBoundingClientRect();

    const x = e.clientX - rectMenu.left;
    const y = e.clientY - rectMenu.top;

    // Set the position for menu
    if (x + 250 < rectMenu.width) {
      showingMenu.style.left = `${x}px`;
    } else showingMenu.style.left = `${x - 250}px`;
    if (y + menuHeight < window?.innerHeight) {
      showingMenu.style.top = `${y}px`;
    } else
      showingMenu.style.top = `${
        y - (menuHeight - (window.innerHeight - y) + 2)
      }px`;

    // Show the showingMenu
    showingMenu.classList.remove("container__menu_hidden");
    //handleMenuShowing(e)

    //showHideMenu.addEventListener('click',handleMenuShowing)
    setPositionXY(newPos);
  };

  //=====================================================
  //onclick menu showing and event handling end
  //=======================================================

  //=====================================================
  //tagging on image start
  //=======================================================

  const handleClick = (e) => {
    const appendRefN = canvRef.current;
    var canv = document.createElement("canvas");
    canv.style.position = "absolute";
    canv.style.left = positionXY.x * widthRatio + "px";
    canv.style.top = positionXY.y * heightRatio + "px";

    appendRefN.appendChild(canv);
    createTag();
  };
  const createTag = (e) => {
    const appendTag = canvRef.current;

    var tagLine = document.createElement("div");

    tagLine.style.display = "none";
    tagLine.style.width = "60px";
    tagLine.style.background = "blue";
    tagLine.style.height = "3px";
    tagLine.style.position = "absolute";
    tagLine.style.left = positionXY.x * widthRatio + "px";
    tagLine.style.top = positionXY.y * heightRatio + "px";
    tagLine.className = "hidden-tag";

    appendTag.appendChild(tagLine);
  };

  //=====================================================
  //tagging on image end
  //=======================================================

  //=====================================================
  //right click hide start
  //=======================================================

  const handleMenuShowing = (e) => {
    const showingMenu = rightMenu.current;
    const isClicked = !mainSectionRef.current.contains(e.target);
    if (isClicked || e.which === 27) {
      showingMenu.classList.add("container__menu_hidden");
    }
  };

  const getFieldValue = (e, menuId) => {
    const showingMenu = rightMenu.current;

    //***saving tagged values to state
    let name =
      menuOptions[menuId - 1].dbName === "NAMES"
        ? "GIVENNAME"
        : menuOptions[menuId - 1].dbName === "FATHER_NAMES"
        ? "FATHER_GIVENNAME"
        : menuOptions[menuId - 1].dbName === "MOTHER_NAMES"
        ? "MOTHER_GIVENNAME"
        : menuOptions[menuId - 1].dbName === "SPOUSE_NAMES"
        ? "SPOUSE_GIVENNAME"
        : menuOptions[menuId - 1].dbName === "SPOUSE_FATHER_NAMES"
        ? "SPOUSE_FATHER_GIVENNAME"
        : menuOptions[menuId - 1].dbName === "SPOUSE_MOTHER_NAMES"
        ? "SPOUSE_MOTHER_GIVENNAME"
        : menuOptions[menuId - 1].dbName;
    // let positionx = parseInt(positionXY.x);
    // let positiony = parseInt(positionXY.y);
    let positionx = positionXY.x;
    let positiony = positionXY.y;
    let imageName = images[currentIndex]?.IMAGE_NAME;
    let imageId = images[currentIndex]?.IMAGE_NUMBER;
    let seriseNumber = selectedSeries;

    setRecords([
      ...records,
      {
        fieldName: name,
        coordinateX: positionx,
        coordinateY: positiony,
        IMAGE_NAME: imageName,
        imageId: imageId,
        line: lineNo + 1,
        SERIES_NUMBER: seriseNumber,
        code: imgCode,
        IMAGE_TYPE: imgType.status,
        EVENT_TYPE: imgEvent,
        IMAGE_WIDTH: imageHW?.width,
        IMAGE_HEIGHT: imageHW?.height,
      },
    ]);

    //***disabling menu
    setMenuOptions((prevState) => {
      const newMenuOptions = [...prevState];
      newMenuOptions[menuId - 1].disabled = true;
      newMenuOptions[menuId - 1].taggedTime = new Date().toLocaleString();
      return newMenuOptions;
    });

    handleClick(e);
    showingMenu.classList.add("container__menu_hidden");
  };

  //=====================================================
  //right button click menu end
  //=======================================================

  //=====================================================
  //Save Tage Data to Database Start
  //=======================================================

  const handleSave = () => {
    localStorage.setItem("taggedData", JSON.stringify(records));
    setImgComment((prevSate) => {
      return prevSate + "Tag";
    });
    setIsChecked(false);
    setIsDisabled(false);

    var localStorageValues = JSON.parse(localStorage.getItem("taggedData"));

    // Insert tagged data if localstorage tagged value is available.
    if (localStorageValues) {
      dispatch(imageDataInsert(localStorageValues));
      localStorage.removeItem("taggedData");

      // Sets the data fields secuence for the selected data type.
      if (fieldSequenceState.length > 0) {
        dispatch(setFieldSequence(fieldSequenceState));
        setFieldSequenceState([]);
      }

      setRecords([]);
      // handleBBD(images[currentIndex]);
      setMenuOptions((menuOptions) => resetFieldTagMenu(menuOptions));
      removeElements(document.querySelectorAll(".hidden-tag"));
      removeElements(document.querySelectorAll("canvas"));
      goToNextSlide();
    }
    if (!hasNext) {
      history.replace("/series");
    }
  };
  //=====================================================
  //Save Tage Data to Database End
  //=======================================================

  //=====================================================
  //remove last tag and All Tags start
  //=======================================================

  const clearTag = (e) => {
    e.preventDefault();
    var selectLastTag = document.querySelectorAll("canvas");

    var blueLastTag = document.querySelectorAll(".hidden-tag");
    if (e.type === "contextmenu") {
      if (selectLastTag.length !== 0) {
        const lastTagedId = menuOptions
          .slice()
          .reverse()
          .find((menu) => menu.disabled === true);

        const lastTagedTime = menuOptions
          .map((tag) => tag.taggedTime)
          .sort()
          .reverse()[0];
        const lastTagedObj = menuOptions.find(
          (tt) => tt.taggedTime === lastTagedTime
        );

        setMenuOptions((prevState) => {
          const prevMenuOptions = [...prevState];
          prevMenuOptions[lastTagedObj !== 0 && lastTagedObj.id - 1].disabled =
            null;
          prevMenuOptions[
            lastTagedObj !== 0 && lastTagedObj.id - 1
          ].taggedTime = "";
          return prevMenuOptions;
        });
        selectLastTag[selectLastTag.length - 1].remove();
        records.pop();
      }
      if (blueLastTag.length !== 0) {
        blueLastTag[blueLastTag.length - 1].remove();
      }
    } else {
      if (blueLastTag.length > 0) {
        for (let i = 0; i < blueLastTag.length; i++) {
          blueLastTag[i].remove();
        }
      }
      if (selectLastTag.length > 0) {
        for (let i = 0; i < selectLastTag.length; i++) {
          selectLastTag[i].remove();
        }
      }
      setRecords([]);
    }
  };
  //=====================================================
  //remove last tag and All Tag end
  //=======================================================

  //=======================================================
  //Clear Tag For Multiple Type Selections Start
  //=====================================================
  const clearForMultipleTag = (e) => {
    e.preventDefault();
    var newRecords = records;
    var selectLastTag = document.querySelectorAll("canvas");
    var blueLastTag = document.querySelectorAll(".hidden-tag");

    if (blueLastTag.length > 0) {
      for (
        let i = blueLastTag.length - selectLastTag.length;
        i < blueLastTag.length;
        i++
      ) {
        blueLastTag[i].remove();
      }
    }
    if (selectLastTag.length > 0) {
      for (let i = 0; i < selectLastTag.length; i++) {
        selectLastTag[i].remove();
      }
    }
    newRecords.splice(
      newRecords.length - selectLastTag.length,
      selectLastTag.length
    );
    setRecords(newRecords);
  };
  const clearForDataTypeChange = (e) => {
    e.preventDefault();
    var selectLastTag = document.querySelectorAll("canvas");
    var blueLastTag = document.querySelectorAll(".hidden-tag");

    if (blueLastTag.length > 0) {
      for (let i = 0; i < blueLastTag.length; i++) {
        blueLastTag[i].remove();
      }
    }
    if (selectLastTag.length > 0) {
      for (let i = 0; i < selectLastTag.length; i++) {
        selectLastTag[i].remove();
      }
    }
    setRecords([]);
    setLineNo(0);
  };

  //=====================================================
  //Clear Tag For Multiple Type Selections End
  //=======================================================
  //=====================================================
  //save data to localstorage and retrieve start
  //=======================================================

  const handleAddRecord = () => {
    setLineNo(lineNo + 1);
    localStorage.setItem("taggedData", JSON.stringify(records));
    setMenuOptions((menuOptions) => resetFieldTagMenu(menuOptions));
    removeElements(document.querySelectorAll("canvas"));
    document.querySelectorAll(".hidden-tag").forEach((el) => {
      el.style.display = "block";
    });
  };

  // //get tagged data from local storage

  useEffect(() => {
    var localStorageValues = JSON.parse(localStorage.getItem("taggedData"));
    if (localStorageValues) {
      setLocalData([...localStorageValues]);
    }
  }, []);

  // remove data from localstorage every minute
  useEffect(() => {
    const interval = setInterval(() => {
      localStorage.removeItem("taggedData");
    }, 60 * 1000 * 60);
    return () => clearInterval(interval);
  });

  //=====================================================
  //save data to localstorage  and retrieve end
  //=======================================================

  //=====================================================
  //image rotation  start
  //=======================================================

  var current_rotation = 0;
  const handleRotation = () => {
    const imgRot = imgRef.current;

    current_rotation += 90;

    // rotate clockwise by 90 degrees

    imgRot.style.transform = "rotate(" + current_rotation + "deg)";
  };

  //=====================================================
  //image rotation  end
  //=======================================================

  //=====================================================
  //keyboard key press for tagging   start
  //=======================================================

  const handleKeyPress = (e) => {
    var xkey = e.which;
    menuOptions.map((menu) => {
      if (menu.disabled !== true && xkey === menu.keyCode) {
        return getFieldValue(e, menu.id);
      }
      return true;
    });
    if (xkey === ESCAPE_KEY_CODE) {
      handleMenuShowing(e);
    }
  };

  //=====================================================
  //keyboard key press for tagging   end
  //=======================================================

  //=====================================================
  //Handle First Record Connection   Start
  //=======================================================

  const handleFirstRecordConnection = (e) => {
    if (records.length) {
      setRecords([]);
      // setMenuOptions(baptismMenuState);
      removeElements(document.querySelectorAll("canvas"));
      removeElements(document.querySelectorAll(".hidden-tag"));
      dispatch(imageDataInsertForFRC({ data: records, link: "FieldTag" }));
      setIsChecked(true);
      setIsDisabled(true);
    } else {
      toast.warn("Tag the Image First");
    }
  };

  //=====================================================
  //Handle First Record Connection   end
  //=======================================================

  return (
    <div>
      <FullScreen></FullScreen>
      <Sidebar></Sidebar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        status={imgComment}
      >
        <DialogTitle id="alert-dialog-title">
          Current image has been marked as
          <span style={{ textTransform: "uppercase" }}> {imgComment}</span>{" "}
          image
        </DialogTitle>
        <DialogContent></DialogContent>

        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() => handleBBD(images[currentIndex])}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {menuHeight !== null && (
        <Box
          sx={{
            width: "100%",
            maxWidth: 250,
            bgcolor: "background.paper",
          }}
          ref={rightMenu}
          className="container__menu container__menu_hidden"
        >
          <List>
            {menuOptions?.map((option) => {
              return (
                <ListItem key={option.id} disablePadding>
                  <ListItemButton
                    component="a"
                    onClick={(e) =>
                      positionXY.x !== Infinity && getFieldValue(e, option.id)
                    }
                    disabled={option.disabled}
                    ref={keyevent}
                    sx={{ fontSize: "12.5px", paddingY: "2px" }}
                  >
                    <ListItemText
                      secondary={option?.name}
                      sx={{ margin: 0, fontSize: "10px" }}
                    />
                    <span>{option?.keyName}</span>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}

      <Box
        sx={{ flexGrow: 1, height: "70vh", overflow: "scroll" }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                className="zoom"
                onMouseLeave={() => disableScroll.off()}
                onMouseEnter={() => disableScroll.on()}
                onMouseOver={() => disableScroll.on()}
                onWheel={(e) => Zooming(e, setScale, canvRef)}
              >
                {images.length > 0 && (
                  <img
                    style={{ position: "relative" }}
                    className="imageContainer"
                    tabIndex="-1"
                    onKeyDown={(e) => handleKeyPress(e)}
                    ref={imgRef}
                    src={`${imageBaseUrl}${selectedSeries}/${images[currentIndex]?.IMAGE_NAME}`}
                    onContextMenu={(e) => clearTag(e)}
                    onClick={(e) =>
                      imgCode === 0
                        ? notifyWarn("Select a Data Type First.")
                        : handleMenu(e)
                    }
                    alt="img"
                    id="imagesss"
                  />
                )}
              </div>

              {/* must use tabIndex  otherwise keyboard event wont work */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Divider /> */}

      <Box
        sx={{ flexGrow: 1, height: "20vh", px: 3 }}
        onClick={(e) => handleMenuShowing(e)}
      >
        <Grid
          container
          item
          lg={12}
          spacing={2}
          py={0.4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item sx={{ height: "45px", paddingTop: "6px!important" }}>
            <Typography
              variant="p"
              color="error"
              fontSize={10}
              sx={{ fontWeight: "bold" }}
            >
              Showing : ({currentIndex + 1} of {imagesData?.length}) | Series:{" "}
              {selectedSeries} | Image : {images[currentIndex]?.IMAGE_NAME} |
              Tag Count: {lineNo}
            </Typography>
            <Stack direction="row" spacing={2}>
              {menuOptions &&
                menuOptions
                  .filter((menuOptions) => menuOptions.id < 20)
                  ?.map((singleParam, index) => {
                    return (
                      <Typography
                        key={index}
                        sx={{
                          color: singleParam.disabled ? "#bbb" : "#000",
                          fontSize: "12px",
                        }}
                      >
                        {singleParam.name}
                      </Typography>
                    );
                  })}
            </Stack>
          </Grid>

          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ fontSize: "12px" }}
                disabled={!disabledAddRecord}
                onClick={handleAddRecord}
                color="success"
                variant="outlined"
                size="small"
              >
                Add Record
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} py={0.4}>
          <Grid item lg={6}>
            <FormControl component="fieldset" disabled={disabled}>
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
                color="primary"
              >
                Language
              </FormLabel>
              <RadioGroup
                row
                aria-label="language"
                name="row-radio-buttons-group"
                defaultValue={imgType.type}
                value={imgType.type}
                onChange={handleLanguage}
                sx={{ my: 0 }}
              >
                {Object.entries(languageCode).map(([key, value]) => (
                  <FormControlLabel
                    key={value}
                    sx={{ marginBottom: 0 }}
                    value={key}
                    control={<Radio size="small" />}
                    label={<span style={{ fontSize: "13px" }}>{key}</span>}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button
                sx={{ fontSize: "12px", my: 0.4 }}
                color="success"
                disabled={!disabled}
                variant="outlined"
                onClick={handleSave}
                size="small"
              >
                Save
              </Button>
            </Stack>
          </Grid>

          <Grid item lg={6}>
            <Stack direction="row">
              <FormGroup direction="column">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleFirstRecordConnection}
                      disabled={isDisabled}
                      size="small"
                    />
                  }
                  sx={{ my: 0, mx: 0 }}
                  label={
                    <span style={{ fontSize: "13px", mx: 0 }}>
                      First record connection
                    </span>
                  }
                />
              </FormGroup>
              <Button
                direction="column"
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="primary"
                variant="outlined"
                onClick={() => handleClickOpen("Continue")}
                size="small"
              >
                continue
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                startIcon={<RotateLeftIcon />}
                color="primary"
                onClick={handleRotation}
                variant="outlined"
                size="small"
              >
                Rotate
              </Button>
            </Stack>
            <Stack direction="row">
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="info"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Blank")}
                size="small"
              >
                Blank
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="error"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Bad")}
                size="small"
              >
                Bad
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="warning"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Duplicate")}
                size="small"
              >
                Duplicate
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="secondary"
                variant="outlined"
                onClick={() => handleClickOpen("OtherLanguage")}
                size="small"
              >
                Other Language
              </Button>
              <Button
                sx={{ mx: 1, my: 0.4, fontSize: "12px" }}
                color="primary"
                variant="outlined"
                onClick={() => handleClickOpen("NoExtDataImage")}
                size="small"
              >
                No Ext DataImage
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} spacing={2} py={0.4}>
          <Grid item lg={6}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
              >
                Data Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="types"
                name="row-radio-buttons-group"
                defaultValue=""
                onChange={handleDataType}
              >
                {Object.values(allFieldsObject)
                  .filter((field) => field.status === "active")
                  .map((field) => (
                    <FormControlLabel
                      key={field.value}
                      value={field.value}
                      control={<Radio size="small" />}
                      label={
                        <span style={{ fontSize: "13px" }}>{field.name}</span>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl
              component="fieldset"
              ref={multipleType}
              disabled={imgCode !== MIXED_TYPE_CODE}
            >
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "13px" }}
              >
                Multiple Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="types"
                name="row-radio-buttons-group"
                value={imgCode === MIXED_TYPE_CODE && imgEvent}
                onChange={handleMultipleType}
              >
                {Object.values(allFieldsObject)
                  .filter(
                    (field) =>
                      field.status === "active" && field.name !== "Mixed"
                  )
                  .map((field) => (
                    <FormControlLabel
                      key={field.value}
                      value={field.value}
                      control={<Radio size="small" />}
                      label={
                        <span style={{ fontSize: "13px" }}>{field.name}</span>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
