import { notifySuccess } from "../../components/Helper/toasts";
import allCheckingService from "../Services/allCheckingService";
import {
  BLANK_UPDATE,
  GET_All_FIELDS,
  GET_FIELD_DATA,
  GET_FIELD_DATA_IMAGE,
  GET_FIELD_DATA_IMAGE_LINE,
  UPDATE_FIELD_VALUE,
} from "./types";

export const getAllFields = () => async (dispatch) => {
  try {
    const res = await allCheckingService.getAllFields();
    dispatch({
      type: GET_All_FIELDS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getFieldData = (seriesnumber, fieldname) => async (dispatch) => {
  if (fieldname === "") {
    dispatch({
      type: GET_FIELD_DATA,
      payload: [],
    });
  } else {
    try {
      const res = await allCheckingService.getFieldData(
        seriesnumber,
        fieldname
      );
      dispatch({
        type: GET_FIELD_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
};
export const getFieldDataImage =
  (seriesnumber, fieldname, value) => async (dispatch) => {
    if (fieldname === "" || value === "") {
      dispatch({
        type: GET_FIELD_DATA_IMAGE,
        payload: [],
      });
    } else {
      try {
        const res = await allCheckingService.getFieldDataImage(
          seriesnumber,
          fieldname,
          value
        );
        dispatch({
          type: GET_FIELD_DATA_IMAGE,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
export const getFieldDataImageLine =
  (imagenumber, fieldname, value) => async (dispatch) => {
    if (imagenumber === null || fieldname === "" || value === "") {
      dispatch({
        type: GET_FIELD_DATA_IMAGE_LINE,
        payload: [],
      });
    } else {
      try {
        const res = await allCheckingService.getFieldDataImageLine(
          imagenumber,
          fieldname,
          value
        );
        dispatch({
          type: GET_FIELD_DATA_IMAGE_LINE,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
export const updateFieldValue = (body) => async (dispatch) => {
  try {
    const res = await allCheckingService.updateFieldValue(body);
    dispatch({
      type: UPDATE_FIELD_VALUE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const blankUpdate = (body) => async (dispatch) => {
  try {
    const res = await allCheckingService.blankUpdate(body);
    dispatch({
      type: BLANK_UPDATE,
      payload: res.data,
    });
    notifySuccess("Blanks are updated successfully");
  } catch (err) {
    console.log(err);
  }
};
