import {
  GET_All_FIELDS,
  GET_FIELD_DATA,
  GET_FIELD_DATA_IMAGE,
  GET_FIELD_DATA_IMAGE_LINE,
  UPDATE_FIELD_VALUE,
} from "../Actions/types";

const initialState = {
  fields: [],
  fieldData: [],
  fieldDataImage: [],
  fieldDataImageLine: [],
};
function allCheckingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_All_FIELDS:
      return { ...state, fields: payload };
    case GET_FIELD_DATA:
      return { ...state, fieldData: payload };
    case GET_FIELD_DATA_IMAGE:
      return { ...state, fieldDataImage: payload };
    case GET_FIELD_DATA_IMAGE_LINE:
      return { ...state, fieldDataImageLine: payload };
    //As per requirement from taslim sir - No need to update state after updating value
    // case UPDATE_FIELD_VALUE:
    //   return {
    //     ...state,
    //     fieldData: payload,
    //     fieldDataImage: [],
    //     fieldDataImageLine: [],
    //   };

    default:
      return state;
  }
}

export default allCheckingReducer;
