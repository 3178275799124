import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function WarningPopup({
  dispatch,
  open,
  close,
  handleAction,
  selectedImage,
  action,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to mark this image as {action} image
        </DialogTitle>
        <DialogContent></DialogContent>

        <DialogActions>
          <Button color="error" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={(e) =>
              handleAction(dispatch, action, selectedImage, close)
            }
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
