import http from "../../utilities/http-common";

const getAllImages = async (seriesNumber) => {
  return await http.get(`/Check/bbd/images/${seriesNumber}`);
};
const changeImageStatus = async (imageNumber, comment, code) => {
  return await http.put(`/Check/bbd/update/${imageNumber}/${comment}/${code}`);
};
const retagImage = async (imageNumber) => {
  return await http.put(`/Check/bbd/retag/${imageNumber}/`);
};
const entryComplete = async (imageNumber) => {
  return await http.put(`/Check/bbd/entry_complete/${imageNumber}/`);
};
const bbdCheckingService = {
  getAllImages,
  changeImageStatus,
  retagImage,
  entryComplete,
};

export default bbdCheckingService;
