import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import { getEntryCountData } from "../../Redux/Actions/entryCountAction";
import { notifyWarn } from "../Helper/toasts";
const today = new Date().toISOString().substr(0, 10);
export default function RangeSelector() {
  const dispatch = useDispatch();
  const [dates, setDates] = useState({
    start: today,
    end: today,
  });
  const getData = (event) => {
    event.preventDefault();
    if (dates?.start !== "" && dates?.end !== "") {
      dispatch(getEntryCountData(dates?.start, dates?.end));
    } else notifyWarn("Select a specific duration");
  };
  useEffect(() => {
    const getDataEveryTime = () => {
      if (dates?.start !== "" && dates?.end !== "") {
        dispatch(getEntryCountData(dates?.start, dates?.end));
      } else notifyWarn("Select a specific duration");
    };
    getDataEveryTime();
  }, []);

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        height: "15vh",
        maxHeight: "15vh",
        position: "relative",
      }}
    >
      <Box
        component="form"
        className="d-flex align-items-center"
        onSubmit={(event) => getData(event)}
        style={{
          position: "absolute",
          bottom: 0,
        }}
        noValidate
      >
        <TextField
          margin="normal"
          color="info"
          type="date"
          label="From Date"
          className="m-1 "
          required
          onChange={(e) => {
            setDates({ ...dates, start: e.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={dates?.start}
          inputProps={{ max: today }}
        />
        <TextField
          margin="normal"
          color="error"
          type="date"
          label="To Date "
          className="m-1 "
          required
          inputProps={{ max: today }}
          onChange={(e) => {
            setDates({ ...dates, end: e.target.value });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={dates?.end}
        />
        <Button
          endIcon={<SearchIcon />}
          type="submit"
          className="m-1 px-1 py-3"
          variant="contained"
          // onClick={(e) => getData(e)}
        >
          Entry Count
        </Button>
      </Box>
    </Container>
  );
}
