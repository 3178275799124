import {
  GET_All_TAGGED_IMAGE,
  GET_SINGLE_TAGGED_IMAGE_DATA,
} from "../Actions/types";

const initialState = null;
function tagCheckingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_All_TAGGED_IMAGE:
      return { images: payload };
    case GET_SINGLE_TAGGED_IMAGE_DATA:
      return {
        ...state,
        details: payload,
      };

    default:
      return state;
  }
}

export default tagCheckingReducer;
