import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DataInputField from "./DataInputField";
import { useEffect, useRef, useState } from "react";
import { sorting, splitCordinate } from "../Helper/editHelper";
import FloatingMenu from "./FloatingMenu";
import SearchField from "../AlertBox/SearchField";
import Keyboard from "../Helper/Keyboard";

export default function DataEditTable({
  imagesData,
  selectedDirection,
  tableBody,
  tableHeader,
  selectedField,
  setSelectedImage,
  setSelectedImageNumber,
  setUnderlineCoordinates,
  setSelectedField,
  setSelectedImageLine,
  setDialogOpen,
  setImgComment,
  doubleClickFocusData,
  tableHeight,
}) {
  const tableRef = useRef(null);
  const rightMenu = useRef(null);
  const mainSectionRef = useRef(null);

  const [openSearchField, setOpenSearchField] = useState(false);
  const [menuHeight, setMenuHeight] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [searchTarget, setSearchTarget] = useState(null);
  const [type, setType] = useState(null);
  const [screenKeyboard, setScreenKeyboard] = useState(null);
  const [screenKeyboardOpen, setScreenKeyboardOpen] = useState(false);

  const [sortedTableBody, setSortedTableBody] = useState(tableBody);
  useEffect(() => {
    setSortedTableBody(tableBody);
  }, [tableBody.length]);

  useEffect(() => {
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.which === 27) {
          closePopupMenu(false);
        }
      });
    }
  }, []);
  const handleContextMenu = (e) => {
    e.preventDefault();

    setMenuHeight(rightMenu.current.clientHeight);
    const showingMenu = rightMenu.current;
    const mainSec = mainSectionRef.current;

    const rectMenu = mainSec?.getBoundingClientRect();

    const x = e.clientX;
    const y = e.clientY;

    // Set the position for menu
    if (x + 250 < rectMenu.width) {
      showingMenu.style.left = `${x}px`;
    } else showingMenu.style.left = `${x - 250}px`;
    if (y + menuHeight < window?.innerHeight) {
      showingMenu.style.top = `${y}px`;
    } else
      showingMenu.style.top = `${
        y - (menuHeight - (window.innerHeight - y) + 2)
      }px`;

    showingMenu.classList.remove("container__menu_hidden");
  };
  useEffect(() => {
    if (selectedSort === null) {
      return;
    } else if (selectedSort === "Clear Sorting") {
      setSortedTableBody(tableBody);
    } else setSortedTableBody(sorting(tableBody, selectedSort, selectedField));
  }, [selectedSort]);

  useEffect(() => {
    setSelectedSort(null);
  }, [selectedField]);

  const closePopupMenu = () => {
    const showingMenu = rightMenu?.current;
    showingMenu.classList.add("container__menu_hidden");
  };
  const handleCloseSearchField = () => {
    setOpenSearchField(false);
  };

  return (
    <>
      <SearchField
        selectedField={selectedField}
        open={openSearchField}
        onClose={handleCloseSearchField}
        type={type}
        setType={setType}
        setSearchTarget={setSearchTarget}
      ></SearchField>
      <FloatingMenu
        rightMenu={rightMenu}
        setSelectedSort={setSelectedSort}
        selectedSort={selectedSort}
        closePopupMenu={closePopupMenu}
      />
      {screenKeyboardOpen && <Keyboard screenKeyboard={screenKeyboard} />}
      <Box
        sx={{
          flexGrow: 1,
          height: tableHeight,
          overflow: "scroll",
          background: "#FFFFE6",
        }}
        ref={mainSectionRef}
        onClick={() => closePopupMenu()}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.length > 0 &&
                tableHeader.map(
                  (image, index) =>
                    index > 3 &&
                    index !== 5 && (
                      <TableCell
                        key={index}
                        sx={{
                          border: "2px solid #b1b1b1",
                          background: "#d3d3d3",
                          fontSize: "12px",
                          padding: "0px 4px",
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                        size="small"
                      >
                        {image}
                      </TableCell>
                    )
                )}
            </TableRow>
          </TableHead>
          <TableBody ref={tableRef}>
            {sortedTableBody?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {tableHeader?.map((image, index) => {
                  return (
                    index > 3 &&
                    index !== 5 && (
                      <TableCell
                        key={index}
                        sx={{
                          border: "2px solid #b1b1b1!important",
                          padding: "0px",
                          borderBottom: "0px",
                        }}
                        size="small"
                      >
                        <DataInputField
                          type={type}
                          searchTarget={searchTarget}
                          setSearchTarget={setSearchTarget}
                          row={row}
                          image={image}
                          setSelectedImage={setSelectedImage}
                          setSelectedImageNumber={setSelectedImageNumber}
                          setUnderlineCoordinates={setUnderlineCoordinates}
                          setSelectedField={setSelectedField}
                          tableRef={tableRef}
                          selectedDirection={selectedDirection}
                          setSelectedImageLine={setSelectedImageLine}
                          handleContextMenu={handleContextMenu}
                          setDialogOpen={setDialogOpen}
                          setImgComment={setImgComment}
                          doubleClickFocusData={doubleClickFocusData}
                          setOpenSearchField={setOpenSearchField}
                          setScreenKeyboardOpen={setScreenKeyboardOpen}
                          setScreenKeyboard={setScreenKeyboard}
                          screenKeyboard={screenKeyboard}
                        />
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
