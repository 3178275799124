import { GET_CONTINUE_IMAGE_DATA } from "../Actions/types";

const initialState = 0;

function continueImageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTINUE_IMAGE_DATA:
      return payload;

    default:
      return state;
  }
}

export default continueImageReducer;
