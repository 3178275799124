import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getImageData,
  getImageDataForEntry,
  getImageSeries,
  setImageEmptyTagging,
} from "../../Redux/Actions/imageTaggingAction";
import { getImageData as getImageEditData } from "../../Redux/Actions/dataEditAction";
import queryString from "query-string";
import { setImageEmptyCoordinates } from "../../Redux/Actions/imageEntryAction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

function ImageSeries(props) {
  const token = JSON.parse(sessionStorage.getItem("user"));
  let history = useHistory();
  const dispatch = useDispatch();
  const imageSeries = useSelector((state) => state.imageSeriesReducer);
  const params = queryString.parse(history.location.search);
  const { onClose, currentSeries, type, open, ...other } = props;
  const [checked, setChecked] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(currentSeries);
  const [series, setSeries] = useState([]);
  const [disabled, setDisabled] = useState(true);

  // useEffect(() => {
  //   dispatch(getImageSeries());
  // }, []);

  // useEffect(() => {
  //   dispatch(setImageEmptyCoordinates());
  // }, []);

  useEffect(() => {
    setSeries(imageSeries);
  }, [imageSeries]);

  const getData = (selectedSeries) => {
    type === "entry"
      ? selectedSeries && dispatch(getImageDataForEntry(selectedSeries))
      : type === "edit"
      ? selectedSeries && dispatch(getImageEditData(selectedSeries))
      : selectedSeries && dispatch(getImageData(selectedSeries));
  };
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    if (checked != null) {
      dispatch(setImageEmptyTagging());
      // getData(selectedSeries);
      history.push({
        pathname: `/${type}`,
        state: selectedSeries,
      });

      sessionStorage.setItem("selectedSeries", JSON.stringify(selectedSeries));
      onClose();
    } else {
      window.alert("Please select a series first.");
    }
  };

  const handleChange = (images, i) => {
    setSelectedSeries(images);
    setDisabled(false);
    setChecked(i);
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
      onClose={onClose}
    >
      <DialogTitle>Select An Image Series</DialogTitle>
      <DialogContent dividers>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{}}>
              <TableCell
                sx={{
                  background: "#d3d3d3",
                  padding: "0px",
                  fontSize: "12px",
                  position: "sticky",
                }}
                size="small"
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  background: "#d3d3d3",
                  padding: "0px",
                  fontSize: "12px",
                  position: "sticky",
                  top: 0,
                }}
                size="small"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              overflow: "scroll",
            }}
          >
            {imageSeries.length > 0 &&
              imageSeries.map((image, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: "1px solid #dee2e6",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "0px 5px",
                        cursor: "pointer",
                      }}
                      size="small"
                    >
                      <input
                        id={image.SERIES_NUMBER}
                        type="radio"
                        value={image.SERIES_NUMBER}
                        onChange={() =>
                          handleChange(image.SERIES_NUMBER, index)
                        }
                        checked={
                          selectedSeries == image?.SERIES_NUMBER ? true : false
                        }
                      ></input>
                      <label htmlFor={image.SERIES_NUMBER} className="p-1">
                        {image?.SERIES_NUMBER}
                      </label>
                    </TableCell>
                    <TableCell
                      key={index}
                      sx={{
                        padding: "0px 5px",
                        cursor: "pointer",
                      }}
                      size="small"
                    >
                      {image?.STATUS}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button> */}
        <Button disabled={disabled} onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ImageSeries;
// export default function ImageSeries() {
//   const token = JSON.parse(localStorage.getItem("user"));

//   const dispatch = useDispatch();
//   const [open, setOpen] = useState(false);

//   const handleClose = (newValue) => {
//     setOpen(false);
//   };
//   const handleClickListItem = () => {
//     setOpen(true);
//   };

//   return (
//     <Box
//       sx={{
//         width: "400px",
//         maxWidth: 860,
//         maxHeight: 800,
//         bgcolor: "#dee2e6",
//         position: "relative",
//         borderRadius: "10px",
//         top: "200px",
//         left: "555px",
//         padding: "10px",
//       }}
//     >
//       <List component="div">
//         <ListItem
//           button
//           divider
//           aria-haspopup="true"
//           aria-controls="image_series"
//           aria-label="phone ringtone"
//           onClick={handleClickListItem}
//         >
//           <ListItemText primary="Click to select Image Series" />
//         </ListItem>

//         <ConfirmationDialogRaw
//           id="image_series"
//           keepMounted
//           open={open}
//           onClose={handleClose}
//         />
//       </List>
//     </Box>
//   );
// }
