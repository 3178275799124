import {
  GET_FIELD_SEQUENCE,
  SET_FIELD_SEQUENCE,
  UPDATE_FIELD_SEQUENCE,
} from "../Actions/types";

const initialState = null;

function fieldSequenceReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FIELD_SEQUENCE:
      return payload;

    case SET_FIELD_SEQUENCE:
      return payload;

    case UPDATE_FIELD_SEQUENCE:
      return payload;

    default:
      return state;
  }
}

export default fieldSequenceReducer;
