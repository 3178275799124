import { Box, Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import RotateLeftIcon from "@mui/icons-material/RotateRight";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import CollectionsIcon from "@mui/icons-material/Collections";
import CurtainsIcon from "@mui/icons-material/Curtains";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import "../DataEdit/DataEdit.css";
import TopFooter from "./TopFooter";
import BottomFooter from "./BottomFooter";
import BottomFooterTableOne from "./BottomFooterTableOne";
import BottomFooterTableTwo from "./BottomFooterTableTwo";
import ValueUpadte from "./ValueUpdate";
import BottomFooterTableThree from "./BottomFooterTableThree";
import { useEffect, useState } from "react";
import {
  getFieldData,
  updateFieldValue,
} from "../../Redux/Actions/allCheckingAction";
import { useDispatch } from "react-redux";
import { handleRotation } from "../Helper/commonHelper";
import WarningPopup from "../CommonUI/WarningPopup";
import { handleAction } from "../Helper/library";
import { notifyWarn } from "../Helper/toasts";

export default function Footer({
  imgRef,
  imagesData,
  radioButtonList,
  selectedSeries,
  setSelectedImageNumber,
  setSelectedImage,
  selectedImageNumber,
  setUnderlineCoordinates,
}) {
  const dispatch = useDispatch();
  const [selectedField, setSelectedField] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageComment, setImageComment] = useState("");
  const [selectedFieldValue, setSelectedFieldValue] = useState("");
  const [updatedValue, setUpdatedValue] = useState(selectedFieldValue);
  const [selectedLine, setSelectedLine] = useState(null);
  const [errorType, setErrorType] = useState(null);

  const [fromImage, setFromImage] = useState(null);
  const [toImage, setToImage] = useState(null);

  useEffect(() => {
    setErrorType(null);
    dispatch(getFieldData(selectedSeries, selectedField));
    setSelectedFieldValue("");
  }, [selectedField]);

  useEffect(() => {
    setSelectedImageNumber(null);
    setSelectedImage(null);
  }, [selectedFieldValue]);
  useEffect(() => {
    setSelectedLine(null);
  }, [selectedImageNumber]);

  const handleUpdate = (
    seriesNumber,
    fromImageNumber,
    toImageNumber,
    line,
    fieldName,
    prevValue,
    nextValue
  ) => {
    let body = {
      seriesnumber: seriesNumber,
      fieldname: fieldName,
      prev_value: prevValue,
      next_value: nextValue,
    };
    if (fromImageNumber !== null) {
      body = {
        ...body,
        fromimagenumber: fromImageNumber,
        toimagenumber: toImageNumber,
      };
    }
    if (line !== null) {
      body = {
        ...body,
        line: line,
      };
    }
    body && dispatch(updateFieldValue(body));
    setSelectedLine(null);
    setSelectedImageNumber(null);
    setSelectedImage(null);
    setSelectedFieldValue("");
  };
  const handlePopupClose = () => {
    setDialogOpen(false);
  };
  const handleBlankUpdate = (action, field) => {
    if (field === "") {
      notifyWarn("Select a Field First.");
    } else {
      setDialogOpen(true);
      setImageComment(action);
    }
  };

  return (
    <>
      {dialogOpen && (
        <WarningPopup
          dispatch={dispatch}
          open={dialogOpen}
          close={handlePopupClose}
          handleAction={handleAction}
          action={imageComment}
          selectedImage={{
            seriesnumber: selectedSeries,
            fieldname: selectedField,
          }}
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          paddingTop: "5px",
          background: "#d9ebff",
          position: "fixed",
          bottom: "0px",
          width: "100%",
          height: "48vh",
          maxHeight: "48vh",
          overflowY: "scroll",
        }}
      >
        <TopFooter
          radioButtonList={radioButtonList}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
        />
        <div className="d-flex">
          <BottomFooterTableOne
            selectedFieldValue={selectedFieldValue}
            setSelectedFieldValue={setSelectedFieldValue}
            selectedField={selectedField}
            selectedSeries={selectedSeries}
            errorType={errorType}
          />
          <BottomFooterTableTwo
            imagesData={imagesData}
            setFromImage={setFromImage}
            setToImage={setToImage}
            selectedField={selectedField}
            selectedFieldValue={selectedFieldValue}
            setSelectedImageNumber={setSelectedImageNumber}
            setSelectedImage={setSelectedImage}
            selectedImageNumber={selectedImageNumber}
            setUnderlineCoordinates={setUnderlineCoordinates}
          />
          <BottomFooterTableThree
            setSelectedLine={setSelectedLine}
            selectedLine={selectedLine}
          />
          <div className="col-3 d-flex flex-column">
            <BottomFooter fromImage={fromImage} toImage={toImage} />
            <ValueUpadte
              selectedFieldValue={selectedFieldValue}
              setUpdatedValue={setUpdatedValue}
            />
          </div>
          {/* <Grid
          container
          item
          direction="row"
          lg={12}
          spacing={2}
          my={0}
          py={0.4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0px",
            width: "100%",
            paddingTop: 0,
          }}
        >
          <Stack
            spacing={1}
            sx={{
              display: "flex",
              alignContent: "flex-start",
            }}
            className="col-4"
          >
            <Typography
              variant="p"
              color="error"
              fontSize={10}
              sx={{
                fontWeight: "bold",
                background: "white",
                borderRadius: "5px",
                lineHeight: "12px",
              }}
            >
              ID : {imagesData[0]?.OPERATOR_ID} | Entry : {imagesData?.length}{" "}
              Total Record : {record?.totalRecord} | Series : {selectedImage} |
              Image : {selectedImage}
            </Typography>
          </Stack>
        </Grid> */}
          <Grid
            container
            item
            lg={12}
            py={0.4}
            sx={{ justifyContent: "space-between", py: 0 }}
          >
            <Grid item className="col-8">
              <Stack className="d-inline-block" spacing={0.4} py={0.4}>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <FmdBadIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    handleUpdate(
                      selectedSeries,
                      fromImage,
                      toImage,
                      selectedLine,
                      selectedField,
                      selectedFieldValue,
                      updatedValue
                    );
                  }}
                  variant="outlined"
                  size="small"
                >
                  Update
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <RotateLeftIcon
                      sx={{ marginLeft: "-5px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => handleRotation(imgRef)}
                  variant="outlined"
                  size="small"
                >
                  Rotate
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <AutorenewIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    setSelectedField("");
                    setSelectedFieldValue("");
                    setSelectedImageNumber(null);
                    setSelectedImage(null);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Refresh
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <FileCopyIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    setErrorType("ERROR_DATA");
                  }}
                  variant="outlined"
                  size="small"
                >
                  Error Data
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <CollectionsIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    setErrorType("DATE_FORMAT_ERROR");
                  }}
                  variant="outlined"
                  size="small"
                >
                  Date Format Error
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <CurtainsIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    handleBlankUpdate("BLANK_UPDATE", selectedField);
                  }}
                  variant="outlined"
                  size="small"
                >
                  Blank Upadate
                </Button>
                <Button
                  sx={{ fontSize: "10px", marginRight: "4px !important" }}
                  startIcon={
                    <AccountTreeIcon
                      sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                    />
                  }
                  color="primary"
                  onClick={() => {
                    setErrorType("CASE_ERROR");
                  }}
                  variant="outlined"
                  size="small"
                >
                  Case Error
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
