import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./EntryCount.css";
import RangeSelector from "./RangeSelector";
import EntryCountTable from "./EntryCountTable";
import { useSelector } from "react-redux";
import { handleTableHeader } from "../Helper/editHelper";
export default function EntryCount() {
  const imagesData = useSelector((state) => state?.entryCountReducer);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [tableFooter, setTableFooter] = useState([]);
  const columnSums = tableHeader?.map((column) =>
    tableBody
      .map((row) => row[column])
      .reduce((sum, value) => {
        if (column.includes("SERIES")) {
          return "Total";
        } else if (column.includes("_DATE")) {
          return "----------";
        } else return sum + value;
      }, 0)
  );
  useEffect(() => {
    document.title = "Entry Count | Data Keying";
  }, []);
  useEffect(() => {
    setTableBody(imagesData);
    setTableHeader(handleTableHeader(imagesData));
  }, [imagesData]);

  useEffect(() => {
    setTableFooter(columnSums);
  }, [tableHeader]);

  return (
    <div>
      <div>
        <Sidebar></Sidebar>
      </div>
      <div
        style={{
          background: "#d5eaff",
        }}
      >
        <RangeSelector />
        <EntryCountTable
          imagesData={imagesData}
          tableBody={tableBody}
          tableHeader={tableHeader}
          tableFooter={tableFooter}
        />
      </div>
    </div>
  );
}
