import { Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { useEffect, useRef } from "react";

export default function BBDCheckingTable({
  imagesData,
  setSelectedImage,
  selectedImage,
  selectedImageIndex,
}) {
  const mainSectionRef = useRef(null);

  useEffect(() => {
    if (selectedImageIndex !== null) {
      const element = document.getElementById(selectedImageIndex);

      element !== null &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
    }
  }, [selectedImageIndex]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          // height: "79vh",
          // maxHeight: "79vh",
          // overflow: "scroll",
          background: "#FFFFE6",
          padding: "0px",
          marginTop: "7vh",
        }}
        className="col-2"
        ref={mainSectionRef}
      >
        <Table aria-label="simple table">
          <TableHead sx={{ position: "sticky", top: 0 }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "2px solid #b1b1b1",
                  borderTop: "0px solid",
                  borderBottom: "0px solid",
                  background: "#b1b1b1",
                  fontSize: "12px",
                  padding: "4px",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                }}
                size="small"
              >
                Image Number ({imagesData?.length})
              </TableCell>
            </TableRow>
          </TableHead>
          <div
            style={{
              overflowY: "scroll",
              height: "74vh",
              maxHeight: "74vh",
            }}
          >
            <TableBody>
              {imagesData?.map((row, index) => (
                <TableRow
                  key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  sx={{
                    padding: "0px",
                    margin: "0px",
                    background:
                      selectedImage?.IMAGE_NUMBER === row["IMAGE_NUMBER"]
                        ? "#5ca2ad"
                        : "",
                  }}
                  id={index}
                >
                  <TableCell
                    className="col-2"
                    sx={{
                      border: "2px solid #b1b1b1!important",
                      padding: "0px",
                      borderBottom: "0px",
                      width: "100%",
                    }}
                    size="small"
                  >
                    <Typography
                      type="text"
                      sx={{
                        padding: "2px 4px",
                        border: "none",
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        wordBreak: "break-all",
                        cursor: "pointer",
                      }}
                      readOnly
                      onClick={() => {
                        setSelectedImage(row);
                        // setSelectedImageIndex(index);
                      }}
                    >
                      {row["IMAGE_NUMBER"]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </div>
        </Table>
      </Box>
    </>
  );
}
