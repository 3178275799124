import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";

const ctrl = [
  {
    id: 1,
    value: "à",
  },
  {
    id: 2,
    value: "á",
  },
  {
    id: 3,
    value: "ä",
  },
  {
    id: 4,
    value: "â",
  },
  {
    id: 5,
    value: "ç",
  },
  {
    id: 6,
    value: "é",
  },
  {
    id: 7,
    value: "è",
  },
  {
    id: 8,
    value: "ê",
  },
  {
    id: 9,
    value: "ë",
  },
  {
    id: 0,
    value: "ñ",
  },
];
const shift = [
  {
    id: 1,
    value: "î",
  },
  {
    id: 2,
    value: "ï",
  },
  {
    id: 3,
    value: "ò",
  },
  {
    id: 4,
    value: "ó",
  },
  {
    id: 5,
    value: "ö",
  },
  {
    id: 6,
    value: "ô",
  },
  {
    id: 7,
    value: "û",
  },
  {
    id: 8,
    value: "ù",
  },
  {
    id: 9,
    value: "ü",
  },
  {
    id: 0,
    value: "ÿ",
  },
];
export default function Keyboard({ screenKeyboard }) {
  const [ctrlBackgroud, setCtrlBackgroud] = useState("#d3d3d3");
  const [shiftBackgroud, setShiftBackgroud] = useState("#d3d3d3");
  useEffect(() => {
    if (screenKeyboard !== null) {
      if (screenKeyboard === 16) {
        setCtrlBackgroud("#d3d3d3");
        setShiftBackgroud("#fd7e14");
      }
      if (screenKeyboard === 17) {
        setCtrlBackgroud("#fd7e14");
        setShiftBackgroud("#d3d3d3");
      }
    } else {
      setShiftBackgroud("#d3d3d3");
      setCtrlBackgroud("#d3d3d3");
    }
  }, [screenKeyboard]);
  return (
    <>
      <Draggable>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            marginTop: "-71px",
            flexWrap: "nowrap",
            top: "-15px",
            float: "right",
            right: "16px",
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              border: "2px solid #979aa8",
              background: "#FFFFE6",
              flexWrap: "wrap",
              marginRight: "2px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: ctrlBackgroud,
                    padding: "0px",
                    fontSize: "12px",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  ctrl +
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: "scroll",
              }}
            >
              <TableRow
                className="d-flex"
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {ctrl?.map((item, index) => {
                  return (
                    <TableCell
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px",
                        borderBottom: "0px",
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          padding: "0px 5px",
                        }}
                        size="small"
                      >
                        {item.id}
                      </span>
                      <span
                        style={{
                          padding: "0px 5px",
                        }}
                        size="small"
                      >
                        {item.value}
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
          <Table
            aria-label="simple table"
            sx={{
              border: "2px solid #979aa8",
              background: "#FFFFE6",
              flexWrap: "wrap",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: shiftBackgroud,
                    padding: "0px",
                    fontSize: "12px",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  shift +
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: "scroll",
              }}
            >
              <TableRow
                className="d-flex"
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {shift?.map((item, index) => {
                  return (
                    <TableCell
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px",
                        borderBottom: "0px",
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          padding: "0px 5px",
                        }}
                        size="small"
                      >
                        {item.id}
                      </span>
                      <span
                        style={{
                          padding: "0px 5px",
                        }}
                        size="small"
                      >
                        {item.value}
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Draggable>
    </>
  );
}
