import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDashboardData } from "../../Redux/Actions/dashboardAction";
import { getImageSeries } from "../../Redux/Actions/imageSeriesAction";
import { ConfirmationDialogRaw } from "../ImageSeries/ImageSeries";
import CollapseBox from "./CollapseBox";

export default function Dashboard() {
  const token = JSON.parse(sessionStorage.getItem("user"));
  const dashboardData = useSelector((state) => state.dashboardReducer);
  const imageSeries = useSelector((state) => state.imageSeriesReducer);
  const dispatch = useDispatch();

  const [totalSeries, setTotalSeries] = useState(0);
  const [totalTag, setTotalTag] = useState(0);
  const [totalEntry, setTotalEntry] = useState(0);
  const [notWorking, setNotWorking] = useState(0);
  const [totalBBD, setTotalBBD] = useState(0);

  // Returns the end value of the value.
  const count = (endValue, setValue) => {
    const duration = 100;
    const increment = Math.ceil((endValue - 0) / duration);

    let startValue = 0;
    let counter = setInterval(() => {
      startValue += increment;
      if (startValue >= endValue) {
        startValue = endValue;
        clearInterval(counter);
      }
      setValue(startValue);
    }, 1);
  };

  useEffect(() => {
    if (dashboardData !== null) {
      count(dashboardData?.total_not_working, setNotWorking);
      count(dashboardData?.total_tag, setTotalTag);
      count(dashboardData?.total_entry, setTotalEntry);
      count(dashboardData?.total_series, setTotalSeries);
      count(dashboardData?.total_bad_image, setTotalBBD);
    }
  }, [dashboardData]);

  useEffect(() => {
    document.title = "Data Keying";
    dispatch(getDashboardData());
    token && dispatch(getImageSeries());
  }, []);

  return (
    <React.Fragment>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-info"
                data-toggle="collapse"
                href="#collapseSeries"
                role="button"
                aria-expanded="false"
                aria-controls="collapseSeries"
              >
                <div className="inner">
                  <h3>{totalSeries}</h3>
                  <p>Allocated series</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <div className="small-box-footer">
                  {imageSeries?.map((item, index) => (
                    <CollapseBox
                      id={"collapseSeries"}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-warning"
                data-toggle="collapse"
                href="#collapseTaggedImage"
                role="button"
                aria-expanded="false"
                aria-controls="collapseTaggedImage"
              >
                <div className="inner">
                  <h3>{totalTag}</h3>
                  <p>Tagged Images</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <div to="#" className="small-box-footer">
                  {dashboardData?.tag?.map((item, index) => (
                    <CollapseBox
                      id={"collapseTaggedImage"}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-success"
                data-toggle="collapse"
                href="#collapseEntryCompleted"
                role="button"
                aria-expanded="false"
                aria-controls="collapseEntryCompleted"
              >
                <div className="inner">
                  <h3>{totalEntry}</h3>
                  <p>Entry Completed</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <div to="#" className="small-box-footer">
                  {dashboardData?.entry?.map((item, index) => (
                    <CollapseBox
                      id={"collapseEntryCompleted"}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-danger"
                data-toggle="collapse"
                href="#collapseAvailabeImage"
                role="button"
                aria-expanded="false"
                aria-controls="collapseAvailabeImage"
              >
                <div className="inner">
                  <h3>{notWorking}</h3>
                  <p>Available Image</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <div className="small-box-footer">
                  {dashboardData?.not_working?.map((item, index) => (
                    <CollapseBox
                      id={"collapseAvailabeImage"}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div
                className="small-box bg-secondary"
                data-toggle="collapse"
                href="#collapseNoNeedEntry"
                role="button"
                aria-expanded="false"
                aria-controls="collapseNoNeedEntry"
              >
                <div className="inner">
                  <h3>{totalBBD}</h3>
                  <p>No Need to Entry</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <div className="small-box-footer">
                  {dashboardData?.bad_image?.map((item, index) => (
                    <CollapseBox
                      id={"collapseNoNeedEntry"}
                      item={item}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </React.Fragment>
  );
}
