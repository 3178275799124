import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import RotateLeftIcon from "@mui/icons-material/RotateRight";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import CollectionsIcon from "@mui/icons-material/Collections";
import CurtainsIcon from "@mui/icons-material/Curtains";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TitleIcon from "@mui/icons-material/Title";
import RedoIcon from "@mui/icons-material/Redo";
import CelebrationIcon from "@mui/icons-material/Celebration";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SecurityIcon from "@mui/icons-material/Security";
import ListIcon from "@mui/icons-material/List";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import "../DataEdit/DataEdit.css";
import pointing from "../../images/logo/pointing1.png";
import { useEffect, useState } from "react";
import { handleRotation } from "../Helper/commonHelper";
import { handleDuplicateImage, handleLanguage } from "../Helper/editHelper";
import { useDispatch } from "react-redux";
import { createLine, deleteImage } from "../../Redux/Actions/dataEditAction";
import { updateImageComment } from "../../Redux/Actions/dataEditAction";
import PopupDialog from "../CommonUI/PopupDialog";
const radioButton = [
  [
    { name: "All", value: "100" },
    { name: "Bad", value: "99" },
    { name: "Duplicate", value: "98" },
  ],
  [
    { name: "Birth", value: "1" },
    { name: "Marriage", value: "3" },
    { name: "Death", value: "2" },
  ],
  [
    { name: "Mixed", value: "4" },
    { name: "Coverpage", value: "5" },
    { name: "Index", value: "6" },
  ],
  [
    { name: "Privacy", value: "7" },
    { name: "Continue", value: "8" },
    { name: "Entry", value: "9" },
  ],
  [
    { name: "Capture", value: "10" },
    { name: "Target", value: "11" },
    { name: "Error", value: "12" },
  ],
];

export default function TopFooter({
  setSelectedField,
  radioButtonList,
  selectedImage,
  imagesData,
  setSelectedDirection,
  imgRef,
  selectedType,
  selectedImageNumber,
  selectedSeries,
  setSelectedType,
  setTableBody,
  setOpenFloatingTable,
  setClickedButton,
  selectedField,
  selectedImageLine,
  getDataForEditTable,
  setDialogOpen,
  setImgComment,
  isBlankOffsetChecked,
  setIsBlankOffsetChecked,
  setBlankOffsetOpen,
  setOffsetValue,
  record,
}) {
  // const dispatch = useDispatch();
  // const [isDownChecked, setIsDownChecked] = useState(false);
  // const [isRightChecked, setIsRightChecked] = useState(true);
  // useEffect(() => {
  //   if (isRightChecked) {
  //     setSelectedDirection("right");
  //   } else if (isRightChecked) {
  //     setSelectedDirection("down");
  //   }
  // }, [isDownChecked, isRightChecked]);

  // const handleReEntry = () => {
  //   if (selectedImageNumber !== null) {
  //     dispatch(deleteImage(selectedSeries, selectedImageNumber));
  //   }
  // };

  return (
    <>
      <Grid item className="col-12">
        <FormControl
          component="fieldset"
          sx={{
            display: "flex",
            border: "1px solid #b1b1b1",
            borderRadius: "5px",
            px: "2px",
            marginTop: "-5px",
            width: "auto",
          }}
        >
          <FormLabel
            component="legend"
            sx={{ margin: 0, fontSize: "12px", width: "auto" }}
            color="primary"
          >
            Field
          </FormLabel>

          <RadioGroup
            className="footerTop"
            aria-label="language"
            name="row-radio-buttons-group"
            // defaultValue="German"
            value={selectedField}
            onChange={(e) => {
              setSelectedField(e.target.name);
              // setTableBody([]);
            }}
            // sx={{
            //   my: 0,
            //   px: 1,
            //   paddingBottom: 0.5,
            //   paddingTop: -0.5,
            // }}
          >
            {radioButtonList.map((item, index) => {
              return (
                // index > 6 &&
                // index < tableHeader.length - 4 && (
                <FormControlLabel
                  className="col-xl-2 col-lg-3 col-md-4 col-sm-6 m-0"
                  key={index}
                  sx={{
                    marginBottom: 0,
                    wordBreak: "break-all",
                  }}
                  value={item}
                  name={item}
                  control={<Radio size="small" sx={{ py: 0, px: 1 }} />}
                  label={
                    <Typography
                      style={{
                        fontSize: "10px",
                        fontWeight: 600,
                        margin: "2px 0px",
                      }}
                      lineHeight={1}
                    >
                      {item}
                    </Typography>
                  }
                />
                // )
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
}
