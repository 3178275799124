import { GET_IMAGE_SERIES_NUMBER } from "../Actions/types";

const initialState = [];

function imageSeriesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_IMAGE_SERIES_NUMBER:
      return payload;

    default:
      return state;
  }
}

export default imageSeriesReducer;
