import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function PopupDialog({
  dispatch,
  dialogOpen,
  handleClose,
  handleDuplicateImage,
  selectedImageNumber,
  imgComment,
  selectedImageLine,
}) {
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        status={imgComment}
      >
        <DialogTitle id="alert-dialog-title">
          {imgComment === "Duplicate"
            ? "Do you want to mark this image as DUPLICATE image ?"
            : imgComment === "Delete"
            ? "Do you want to create DELETE this LINE from this image ?"
            : imgComment === "Retag"
            ? "Do you want to TAG this image again?"
            : "Do you want to create A NEW LINE for this image ?"}
        </DialogTitle>
        <DialogContent></DialogContent>

        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() =>
              handleDuplicateImage(
                dispatch,
                handleClose,
                selectedImageNumber,
                imgComment,
                selectedImageLine["SERIES_NUMBER"],
                selectedImageLine["IMAGE_NAME"],
                selectedImageLine["CODE"],
                selectedImageLine["FORMAT"],
                selectedImageLine["LINE"]
              )
            }
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
