import { GET_IMAGE_OFFSET, SET_IMAGE_OFFSET } from "../Actions/types";

const initialState = 0;

function focusImageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IMAGE_OFFSET:
      return payload;
    case GET_IMAGE_OFFSET:
      return payload;

    default:
      return state;
  }
}

export default focusImageReducer;
