import {
  CREATE_LINE,
  DELETE_DATA,
  DELETE_LINE,
  GET_SERIES_DATA_FOR_EDIT,
  UPDATE_DATA,
  UPDATE_IMAGE_COMMENT,
} from "../Actions/types";

const initialState = [];
function dataEditReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SERIES_DATA_FOR_EDIT:
      return payload;
    case UPDATE_DATA:
      return state.map((row) => {
        if (
          row["IMAGE_NUMBER"] == payload.imageNumber &&
          row["LINE"] == payload.line
        ) {
          for (let x in row) {
            if (x == payload.fieldName) {
              row[x] = payload.fieldValue;
            }
          }
          return {
            ...row,
          };
        } else {
          return row;
        }
      });
    case DELETE_DATA:
      let newState = [];
      state.map((row) => {
        if (row["IMAGE_NUMBER"] !== payload) {
          newState.push(row);
        }
      });
      return newState;
    case DELETE_LINE:
      let newState4 = [];
      state.map((row) => {
        if (row["IMAGE_NUMBER"] === payload.imageNumber) {
          if (row["LINE"] < payload.LINE) {
            newState4.push(row);
          } else if (row["LINE"] > payload.LINE) {
            newState4.push({ ...row, ["LINE"]: row.LINE - 1 });
          }
        } else newState4.push(row);
      });
      return newState4;
    case CREATE_LINE:
      let newState2 = [];
      state.map((row) => {
        if (
          row["IMAGE_NUMBER"] === payload.IMAGE_NUMBER &&
          row["LINE"] === payload.LINE
        ) {
          newState2.push(payload);
          newState2.push({ ...row, ["LINE"]: row.LINE + 1 });
        } else if (
          row["IMAGE_NUMBER"] === payload.IMAGE_NUMBER &&
          row["LINE"] > payload.LINE
        ) {
          newState2.push({ ...row, ["LINE"]: row.LINE + 1 });
        } else newState2.push(row);
      });
      return newState2;
    case UPDATE_IMAGE_COMMENT:
      let newState3 = [];
      state.map((row) => {
        if (row["IMAGE_NUMBER"] !== payload.IMAGE_NUMBER) {
          newState3.push(row);
        }
      });
      return newState3;
    default:
      return state;
  }
}

export default dataEditReducer;
