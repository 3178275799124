import React from "react";
// import HomeIcon from "@mui/icons-material/Home";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// import FmdBadIcon from "@mui/icons-material/FmdBad";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
// import HelpIcon from "@mui/icons-material/Help";
import PsychologyIcon from "@mui/icons-material/Psychology";
import LogoutIcon from "@mui/icons-material/Logout";
export const links = [
  {
    id: 1,
    url: "/",
    type: "dashboard",
    text: "Dashboard",
    icon: <DashboardIcon />,
  },
  // {
  //   id: 2,
  //   url: "/calendar",
  //   text: "Profile",
  //   icon: <AccountBoxIcon />,
  // },
  // {
  //   id: 3,
  //   url: "/documents",
  //   text: "BBD Checking",
  //   icon: <FmdBadIcon />,
  // },
  {
    id: 4,
    url: "#",
    // url: "/series?target=tag",
    type: "tag",
    text: "Field Tagging",
    icon: <ZoomOutIcon />,
  },
  {
    id: 5,
    url: "#",
    // url: "/series?target=tag-index",
    type: "tag-index",
    text: "Index Tagging",
    icon: <ZoomOutIcon />,
  },
  {
    id: 15,
    url: "#",
    type: "ai",
    text: "AI Tagging",
    icon: <PsychologyIcon />,
  },
  {
    id: 6,
    // url: "/series?target=entry",
    url: "#",
    type: "entry",
    text: "Data Entry",
    icon: <KeyboardAltIcon />,
  },
  // {
  //   id: 7,
  //   url: "/documents",
  //   text: "Keying Instruction",
  //   icon: <IntegrationInstructionsIcon />,
  // },
  // {
  //   id: 8,
  //   url: "/documents",
  //   text: "Practice with help",
  //   icon: <HelpIcon />,
  // },
  {
    id: 9,
    url: "#",
    type: "edit",
    text: "Data Edit",
    icon: <BorderColorIcon />,
  },
  {
    id: 10,
    url: "#",
    type: "tag-checking",
    text: "Tag Checking",
    icon: <PlaylistAddCheckIcon />,
  },
  {
    id: 11,
    url: "#",
    type: "all-checking",
    text: "All Checking",
    icon: <FactCheckIcon />,
  },
  {
    id: 12,
    url: "#",
    type: "bbd-checking",
    text: "BBD Checking",
    icon: <FolderCopyIcon />,
  },
  {
    id: 13,
    url: "#",
    type: "entry-count",
    text: "Entry Count",
    icon: <HourglassBottomIcon />,
  },
  {
    id: 14,
    url: "/login",
    type: "logout",
    text: "Sign out",
    icon: <LogoutIcon />,
  },
];
