import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import RotateLeftIcon from "@mui/icons-material/RotateRight";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import CollectionsIcon from "@mui/icons-material/Collections";
import CurtainsIcon from "@mui/icons-material/Curtains";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TitleIcon from "@mui/icons-material/Title";
import RedoIcon from "@mui/icons-material/Redo";
import CelebrationIcon from "@mui/icons-material/Celebration";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SecurityIcon from "@mui/icons-material/Security";
import ListIcon from "@mui/icons-material/List";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import "../DataEdit/DataEdit.css";
import pointing from "../../images/logo/pointing1.png";
import { useEffect, useState } from "react";
import { handleRotation } from "../Helper/commonHelper";
import { handleDuplicateImage, handleLanguage } from "../Helper/editHelper";
import { useDispatch } from "react-redux";
import { updateImageComment } from "../../Redux/Actions/dataEditAction";
import {
  changeAImageStatus,
  retagAImage,
} from "../../Redux/Actions/bbdCheckingAction";
import WarningPopup from "../CommonUI/WarningPopup";
import { handleAction, travelAcrossArray } from "../Helper/library";
import { allFieldsObject } from "../Helper/entryField";

const activeDataTypes = Object.entries(allFieldsObject)
  .filter(([key, value]) => value.status === "active")
  .map(([key]) => key.toUpperCase());

const radioButtonArray = [
  "ALL",
  "BAD",
  "BLANK",
  "DUPLICATE",
  ...activeDataTypes,
  "COVERPAGE",
  "INDEX",
  "CONTINUE",
  "ENTRY",
  "CAPTURE",
  "TARGET",
  "ERROR",
];

export default function Footer({
  selectedImage,
  imagesData,
  imgRef,
  selectedType,
  setSelectedType,
  setImgComment,
  setIsRefresh,
  setSelectedImage,
  selectedImageIndex,
}) {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageComment, setImageComment] = useState("");
  const handleRetag = () => {
    dispatch(retagAImage(selectedImage?.IMAGE_NUMBER));
  };
  const handlePopupClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {dialogOpen && (
        <WarningPopup
          dispatch={dispatch}
          open={dialogOpen}
          close={handlePopupClose}
          handleAction={handleAction}
          action={imageComment}
          selectedImage={selectedImage}
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          paddingTop: "5px",
          background: "#d9ebff",
          position: "fixed",
          bottom: "0px",
          width: "100%",
          height: "14vh",
          overflowY: "scroll",
        }}
      >
        <Grid
          container
          item
          lg={12}
          py={0.4}
          sx={{ justifyContent: "space-between", py: 0 }}
        >
          {/* <Grid item lg={8}> */}
          <Grid item className="col-4">
            <FormControl
              component="fieldset"
              sx={{
                display: "flex",
                border: "1px solid #b1b1b1",
                borderRadius: "5px",
                px: "4px",
                marginTop: "-5px",
              }}
            >
              <FormLabel
                component="legend"
                sx={{ margin: 0, fontSize: "12px", width: "auto" }}
                color="primary"
              >
                Type
              </FormLabel>

              <RadioGroup
                className="footerBottom"
                aria-label="language"
                name="row-radio-buttons-group"
                defaultValue="German"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                {radioButtonArray.map((item, index) => {
                  return (
                    <FormControlLabel
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-0"
                      key={index}
                      sx={{
                        marginBottom: 0,
                        wordBreak: "break-all",
                      }}
                      value={item}
                      name={item}
                      control={<Radio size="small" sx={{ py: 0, px: 0 }} />}
                      label={
                        <Typography
                          style={{
                            fontSize: "10px",
                            fontWeight: 600,
                            margin: "2px 0px",
                          }}
                          lineHeight={1}
                        >
                          {item}
                        </Typography>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className="col-8">
            <Grid
              container
              item
              direction="row"
              xl={12}
              spacing={2}
              my={0}
              py={0.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px",
                width: "100%",
                paddingTop: 0,
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  color="error"
                  fontSize={10}
                  sx={{
                    fontWeight: "bold",
                    background: "white",
                    borderRadius: "5px",
                    lineHeight: "12px",
                  }}
                >
                  Entry : {imagesData?.length} | Series :{" "}
                  {selectedImage?.SERIES_NUMBER} | Image :{" "}
                  {selectedImage?.IMAGE_NUMBER}
                </Typography>
              </Stack>
            </Grid>
            <Stack className="d-inline-block" spacing={0.4} py={0.4}>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <KeyboardDoubleArrowLeftIcon
                    sx={{ marginLeft: "-5px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() =>
                  setSelectedImage(
                    imagesData[
                      travelAcrossArray(
                        imagesData,
                        selectedImageIndex,
                        "negative"
                      )
                    ]
                  )
                }
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              ></Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <KeyboardDoubleArrowRightIcon
                    sx={{ marginLeft: "-5px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setSelectedImage(
                    imagesData[
                      travelAcrossArray(
                        imagesData,
                        selectedImageIndex,
                        "positive"
                      )
                    ]
                  );
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              ></Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <RotateLeftIcon
                    sx={{ marginLeft: "-5px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => handleRotation(imgRef)}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Rotate
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <AddRoadIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("RETAG");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Re-Tag
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <AutorenewIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => setIsRefresh(true)}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Refresh
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <FileCopyIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("DUPLICATE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Duplicate
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <FmdBadIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("BAD");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Bad
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <CurtainsIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("COVERPAGE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Coverpage
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <AccountTreeIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("MIXED");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Mixed
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <FileCopyIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("REGISTER");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Register
              </Button>
              {/* <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <TitleIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
                }
                color="primary"
                // onClick={() => {
                //   setDialogOpen(true);
                //   setImgComment("Duplicate");
                // }}
                variant="outlined"
                size="small"
              >
                Titleboard
              </Button> */}
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <RedoIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("CONTINUE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Continue
              </Button>
              {/* <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <CelebrationIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("BAPTISM");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Baptism
              </Button> */}
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <LocalOfferIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("BURIAL");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Burial
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <SupervisorAccountIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("MARRIAGE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Marriage
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <CheckCircleOutlineIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("ENTRY_COMPLETE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Entry Complete
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <SecurityIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("PRIVACY");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Privacy
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <ListIcon sx={{ marginLeft: "-2px", marginRight: "-5px" }} />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("INDEX");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Index
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <AddAPhotoIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("CAPTURE");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Capture
              </Button>
              <Button
                sx={{ fontSize: "10px", marginRight: "4px !important" }}
                startIcon={
                  <CrisisAlertIcon
                    sx={{ marginLeft: "-2px", marginRight: "-5px" }}
                  />
                }
                color="primary"
                onClick={() => {
                  setDialogOpen(true);
                  setImageComment("TARGET");
                }}
                variant="outlined"
                size="small"
                disabled={selectedImage === null}
              >
                Target
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
