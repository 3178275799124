import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { useRef } from "react";

export default function EntryCountTable({
  imagesData,
  tableBody,
  tableHeader,
  tableFooter,
}) {
  const dateModify = (date) => {
    return date.toString().substr(0, 10);
  };
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "85vh",
          maxHeight: "85vh",
          overflow: "scroll",
          background: "#FFFFE6",
          borderTop: "2px solid #d5eaff",
        }}
      >
        {imagesData?.length > 0 ? (
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="col-12"
          >
            <TableHead>
              <TableRow>
                {tableHeader?.length > 0 &&
                  tableHeader?.map((image, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: "2px solid #b1b1b1",
                        background: "#d3d3d3",
                        fontSize: "12px",
                        padding: "0px 4px",
                      }}
                      size="small"
                      className="text-center"
                    >
                      {image}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody?.map((row, index) => (
                <TableRow
                  key={index}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  sx={{
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  {tableHeader?.map((image, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          border: "2px solid #b1b1b1!important",
                          padding: "0px",
                          borderBottom: "0px",
                        }}
                        size="small"
                        className="text-center"
                      >
                        <span>
                          {image.includes("_DATE")
                            ? dateModify(row[image])
                            : row[image]}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {tableFooter?.length > 0 &&
                  tableFooter?.map((image, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        border: "2px solid #b1b1b1",
                        background: "#d3d3d3",
                        fontSize: "12px",
                        padding: "0px 4px",
                      }}
                      size="small"
                      className="text-center"
                    >
                      {image}
                    </TableCell>
                  ))}
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <div className="d-flex justify-content-center">
            No data available on selected duration
          </div>
        )}
      </Box>
    </>
  );
}
