import {
  FATHER_NAMES,
  MOTHER_NAMES,
  NAME,
  NAMES,
  SPOUSE_FATHER_NAMES,
  SPOUSE_MOTHER_NAMES,
  SPOUSE_NAMES,
} from "./entryField";
import disableScroll from "disable-scroll";
import { notifyWarn } from "./toasts";

export const selectInputFields = (selectedInputFields) => {
  let activeFieldArray = [];
  selectedInputFields.map((field) => {
    if (field.fieldName === "NAME") {
      NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else if (field.fieldName === "FATHER_NAME") {
      FATHER_NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else if (field.fieldName === "MOTHER_NAME") {
      MOTHER_NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else if (field.fieldName === "SPOUSE_NAME") {
      SPOUSE_NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else if (field.fieldName === "SPOUSE_FATHER_NAME") {
      SPOUSE_FATHER_NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else if (field.fieldName === "SPOUSE_MOTHER_NAME") {
      SPOUSE_MOTHER_NAMES.map((field) => {
        activeFieldArray = [
          ...activeFieldArray,
          { ...field, active: !field.active },
        ];
      });
    } else {
      activeFieldArray = [
        ...activeFieldArray,
        { ...field, active: !field.active },
      ];
    }
  });
  return activeFieldArray;
};
export const allFields = (allInputFields, selectedInputFields) => {
  let allFieldArray = [];
  for (let index = 0; index < allInputFields.length; index++) {
    let commonField;
    for (let i = 0; i < selectedInputFields.length; i++) {
      if (
        selectedInputFields[i].fieldName === allInputFields[index].fieldName
      ) {
        commonField = { ...selectedInputFields[i], active: true };
        break;
      } else if (
        selectedInputFields[i].fieldName === "GIVENNAME" ||
        selectedInputFields[i].fieldName === "SURNAME" ||
        selectedInputFields[i].fieldName === "MAIDEN_NAME"
      ) {
        if (allInputFields[index].fieldName === "NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      } else if (
        selectedInputFields[i].fieldName === "FATHER_GIVENNAME" ||
        selectedInputFields[i].fieldName === "FATHER_SURNAME"
      ) {
        if (allInputFields[index].fieldName === "FATHER_NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      } else if (
        selectedInputFields[i].fieldName === "MOTHER_GIVENNAME" ||
        selectedInputFields[i].fieldName === "MOTHER_SURNAME" ||
        selectedInputFields[i].fieldName === "MOTHER_MAIDEN_NAME"
      ) {
        if (allInputFields[index].fieldName === "MOTHER_NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      } else if (
        selectedInputFields[i].fieldName === "SPOUSE_GIVENNAME" ||
        selectedInputFields[i].fieldName === "SPOUSE_SURNAME" ||
        selectedInputFields[i].fieldName === "SPOUSE_MAIDEN_NAME"
      ) {
        if (allInputFields[index].fieldName === "SPOUSE_NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      } else if (
        selectedInputFields[i].fieldName === "SPOUSE_FATHER_GIVENNAME" ||
        selectedInputFields[i].fieldName === "SPOUSE_FATHER_SURNAME"
      ) {
        if (allInputFields[index].fieldName === "SPOUSE_FATHER_NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      } else if (
        selectedInputFields[i].fieldName === "SPOUSE_MOTHER_GIVENNAME" ||
        selectedInputFields[i].fieldName === "SPOUSE_MOTHER_SURNAME" ||
        selectedInputFields[i].fieldName === "SPOUSE_MOTHER_MAIDEN_NAME"
      ) {
        if (allInputFields[index].fieldName === "SPOUSE_MOTHER_NAME") {
          commonField = { ...allInputFields[index], active: true };
        }
      }
    }
    if (commonField !== undefined) {
      allFieldArray.push(commonField);
    } else {
      allFieldArray.push(allInputFields[index]);
    }
  }
  return allFieldArray;
};

const CheckBoxClickHandler = (
  e,
  allInputFields,
  customFields,
  setCustomFields
) => {
  const { value, checked } = e.target;
  allInputFields.map((field) => {
    let newCustomFields = customFields;
    let removedField;
    if (field.fieldIndex == value) {
      if (checked == false) {
        if (field.fieldIndex === 47) {
          removedField = customFields.filter(
            (data) =>
              data.fieldIndex !== 7 &&
              data.fieldIndex !== 8 &&
              data.fieldIndex !== 9
          );
        } else if (field.fieldIndex === 48) {
          removedField = customFields.filter(
            (data) => data.fieldIndex !== 13 && data.fieldIndex !== 14
          );
        } else if (field.fieldIndex === 49) {
          removedField = customFields.filter(
            (data) =>
              data.fieldIndex !== 17 &&
              data.fieldIndex !== 18 &&
              data.fieldIndex !== 19
          );
        } else if (field.fieldIndex === 50) {
          removedField = customFields.filter(
            (data) =>
              data.fieldIndex !== 32 &&
              data.fieldIndex !== 33 &&
              data.fieldIndex !== 34
          );
        } else if (field.fieldIndex === 51) {
          removedField = customFields.filter(
            (data) => data.fieldIndex !== 40 && data.fieldIndex !== 41
          );
        } else if (field.fieldIndex === 52) {
          removedField = customFields.filter(
            (data) =>
              data.fieldIndex !== 43 &&
              data.fieldIndex !== 44 &&
              data.fieldIndex !== 45
          );
        } else {
          removedField = customFields.filter(
            (data) => data.fieldIndex !== field.fieldIndex
          );
        }
        // setAllInputFields(removedField);
        setCustomFields(removedField);
      } else if (checked === true) {
        let addedField;
        if (field.fieldIndex === 47) {
          NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else if (field.fieldIndex === 48) {
          FATHER_NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else if (field.fieldIndex === 49) {
          MOTHER_NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else if (field.fieldIndex === 50) {
          SPOUSE_NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else if (field.fieldIndex === 51) {
          SPOUSE_FATHER_NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else if (field.fieldIndex === 52) {
          SPOUSE_MOTHER_NAMES.map((field) => {
            newCustomFields.push(field);
          });
        } else {
          addedField = allInputFields.find(
            (singleField) => singleField.fieldIndex === field.fieldIndex
          );
          newCustomFields.push(addedField);
        }
        setCustomFields(newCustomFields);
      }
      return { ...field, active: !field.active };
    } else {
      return field;
    }
  });
};

export const createCanvas = (canvasId, x, y, w, h, color, opacityData) => {
  const cvs = document.createElement("canvas");
  cvs.id = canvasId;
  cvs.style.position = "absolute";
  cvs.style.left = x;
  cvs.style.top = y;
  cvs.style.width = w;
  cvs.style.height = h;
  cvs.style.background = color;
  cvs.style.opacity = opacityData;

  return cvs;
};

export const Zooming = (e, setScale, imgRef) => {
  if (!e.altKey && !e.shiftKey) {
    disableScroll.on();
    var delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
    delta > 0
      ? setScale((prevScale) => (prevScale *= 1.08).toFixed(2))
      : setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
    // setTransform(e);
  } else {
    disableScroll.off();
  }
};

export const setModifiedDateValue = (value) => {
  const modifiedValue = value.replace(
    /(January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|january|february|march|april|may|june|july|august|september|october|november|december|jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec|JANUARY|FEBRUARY|MARCH|APRIL|MAY|JUNE|JULY|AUGUST|SEPTEMBER|OCTOBER|NOVEMBER|DECEMBER|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC|-| )/g,
    (x) => {
      if (x === "-" || x === " ") {
        return "/";
      } else return x.charAt(0).toUpperCase() + x.slice(1, 3);
    }
  );
  return modifiedValue;
};

export const validateFunc = (field, value) => {
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  // const regexddmmyyyy =
  //   /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
  // const regexddmmmyyyy =
  //   /^([0-2][0-9]|(3)[0-1])(\/)((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/)((1)[6-9][0-9][0-9])$/i;

  // const regexddmmmyyyy =
  //   /^([1-9]|(0)[1-9]|[1-2][0-9]|(3)[0-1])(\/)((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|[1-9]|(0)[1-9]|[1][0-2]))(\/)((1)[6-9][0-9][0-9])$/i;

  const regexddmmyy =
    /^(31)(\/)(Jan|Mar|May|Jul|Aug|Oct|Dec|1|3|5|7|8||01|03|05|07|08|10|12)(\/)((1)[6-9][0-9][0-9])$/i;
  const regexddmmyy31 =
    /^([1-9]|(0)[1-9]|[1-2][0-9]|(30))(\/)((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|[1-9]|(0)[1-9]|[1][0-2]))(\/)((1)[6-9][0-9][0-9])$/i;
  const regexGender = /^[A-Za-z]+$/;

  if (field == "certiNo") {
    if (!validEmailRegex.test(value)) {
      notifyWarn("Please enter  valid email address");
    }
  } else if (field.includes("DATE")) {
    if (!regexddmmyy.test(value)) {
      if (!regexddmmyy31.test(value)) {
        return false;
      }
      return true;
      // alert("Please enter  valid date format");
    } else return true;
  } else if (field.includes("GENDER")) {
    if (!regexGender.test(value)) {
      return false;
      // alert("Please enter  valid date format");
    } else return true;
  } else return true;
};

export const identifyPosition = () => {};
