import http from "../../utilities/http-common";

const getDataFieldSequence = async (selectedSeries, code, line) => {
  return await http.get(
    `/Image/GetFieldSequence/${selectedSeries}/${code}/${line}`
  );
};
const setDataFieldSequence = async (data) => {
  return await http.post("/Image/InsertFieldSequence", data);
};
const updateDataFieldSequence = async (data) => {
  return await http.post("/Image/UpdateFieldSequence", data);
};

const FieldSequenceService = {
  getDataFieldSequence,
  setDataFieldSequence,
  updateDataFieldSequence,
};

export default FieldSequenceService;
