import { combineReducers } from "redux";
import projectReducer from "./projectReducer";
import languageReducer from "./languageReducer";
import countryReducer from "./countryReducer";
import authReducer from "./authReducer";
import imageTaggingReducer from "./imageTaggingReducer";
import imageEntryReducer from "./imageEntryReducer";
import searchDataReducer from "./searchDataReducer";
import continueImageReducer from "./continueImageReducer";
import ctrlTwoReducer from "./ctrlTwoReducer";
import focusImageReducer from "./focusImageReducer";
import dashboardReducer from "./dashboardReducer";
import imageSeriesReducer from "./imageSeriesReducer";
import dataEditReducer from "./dataEditReducer";
import fieldSequenceReducer from "./fieldSequenceReducer";
import entryCountReducer from "./entryCountReducer";
import tagCheckingReducer from "./tagCheckingReducer";
import bbdCheckingReducer from "./bbdCheckingReducer";
import allCheckingReducer from "./allCheckingReducer";

export default combineReducers({
  projectReducer,
  languageReducer,
  countryReducer,
  authReducer,
  imageTaggingReducer,
  imageEntryReducer,
  searchDataReducer,
  continueImageReducer,
  ctrlTwoReducer,
  focusImageReducer,
  dashboardReducer,
  imageSeriesReducer,
  dataEditReducer,
  fieldSequenceReducer,
  entryCountReducer,
  tagCheckingReducer,
  bbdCheckingReducer,
  allCheckingReducer,
});
