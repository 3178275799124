import http from "../../utilities/http-common";

const getDashboardData = async () => {
  return await http.get("/dashboard-data");
};
const getEditPageData = async (seriesNumber) => {
  return await http.get(`/Edit/GetEditImageInformation/${seriesNumber}`);
};
const DashboardService = {
  getDashboardData,
  getEditPageData,
};

export default DashboardService;
