import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  fieldSequenceOnjectGenerator,
  parentFieldsGenerator,
  pascalToCamelCase,
} from "../Helper/library";
import { useDispatch } from "react-redux";
import { updateFieldSequence } from "../../Redux/Actions/fieldSequenceActions";
import { allFieldsObject } from "../Helper/entryField";
import { MIXED_TYPE_CODE } from "../Helper/constants";

export default function AlertBox({ open, onClose, header, ...props }) {
  const {
    title,
    type,
    code,
    selectedSeries,
    fieldSequenceProps,
    setFieldSequenceProps,
    dataType,
  } = props;
  const dispatch = useDispatch();
  const [fieldSequenceState, setFieldSequenceState] = useState(null);
  const [parentInputFields, setParentInputFields] = useState([]);
  const [customParentFields, setCustomParentFields] = useState([]);

  useEffect(() => {
    if (fieldSequenceProps?.length >= 0 && dataType !== "") {
      const dataObject = allFieldsObject[pascalToCamelCase(dataType)];
      const newParentMenus = parentFieldsGenerator(
        dataObject.fields,
        fieldSequenceProps
      );
      setParentInputFields(newParentMenus);
      setCustomParentFields(newParentMenus);
    }
  }, [fieldSequenceProps, dataType]);

  useEffect(() => {
    if (customParentFields?.length > 0) {
      const dataObject = allFieldsObject[pascalToCamelCase(dataType)];

      const extractedCode =
        code === MIXED_TYPE_CODE ? dataObject.imgCode : code;

      setFieldSequenceState(
        fieldSequenceOnjectGenerator(
          fieldSequenceProps,
          selectedSeries,
          extractedCode
        )
      );
    }
  }, [fieldSequenceProps?.length]);

  const checkBoxChange = (e, fieldSequenceProps) => {
    const { value, checked } = e.target;
    let newFieldSequence = [...fieldSequenceProps];

    if (checked == false) {
      const index = newFieldSequence?.indexOf(value);
      if (index > -1) {
        newFieldSequence.splice(index, 1);
      }
    } else if (checked === true) {
      newFieldSequence.push(value);
    }
    setFieldSequenceProps(newFieldSequence);
  };

  const handleSave = () => {
    if (fieldSequenceState !== null) {
      dispatch(updateFieldSequence(fieldSequenceState));
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="100%"
      sx={{ top: "-50%" }}
    >
      <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {title}
        </DialogContentText>
        {parentInputFields && (
          <div id="alert-dialog-description">
            <form>
              <div className="d-flex justify-content-between flex-wrap p-3 checkbox">
                {parentInputFields?.map((inp, i) => (
                  <div key={i} className="mx-2l">
                    <input
                      className="m-1 check-dialog"
                      checked={inp.active}
                      onChange={(e) => checkBoxChange(e, fieldSequenceProps)}
                      id={inp.fieldName}
                      name={inp.fieldName}
                      value={inp.fieldName}
                      type="checkbox"
                      disabled={inp.fieldName.includes("HEADER") ? true : false}
                    ></input>
                    <label htmlFor={inp.fieldName} className=" check-dialog">
                      {inp.labelName}
                    </label>
                  </div>
                ))}
              </div>
            </form>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {type === "alert" ? (
          <Button
            color="success"
            variant="outlined"
            onClick={onClose}
            autoFocus={true}
          >
            Ok
          </Button>
        ) : (
          <>
            <Button
              color="success"
              variant="outlined"
              onClick={handleSave}
              disabled={fieldSequenceState === null ? true : false}
            >
              Save
            </Button>

            {/* <Button color="error" variant="outlined" onClick={onClose}>
              cancel
            </Button> */}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
