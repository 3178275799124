import DashboardService from "../Services/dashboardService";
import { CLEAR_DASHBOARD, GET_DASHBOARD_DATA, TOTAL_RECORD } from "./types";

export const getDashboardData = () => async (dispatch) => {
  try {
    const res = await DashboardService.getDashboardData();
    dispatch({
      type: GET_DASHBOARD_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const getEditPageData = (seriesNumber) => async (dispatch) => {
  try {
    const res = await DashboardService.getEditPageData(seriesNumber);
    dispatch({
      type: TOTAL_RECORD,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const clearDashboard = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DASHBOARD,
    payload: null,
  });
};
