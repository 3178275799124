import {
  CHANGE_IMAGE_STATUS,
  ENTRY_COMPLETE,
  GET_All_IMAGE,
  RETAG_IMAGE,
} from "../Actions/types";

const initialState = [];
function bbdCheckingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_All_IMAGE:
      return payload;
    case CHANGE_IMAGE_STATUS:
      return state.map((item) => {
        if (item.IMAGE_NUMBER === payload.IMAGE_NUMBER) {
          return payload;
        } else {
          return item;
        }
      });
    case RETAG_IMAGE:
      return state.filter(
        (item) => item.IMAGE_NUMBER !== payload[0].IMAGE_NUMBER
      );
    case ENTRY_COMPLETE:
      return state.filter((item) => item.IMAGE_NUMBER !== payload.IMAGE_NUMBER);
    // case ENTRY_COMPLETE:
    //   return state.map((item) => {
    //     if (item?.IMAGE_NUMBER !== payload?.IMAGE_NUMBER) {
    //       return item;
    //     }
    //   });
    // return state.map((item) =>
    //   item.IMAGE_NUMBER === payload.IMAGE_NUMBER ? payload : item
    // );

    default:
      return state;
  }
}

export default bbdCheckingReducer;
