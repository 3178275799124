export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_PROJECT_ALL = "GET_PROJECT_ALL";
export const GET_PROJECT = "GET_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CREATE_PROJECT_DATABASE = "CREATE_PROJECT_DATABASE";

export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const GET_LANGUAGE_ALL = "GET_PROJLANGUAGE_ALL";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const GET_COUNTRY_ALL = "GET_COUNTRY_ALL";
export const GET_COUNTRY = "GET_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CURRENT_USER = "CURRENT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_IMAGE_SERIES_NUMBER = "GET_IMAGE_SERIES_NUMBER";
export const GET_IMAGE_DATA = "GET_IMAGE_DATA";
export const UPDATE_IMAGE_COMMENT = "UPDATE_IMAGE_COMMENT";
export const IMAGE_DATA_INSERT = "IMAGE_DATA_INSERT";

//Added by nasif
export const GET_IMAGE_ENTRY_DATA = "GET_IMAGE_ENTRY_DATA";
export const GET_IMAGE_COORDINATES = "GET_IMAGE_COORDINATES";
export const IMAGE_ENTRY_DATA_INSERT = "IMAGE_ENTRY_DATA_INSERT";
export const IMAGE_ENTRY_DATA_INSERT_FOR_FRC =
  "IMAGE_ENTRY_DATA_INSERT_FOR_FRC";
export const GET_SEARCH_DATA = "GET_SEARCH_DATA";
export const SET_EMPTY_SEARCHDATA = "SET_EMPTY_SEARCHDATA";
export const SET_EMPTY_COORDINATES = "SET_EMPTY_COORDINATES";
export const SET_EMPTY_TAGGING = "SET_EMPTY_TAGGING";
export const SET_DATA_FIELDS = "SET_DATA_FIELDS";
export const CTRL_TWO_RESPONSE_DATA = "CTRL_TWO_RESPONSE_DATA";
export const CTRL_ONE_RESPONSE_DATA = "CTRL_ONE_RESPONSE_DATA";
export const GET_CONTINUE_IMAGE_DATA = "GET_CONTINUE_IMAGE_DATA";
export const GET_CTRL_TWO_DATA = "GET_CTRL_TWO_DATA";
export const SET_IMAGE_OFFSET = "SET_IMAGE_OFFSET";
export const GET_IMAGE_OFFSET = "GET_IMAGE_OFFSET";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const TOTAL_RECORD = "TOTAL_RECORD";
export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";

//Edit
export const GET_SERIES_DATA_FOR_EDIT = "GET_SERIES_DATA_FOR_EDIT";
export const UPDATE_DATA = "UPDATE_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const DELETE_LINE = "DELETE_LINE";
export const CREATE_LINE = "CREATE_LINE";

//Field Sequence
export const GET_FIELD_SEQUENCE = "GET_FIELD_SEQUENCE";
export const SET_FIELD_SEQUENCE = "SET_FIELD_SEQUENCE";
export const UPDATE_FIELD_SEQUENCE = "UPDATE_FIELD_SEQUENCE";
export const SET_EMPTY_FIELD_SEQUENCE = "SET_EMPTY_FIELD_SEQUENCE";

//Entry Count
export const GET_ENTRY_COUNT_DATA = "GET_ENTRY_COUNT_DATA";

//Tag Checking
export const GET_All_TAGGED_IMAGE = "GET_All_TAGGED_IMAGE";
export const GET_SINGLE_TAGGED_IMAGE_DATA = "GET_SINGLE_TAGGED_IMAGE_DATA";

//BBD Checking
export const GET_All_IMAGE = "GET_All_IMAGE";
export const CHANGE_IMAGE_STATUS = "CHANGE_IMAGE_STATUS";
export const RETAG_IMAGE = "RETAG_IMAGE";
export const ENTRY_COMPLETE = "ENTRY_COMPLETE";
export const BLANK_UPDATE = "BLANK_UPDATE";

//All Checking
export const GET_All_FIELDS = "GET_All_FIELDS";
export const GET_FIELD_DATA = "GET_FIELD_DATA";
export const GET_FIELD_DATA_IMAGE = "GET_FIELD_DATA_IMAGE";
export const GET_FIELD_DATA_IMAGE_LINE = "GET_FIELD_DATA_IMAGE_LINE";
export const UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE";
