import http from "../../utilities/http-common";

const getAllTaggedImages = async (seriesNumber) => {
  return await http.get(`/TotalTag/images/${seriesNumber}`);
};
const getSingleTaggedImageDetails = async (imageNumber) => {
  return await http.get(`/TotalTag/imagedata/${imageNumber}`);
};

const tagCheckingService = {
  getAllTaggedImages,
  getSingleTaggedImageDetails,
};

export default tagCheckingService;
