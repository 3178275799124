import { FormControl, FormLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./AllChecking.css";

export default function BottomFooter({ fromImage, toImage }) {
  return (
    <>
      <Grid item>
        <FormControl
          component="fieldset"
          sx={{
            display: "flex",
            border: "1px solid #b1b1b1",
            borderRadius: "5px",
            px: "2px",
            width: "auto",
          }}
        >
          <FormLabel
            component="legend"
            sx={{ margin: 0, fontSize: "12px", width: "auto" }}
            color="primary"
          >
            Image Range
          </FormLabel>
          <div className="d-flex">
            <TextField
              placeholder="From Image"
              fullWidth={false}
              size="small"
              className="p-1 w-100"
              value={fromImage}
            />
            <TextField
              placeholder="To Image"
              size="small"
              className="p-1 w-100 bottomFooterInput"
              value={toImage}
            />
          </div>
        </FormControl>
      </Grid>
    </>
  );
}
