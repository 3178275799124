import { Box, List, ListItem, ListItemText } from "@mui/material";
import React from "react";

export default function CollapseBox({ id, item }) {
  return (
    <div className="collapse" id={id}>
      <List component="div">
        <ListItem
          divider
          aria-haspopup="true"
          aria-controls="image_series"
          aria-label="phone ringtone"
        >
          <ListItemText>
            <span
              className="text-break"
              style={{
                fontSize: "12px",
                paddingRight: "10px",
              }}
            >
              {item?.SERIES_NUMBER}
            </span>
          </ListItemText>
          {item?.Count && (
            <ListItemText>
              [
              <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item?.Count}
              </span>
              ]
            </ListItemText>
          )}
        </ListItem>
      </List>
    </div>
  );
}
