/**
 * Maps parameter to corresponding column name in the database and returns that as the field name.
 * @param {string} eventTargetName - The parameter to be mapped.
 * @param {boolean} forCTRL2 - Optional. Specifies whether to prepend "X_" to the field name.
 * @returns {string} The corresponding field name of the database for the names mapping.
 * Ex: Givenname or Surname should be mapped to Names.
 */
export function getFieldNameCombiningNames(eventTargetName, forCTRL2 = false) {
  const fieldMapping = {
    GIVENNAME: "NAMES",
    SURNAME: "NAMES",
    SPOUSE_GIVENNAME: "SPOUSE_NAMES",
    SPOUSE_SURNAME: "SPOUSE_NAMES",
    FATHER_GIVENNAME: "FATHER_NAMES",
    FATHER_SURNAME: "FATHER_NAMES",
    MOTHER_GIVENNAME: "MOTHER_NAMES",
    MOTHER_SURNAME: "MOTHER_NAMES",
    SPOUSE_FATHER_GIVENNAME: "SPOUSE_FATHER_NAMES",
    SPOUSE_FATHER_SURNAME: "SPOUSE_FATHER_NAMES",
    SPOUSE_MOTHER_GIVENNAME: "SPOUSE_MOTHER_NAMES",
    SPOUSE_MOTHER_SURNAME: "SPOUSE_MOTHER_NAMES",
  };
  const value = fieldMapping[eventTargetName] || eventTargetName;

  return forCTRL2 ? `X_${value}` : value;
}

/**
 * Retrieves the corresponding 'GIVENNAME' field based on the provided coordinate data (parts of the Names).
 * @param {string} cordinateData - The coordinate data for names to map to 'GIVENNAME' field.
 * @returns {string} The corresponding 'GIVENNAME' input field for the given coordinate data (parts of the Names).
 */
export const getSplittedCordinateForNames = (cordinateData) => {
  const fieldsMapToGivenNames = {
    PREFIX: "GIVENNAME",
    GIVENNAME: "GIVENNAME",
    SURNAME: "GIVENNAME",
    SUFFIX: "GIVENNAME",
    FATHER_PREFIX: "FATHER_GIVENNAME",
    FATHER_GIVENNAME: "FATHER_GIVENNAME",
    FATHER_SURNAME: "FATHER_GIVENNAME",
    FATHER_SUFFIX: "FATHER_GIVENNAME",
    MOTHER_PREFIX: "MOTHER_GIVENNAME",
    MOTHER_GIVENNAME: "MOTHER_GIVENNAME",
    MOTHER_SURNAME: "MOTHER_GIVENNAME",
    MOTHER_MAIDENNAME: "MOTHER_GIVENNAME",
    MOTHER_SUFFIX: "MOTHER_GIVENNAME",
    SPOUSE_FATHER_PREFIX: "SPOUSE_GIVENNAME",
    SPOUSE_GIVENNAME: "SPOUSE_GIVENNAME",
    SPOUSE_SURNAME: "SPOUSE_GIVENNAME",
    SPOUSE_MAIDENNAME: "SPOUSE_GIVENNAME",
    SPOUSE_FATHER_SUFFIX: "SPOUSE_GIVENNAME",
    SPOUSE_FATHER_GIVENNAME: "SPOUSE_FATHER_GIVENNAME",
    SPOUSE_FATHER_SURNAME: "SPOUSE_FATHER_GIVENNAME",
    SPOUSE_FATHER_SUFFIX: "SPOUSE_FATHER_GIVENNAME",
    SPOUSE_MOTHER_PREFIX: "SPOUSE_MOTHER_GIVENNAME",
    SPOUSE_MOTHER_GIVENNAME: "SPOUSE_MOTHER_GIVENNAME",
    SPOUSE_MOTHER_SURNAME: "SPOUSE_MOTHER_GIVENNAME",
    SPOUSE_MOTHER_MAIDENNAME: "SPOUSE_MOTHER_GIVENNAME",
    SPOUSE_MOTHER_SUFFIX: "SPOUSE_MOTHER_GIVENNAME",
  };

  return fieldsMapToGivenNames[cordinateData] || cordinateData;
};
