import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import AlertBox from "../AlertBox/AlertBox";
import "./TagChecking.css";
import { useDispatch, useSelector } from "react-redux";
import TagCheckingTable from "./TagCheckingTable";
import TagCheckingTableLines from "./TagCheckingTableLines";
import TagCheckingImage from "./TagCheckingImage";
import {
  getAllTaggedImages,
  getSingleTaggedImageDetails,
} from "../../Redux/Actions/tagCheckingAction";
export default function TagChecking() {
  const dispatch = useDispatch();
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const imagesData = useSelector((state) => state?.tagCheckingReducer);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  useEffect(() => {
    document.title = "Tag Checking | Data Keying";
  }, []);
  useEffect(() => {
    dispatch(getAllTaggedImages(selectedSeriesfromLocal));
  }, [selectedSeriesfromLocal]);

  useEffect(() => {
    if (selectedImage !== null) {
      dispatch(getSingleTaggedImageDetails(selectedImage));
    }
  }, [selectedImage]);

  return (
    <div>
      <div>
        <Sidebar></Sidebar>
        {/* <AlertBox
          type="alert"
          // open={open}
          // onClose={handleClose}
          header="No data to edit"
          title="  Your entry is finished.To entry new data please tag
                    again"
        ></AlertBox> */}
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "7vh",
              maxHeight: "7vh",
              background: "#d3d3d3",
              position: "fixed",
              fontWeight: "bold",
            }}
            className="col-3 d-flex align-items-center justify-content-center"
          >
            Tag Checking
          </div>
          <TagCheckingTable
            imagesData={imagesData?.images}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
          />
          <TagCheckingTableLines imagesData={imagesData} />
          <TagCheckingImage
            imagesData={imagesData}
            selectedImage={selectedImage}
            selectedSeries={selectedSeriesfromLocal}
          />
        </div>
      </div>
    </div>
  );
}
