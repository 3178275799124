import { toast } from "react-toastify";

export const notifySuccess = (messages) => {
  toast.success(messages, {
    autoClose: 2000,
    theme: "colored",
  });
};
export const notifyWarn = (messages) => {
  toast.warn(messages, {
    autoClose: 2000,
    theme: "colored",
  });
};
