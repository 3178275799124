import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { useSelector } from "react-redux";

export default function BottomFooterTableThree({
  imagesData,
  setSelectedLine,
  selectedLine,
}) {
  const selectedFieldDataLine = useSelector(
    (state) => state.allCheckingReducer?.fieldDataImageLine
  );
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "26vh",
          maxHeight: "26vh",
          overflow: "scroll",
          background: "#FFFFE6",
          padding: "0px",
          marginTop: "1vh",
        }}
        className="col-1"
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "2px solid #b1b1b1",
                  borderTop: "0px solid",
                  borderBottom: "0px solid",
                  background: "#d3d3d3",
                  fontSize: "12px",
                  padding: "4px",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                }}
                size="small"
              >
                Line
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedFieldDataLine?.map((row, index) => (
              <TableRow
                key={index}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
                onClick={() => {
                  setSelectedLine(row["LINE"]);
                }}
              >
                <TableCell
                  sx={{
                    border: "2px solid #b1b1b1!important",
                    padding: "0px",
                    borderBottom: "0px",
                  }}
                  size="small"
                >
                  <input
                    value={row["LINE"]}
                    type="text"
                    style={{
                      width: "100%",
                      padding: "2px 4px",
                      border: "none",
                      background: row["LINE"] === selectedLine && "#00a1ffc4",
                      cursor: "pointer",
                    }}
                    readOnly
                  />
                  {/* <DataInputField
                          type={type}
                          searchTarget={searchTarget}
                          setSearchTarget={setSearchTarget}
                          row={row}
                          image={image}
                          setSelectedImage={setSelectedImage}
                          setSelectedImageNumber={setSelectedImageNumber}
                          setUnderlineCoordinates={setUnderlineCoordinates}
                          setSelectedField={setSelectedField}
                          tableRef={tableRef}
                          selectedDirection={selectedDirection}
                          setSelectedImageLine={setSelectedImageLine}
                          handleContextMenu={handleContextMenu}
                          setDialogOpen={setDialogOpen}
                          setImgComment={setImgComment}
                          doubleClickFocusData={doubleClickFocusData}
                          setOpenSearchField={setOpenSearchField}
                          setScreenKeyboardOpen={setScreenKeyboardOpen}
                          setScreenKeyboard={setScreenKeyboard}
                          screenKeyboard={screenKeyboard}
                        /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
