import http from "../../utilities/http-common";

const getImageSeries = async () => {
  return await http.get("/Image/GetSeries");
};

const getImageData = async (data) => {
  return await http.get(`/Image/GetFieldTagging/${data}`);
};
const getImageDataForEntry = async (data) => {
  return await http.get(`/Image/GetFieldEntry/${data}`);
};

const updateImageComment = async (data) => {
  return await http.put("/Image/UpdateComment", data);
};

const imageDataInsert = async (data) => {
  return await http.post("/Image/FieldTag", data);
};
const imageDataInsertIndex = async (data) => {
  return await http.post("/Image/IndexTag", data);
};
const imageDataInsertForFRC = async (data) => {
  return await http.post("/Image/First_Record_Connection_FieldTag", data);
};
const imageDataInsertForFRCField = async (data) => {
  return await http.post("/Image/First_Record_Connection_FieldTag", data);
};
const imageDataInsertForFRCIndex = async (data) => {
  return await http.post("/Image/First_Record_Connection_IndexTag", data);
};
const setDataFields = async (data) => {
  return await http.post("/Image/InsertFieldSequence", data);
};

const ImageTaggingService = {
  getImageSeries,
  getImageData,
  getImageDataForEntry,
  updateImageComment,
  imageDataInsert,
  imageDataInsertIndex,
  imageDataInsertForFRC,
  imageDataInsertForFRCField,
  imageDataInsertForFRCIndex,
  setDataFields,
};

export default ImageTaggingService;
