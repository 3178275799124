import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import disableScroll from "disable-scroll";
import FullScreen from "../FullScreen/FullScreen";
import Sidebar from "../Sidebar/Sidebar";
import img2 from "../../images/white.png";
import AlertBox from "../AlertBox/AlertBox";
import { useDispatch, useSelector } from "react-redux";
import { getImageData } from "../../Redux/Actions/dataEditAction";
import { getContinueImageData } from "../../Redux/Actions/continueImageAction";
import DataEditTable from "./DataEditTable";
import DataEditImage from "./DataEditImage";
import FloatingButtons from "./FloatingButtons";
import FloatingTable from "./FloatingTable";
import Footer from "./Footer";
import "./DataEdit.css";
import {
  handleDuplicateImage,
  handleTableHeader,
  handleTableRaw,
} from "../Helper/editHelper";
import PopupDialog from "../CommonUI/PopupDialog";
import { getEditPageData } from "../../Redux/Actions/dashboardAction";
import Keyboard from "../Helper/Keyboard";
import BlankOffsetSetUpBox from "../AlertBox/BlankOffsetSetUpBox";
export default function DataEdit() {
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const imgRef = useRef(null);
  const imagesData = useSelector((state) => state.dataEditReducer);
  const continueImage = useSelector((state) => state.continueImageReducer);
  const record = useSelector((state) => state.dashboardReducer);

  const [selectedSeries, setSelectedSeries] = useState(selectedSeriesfromLocal);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageNumber, setSelectedImageNumber] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [underlineCoordinates, setUnderlineCoordinates] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [open, setOpen] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [selectedImageLine, setSelectedImageLine] = useState([]);
  const [selectedType, setSelectedType] = useState({
    name: "All",
    value: "100",
  });
  const [isFullLineClicked, setIsFullLineClicked] = useState(false);
  const [isCardClicked, setIsCardClicked] = useState(false);
  const [isSecondImageClicked, setIsSecondImageClicked] = useState(false);
  const [isFixedLeftClicked, setIsFixedLeftClicked] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [openFloatingTable, setOpenFloatingTable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imgComment, setImgComment] = useState(null);
  const [doubleClickFocusData, setDoubleClickFocusData] = useState(null);
  const [blankOffsetOpen, setBlankOffsetOpen] = useState(false);
  const [isBlankOffsetChecked, setIsBlankOffsetChecked] = useState(false);
  const [offsetValue, setOffsetValue] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    document.title = "Data Edit | Data Keying";
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && e.which === 121) {
          e.preventDefault();
          setBlankOffsetOpen(true);
          setIsBlankOffsetChecked(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    setSelectedSeries(selectedSeriesfromLocal);
    getDataForEditTable(selectedSeriesfromLocal);
  }, [location]);
  useEffect(() => {
    dispatch(getEditPageData(selectedSeries));
  }, [selectedSeries]);
  useEffect(() => {
    if (imagesData.length > 0) {
      setTableBody(imagesData);
      setTableHeader(handleTableHeader(imagesData));
    }
  }, [imagesData]);
  useEffect(() => {
    if (selectedImageNumber !== null) {
      dispatch(getContinueImageData(selectedImageNumber));
    }
  }, [selectedImageNumber]);

  useEffect(() => {
    if (selectedType.value == "100") {
      setTableBody(imagesData);
    } else {
      let rawData = handleTableRaw(imagesData, selectedType);
      setTableBody(rawData);
    }
  }, [selectedType, imagesData]);

  useEffect(() => {
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.which === 27) {
          setOpenFloatingTable(false);
        }
      });
    }
  }, []);

  const getDataForEditTable = (series) => {
    dispatch(getImageData(series));
  };
  const handleClose = () => {
    setOpen(true);
    history.push("/");
  };
  const handlePopupClose = () => {
    setDialogOpen(false);
  };
  const handleBlankOffsetClose = () => {
    setBlankOffsetOpen(false);
  };

  return (
    <div style={{ background: "#d9ebff", height: "100vh" }}>
      <div>
        <Sidebar></Sidebar>
        <FullScreen></FullScreen>
        <AlertBox
          type="alert"
          open={open}
          onClose={handleClose}
          header="No data to edit"
          title="  Your entry is finished.To entry new data please tag
                    again"
        ></AlertBox>
        <PopupDialog
          dispatch={dispatch}
          dialogOpen={dialogOpen}
          handleClose={handlePopupClose}
          handleDuplicateImage={handleDuplicateImage}
          selectedImageNumber={selectedImageNumber}
          imgComment={imgComment}
          selectedImageLine={selectedImageLine}
        />
        {blankOffsetOpen && (
          <BlankOffsetSetUpBox
            title="Set Blank Offset"
            open={blankOffsetOpen}
            onClose={handleBlankOffsetClose}
            setOffsetValue={setOffsetValue}
            offsetValue={offsetValue}
          />
        )}
        <div>
          <DataEditImage
            img2={img2}
            imgRef={imgRef}
            continueImage={continueImage}
            isFullLineClicked={isFullLineClicked}
            isCardClicked={isCardClicked}
            isSecondImageClicked={isSecondImageClicked}
            isFixedLeftClicked={isFixedLeftClicked}
            selectedSeries={selectedSeries}
            selectedImage={selectedImage}
            underlineCoordinates={underlineCoordinates}
            selectedField={selectedField}
            offsetValue={offsetValue}
          />
          <FloatingButtons
            isFullLineClicked={isFullLineClicked}
            setIsFullLineClicked={setIsFullLineClicked}
            isCardClicked={isCardClicked}
            setIsCardClicked={setIsCardClicked}
            isSecondImageClicked={isSecondImageClicked}
            setIsSecondImageClicked={setIsSecondImageClicked}
            isFixedLeftClicked={isFixedLeftClicked}
            setIsFixedLeftClicked={setIsFixedLeftClicked}
          />
        </div>
        {/* <Divider /> */}
      </div>
      <div>
        <DataEditTable
          imagesData={imagesData}
          selectedDirection={selectedDirection}
          selectedType={selectedType}
          tableBody={tableBody}
          tableHeader={tableHeader}
          selectedField={selectedField}
          setSelectedImage={setSelectedImage}
          setSelectedImageNumber={setSelectedImageNumber}
          setUnderlineCoordinates={setUnderlineCoordinates}
          setSelectedField={setSelectedField}
          setSelectedImageLine={setSelectedImageLine}
          setDialogOpen={setDialogOpen}
          setImgComment={setImgComment}
          doubleClickFocusData={doubleClickFocusData}
          tableHeight="35vh"
        />
        <div className="pt-1 px-2 d-flex justify-content-between">
          {openFloatingTable && (
            <FloatingTable
              clickedButton={clickedButton}
              tableBody={tableBody}
              selectedField={selectedField}
              setDoubleClickFocusData={setDoubleClickFocusData}
            />
          )}
          <p className="m-0" style={{ fontWeight: "bold", color: "#991e29" }}>
            {/* {dataType} */}
          </p>
          <Footer
            selectedImage={selectedImage}
            imagesData={imagesData}
            setSelectedDirection={setSelectedDirection}
            imgRef={imgRef}
            selectedField={selectedField}
            selectedType={selectedType}
            selectedImageNumber={selectedImageNumber}
            selectedSeries={selectedSeries}
            setSelectedType={setSelectedType}
            setTableBody={setTableBody}
            setOpenFloatingTable={setOpenFloatingTable}
            setClickedButton={setClickedButton}
            selectedImageLine={selectedImageLine}
            getDataForEditTable={getDataForEditTable}
            setDialogOpen={setDialogOpen}
            setImgComment={setImgComment}
            record={record}
            isBlankOffsetChecked={isBlankOffsetChecked}
            setIsBlankOffsetChecked={setIsBlankOffsetChecked}
            setBlankOffsetOpen={setBlankOffsetOpen}
            setOffsetValue={setOffsetValue}
          />
        </div>
      </div>
    </div>
  );
}
