import { GET_SEARCH_DATA, SET_EMPTY_SEARCHDATA } from "../Actions/types";

const initialState = [];

function searchDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SEARCH_DATA:
      return payload;

    case SET_EMPTY_SEARCHDATA:
      return payload;

    default:
      return state;
  }
}

export default searchDataReducer;
