import SearchDataService from "../Services/searchDataService";
import { GET_SEARCH_DATA, SET_EMPTY_SEARCHDATA } from "./types";

export const getSearchData = (data) => async (dispatch) => {
  try {
    const res = await SearchDataService.getSearchData(data);
    dispatch({
      type: GET_SEARCH_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const setEmptySearchData = () => (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_SEARCHDATA,
      payload: [],
    });
  } catch (err) {
    console.log(err);
  }
};
