import ImageSeriesService from "../Services/imageSeriesService";
import { GET_IMAGE_SERIES_NUMBER } from "./types";

export const getImageSeries = () => async (dispatch) => {
  try {
    const res = await ImageSeriesService.getImageSeries();

    dispatch({
      type: GET_IMAGE_SERIES_NUMBER,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
