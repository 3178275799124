import {
  CLEAR_DASHBOARD,
  GET_DASHBOARD_DATA,
  TOTAL_RECORD,
} from "../Actions/types";

const initialState = null;

function dashboardReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_DATA:
      return payload;
    case TOTAL_RECORD:
      return { ...state, totalRecord: payload };
    case CLEAR_DASHBOARD:
      return payload;
    default:
      return state;
  }
}

export default dashboardReducer;
