import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./BBDChecking.css";
import { useDispatch, useSelector } from "react-redux";
import BBDCheckingTable from "./BBDCheckingTable";
import BBDCheckingImage from "./BBDCheckingImage";
import Footer from "./Footer";
import { getAllImages } from "../../Redux/Actions/bbdCheckingAction";
import { handleTableRaw } from "../Helper/library";
export default function BBDChecking() {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  var selectedSeriesfromLocal = JSON.parse(
    sessionStorage.getItem("selectedSeries")
  );
  const imagesData = useSelector((state) => state.bbdCheckingReducer);

  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [tableBody, setTableBody] = useState([]);
  const [selectedType, setSelectedType] = useState("ALL");

  const getData = () => {
    dispatch(getAllImages(selectedSeriesfromLocal));
  };
  useEffect(() => {
    document.title = "BBD Checking | Data Keying";
    getData();
  }, []);
  useEffect(() => {
    if (isRefresh) {
      getData();
      setSelectedType("ALL");
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    if (selectedImage !== null) {
      setSelectedImageIndex(() =>
        imagesData.findIndex(
          (element) => element.IMAGE_NUMBER === selectedImage.IMAGE_NUMBER
        )
      );
    }
  }, [selectedImage]);

  useEffect(() => {
    if (imagesData?.length > 0) {
      if (selectedType === "ALL") {
        setTableBody(imagesData);
      } else {
        setTableBody(handleTableRaw(imagesData, selectedType));
      }
    }
  }, [selectedType, imagesData]);

  return (
    <div>
      <div>
        <Sidebar></Sidebar>
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "7vh",
              maxHeight: "7vh",
              background: "#d3d3d3",
              position: "fixed",
              fontWeight: "bold",
            }}
            className="col-2 d-flex align-items-center justify-content-end px-4"
          >
            BBD Checking
          </div>
          <BBDCheckingTable
            imagesData={tableBody}
            selectedImage={selectedImage}
            selectedImageIndex={selectedImageIndex}
            setSelectedImage={setSelectedImage}
            setSelectedImageIndex={setSelectedImageIndex}
          />
          <BBDCheckingImage
            imgRef={imgRef}
            imagesData={imagesData}
            selectedImage={selectedImage}
            selectedSeries={selectedSeriesfromLocal}
          />
        </div>
        <Footer
          imagesData={imagesData}
          imgRef={imgRef}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          selectedImageIndex={selectedImageIndex}
          setIsRefresh={setIsRefresh}
        />
      </div>
    </div>
  );
}
