export default function FloatingButtons({
  isFullLineClicked,
  setIsFullLineClicked,
  isCardClicked,
  setIsCardClicked,
  isSecondImageClicked,
  setIsSecondImageClicked,
  isFixedLeftClicked,
  setIsFixedLeftClicked,
}) {
  const drawFullLine = (e) => {};
  return (
    <div
      style={{
        display: "flex",
        // flexDirection: "column",
        position: "relative",
        // top: "36vh",
        // top: "39vh",
        marginTop: "-38px",
        marginBottom: "18px",
      }}
    >
      <div
        style={{
          display: "flex",
          background: "#62adf7",
          color: "#212529",
          borderRadius: "2px",
          margin: "0px 1px",
          paddingRight: "4px",
          alignItems: "center",
        }}
      >
        <input
          className="m-1 check-dialog"
          onChange={() => {
            setIsFullLineClicked(!isFullLineClicked);
          }}
          name="fullLine"
          id="fullLine"
          value="full-line"
          type="checkbox"
          //   disabled={imagesData[0]?.FORMATE === 2 ? true : false}
        ></input>
        <label
          className=" check-dialog"
          style={{ margin: "0px" }}
          htmlFor="fullLine"
        >
          Full Line
        </label>
      </div>
      {/* <div
        style={{
          display: "flex",
          background: "#bdbdbd",
          borderRadius: "2px",
          margin: "1px 0px",
        }}
      >
        <input
          className="m-1 check-dialog"
          onChange={() => {
            setIsCardClicked(!isCardClicked);
          }}
          name="card"
          value="card"
          type="checkbox"
          //   disabled={imagesData[0]?.FORMATE === 2 ? true : false}
        ></input>
        <label className=" check-dialog" style={{ margin: "0px" }}>
          Card
        </label>
      </div> */}
      <div
        style={{
          display: "flex",
          background: "#62adf7",
          color: "#212529",
          borderRadius: "2px",
          margin: "0px 1px",
          paddingRight: "4px",
          alignItems: "center",
        }}
      >
        <input
          className="m-1 check-dialog"
          onChange={() => {
            setIsSecondImageClicked(!isSecondImageClicked);
          }}
          name="secondImage"
          id="secondImage"
          value="secondImage"
          type="checkbox"
          //   disabled={
          // imagesData[0]?.FORMATE === 2 && fixedLeftData !== null
          //   ? false
          //   : true
          //   }
        ></input>
        <label
          className=" check-dialog"
          style={{ margin: "0px" }}
          htmlFor="secondImage"
        >
          Second Image
        </label>
      </div>

      <div
        style={{
          display: "flex",
          background: "#62adf7",
          color: "#212529",
          borderRadius: "2px",
          margin: "0px 1px",
          paddingRight: "4px",
          alignItems: "center",
        }}
      >
        <input
          className="m-1 check-dialog"
          onChange={() => {
            setIsFixedLeftClicked(!isFixedLeftClicked);
          }}
          name="fixedLeft"
          id="fixedLeft"
          value="fixed-left"
          type="checkbox"
          //   disabled={
          // imagesData[0]?.FORMATE === 2 && fixedLeftData !== null
          //   ? false
          //   : true
          //   }
        ></input>
        <label
          className=" check-dialog"
          style={{ margin: "0px" }}
          htmlFor="fixedLeft"
        >
          Fixed Left
        </label>
      </div>
    </div>
  );
}
