import axios from "axios";
// const token = JSON.parse(localStorage.getItem("user"));

// export default axios.create({
//   baseURL: "http://localhost:8000",
//   //method: 'POST',
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Content-type": "application/json",
//     Authorization: `Bearer ${token ? token.access_token : ""}`,
//     responseType: "json",
//   },
// });

// const client = axios.create({ baseURL: "http://localhost:8000" });
// const client = axios.create({ baseURL: "http://10.0.0.123:8000" }); //dev ip
// const client = axios.create({ baseURL: "http://10.0.0.123:8051" });//personal
// const client = axios.create({ baseURL: "http://10.0.6.9:8070" }); //Ashik
const client = axios.create({
  baseURL: "https://datakeyingbackend.rndghit.com", //dev domain
});
// const client = axios.create({ baseURL: "https://datakeyingbackend.rndghit.com" }); //dev ip

client.interceptors.request.use(
  async (request) => {
    const token = await JSON.parse(sessionStorage.getItem("user"));
    if (token) {
      request.headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: `Bearer ${token ? token.access_token : ""}`,
        responseType: "json",
      };
    }
    // Do something before request is sent
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    const { data: response } = error.response;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
export default client;
