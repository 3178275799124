// Image Code / Event Code of the Data Type
export const BIRTH_TYPE_CODE = 1;
export const DEATH_TYPE_CODE = 2;
export const MARRIAGE_TYPE_CODE = 3;
export const BANNS_TYPE_CODE = 4;
export const BAPTISM_TYPE_CODE = 5;
export const BURIAL_TYPE_CODE = 6;
export const MARRIAGE_INTENT_TYPE_CODE = 8;
export const MIXED_TYPE_CODE = 7;
//Set Default
export const DEFAULT_TYPE_CODE = 1;
export const DEFAULT_TYPE = "Birth";

// Language Code
export const ITALIAN_LANGUAGE_CODE = 1;
export const LATIN_LANGUAGE_CODE = 2;
export const FRENCH_LANGUAGE_CODE = 3;

// Function Key Codes
export const ESCAPE_KEY_CODE = 27;

//Key code to the Fields
export const NAMES_KEY_CODE = 65;
export const GENDER_KEY_CODE = 75;
export const BIRTH_DATE_KEY_CODE = 66;
export const FATHER_NAMES_KEY_CODE = 68;
export const MOTHER_NAMES_KEY_CODE = 69;
export const AGE_KEY_CODE = 67;
export const MARRIAGE_DATE_KEY_CODE = 74;
export const BIRTH_PLACE_KEY_CODE = 71;
export const OCCUPATION_KEY_CODE = 51;
export const SPOUSE_GIVENNAME_KEY_CODE = 70;
export const SPOUSE_SURNAME_KEY_CODE = 70;
export const SPOUSE_AGE_KEY_CODE = 76;
export const SPOUSE_BIRTH_PLACE_KEY_CODE = 72;
export const SPOUSE_OCCUPATION_KEY_CODE = 77;
export const SPOUSE_FATHER_NAME_KEY_CODE = 73;
export const SPOUSE_FATHER_BIRTH_PLACE_KEY_CODE = 72;
export const SPOUSE_MOTHER_NAME_KEY_CODE = 72;
export const SPOUSE_MOTHER_BIRTH_PLACE_KEY_CODE = 72;
export const DEATH_DATE_KEY_CODE = 74;
export const BURIAL_DATE_KEY_CODE = 87;
export const R_BURIAL_DATE_KEY_CODE = 87;
export const FATHER_AGE_KEY_CODE = 67;
export const FATHER_BIRTH_PLACE_KEY_CODE = 71;
export const MOTHER_AGE_KEY_CODE = 67;
export const MOTHER_BIRTH_PLACE_KEY_CODE = 71;
export const R_DEATH_DATE_KEY_CODE = 74;
export const R_BIRTH_DATE_KEY_CODE = 66;
export const R_MARRIAGE_DATE_KEY_CODE = 74;
export const HEADER_PARISH_KEY_CODE = 81;
export const HEADER_COUNTY_KEY_CODE = 51;
export const HEADER_YEAR_KEY_CODE = 72;
export const BAPTISM_DATE_KEY_CODE = 87;
export const R_BAPTISM_DATE_KEY_CODE = 87;
export const SPOUSE_FATHER_GIVENNAME_KEY_CODE = 84;
export const SPOUSE_FATHER_SURNAME_KEY_CODE = 84;
export const SPOUSE_MOTHER_GIVENNAME_KEY_CODE = 83;
export const SPOUSE_MOTHER_SURNAME_KEY_CODE = 83;
export const MARRIAGE_INTENT_DATE_KEY_CODE = 86;
export const R_MARRIAGE_INTENT_DATE_KEY_CODE = 86;
export const ENTRY_NO_KEY_CODE = 5;
export const EVENT_COMMUNE_KEY_CODE = 6;
export const GIVENNAME_KEY_CODE = 7;
export const SURNAME_KEY_CODE = 8;
