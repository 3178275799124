import http from "../../utilities/http-common";

const getAllFields = async () => {
  return await http.get("/allcheck/fields");
};
const getFieldData = async (seriesnumber, fieldname) => {
  return await http.get(`allcheck/fielddata/${seriesnumber}/${fieldname}`);
};
const getFieldDataImage = async (seriesnumber, fieldname, value) => {
  return await http.get(
    `allcheck/images/${seriesnumber}/${fieldname}?fieldvalue=${value}`
  );
};
const getFieldDataImageLine = async (imagenumber, fieldname, value) => {
  return await http.get(
    `allcheck/lines/${imagenumber}/${fieldname}?fieldvalue=${value}`
  );
};
const updateFieldValue = async (body) => {
  return await http.put("/allcheck/update_data", body);
};
const blankUpdate = async (body) => {
  return await http.put("/allcheck/blank_update", body);
};
const allCheckingService = {
  getAllFields,
  getFieldData,
  getFieldDataImage,
  getFieldDataImageLine,
  updateFieldValue,
  blankUpdate,
};

export default allCheckingService;
