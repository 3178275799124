/**
 * Get the BBD status code based on the provided imgComment.
 *
 * @param {string} imgComment - The comment associated with the image.
 * @returns {number} The corresponding BBD code based on the imgComment.
 */
export const getBBDStatusCode = (imgComment) => {
  const defaultValue = 2;
  const statusMapping = {
    Blank: -99,
    Bad: -9,
    Duplicate: -999,
    Tag: defaultValue,
    Continue: -8,
    NoExtDataImage: -777,
    OtherLanguage: -444,
  };

  return statusMapping[imgComment] || defaultValue;
};

/**
 * Reset the disabled property of menuOptions to null for items with disabled set to true.
 *
 * @param {Array} menuOptions - The array of menu options to reset.
 * @returns {Array} A new array with the disabled property set to null for disabled items, otherwise the original array.
 */
export const resetFieldTagMenu = (menuOptions) => {
  const newMenuOptions = menuOptions.map((menuItem) => {
    if (menuItem.disabled === true) {
      return { ...menuItem, disabled: null };
    }
    return menuItem;
  });
  return newMenuOptions;
};
