import bbdCheckingService from "../Services/bbdCheckingService";
import {
  CHANGE_IMAGE_STATUS,
  ENTRY_COMPLETE,
  GET_All_IMAGE,
  RETAG_IMAGE,
} from "./types";

export const getAllImages = (selectedSeriesfromLocal) => async (dispatch) => {
  try {
    const res = await bbdCheckingService.getAllImages(selectedSeriesfromLocal);
    dispatch({
      type: GET_All_IMAGE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const changeAImageStatus =
  (imageNumber, comment, code) => async (dispatch) => {
    try {
      const res = await bbdCheckingService.changeImageStatus(
        imageNumber,
        comment,
        code
      );
      dispatch({
        type: CHANGE_IMAGE_STATUS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
export const retagAImage = (imageNumber) => async (dispatch) => {
  try {
    const res = await bbdCheckingService.retagImage(imageNumber);
    dispatch({
      type: RETAG_IMAGE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const entryComplete = (imageNumber) => async (dispatch) => {
  try {
    const res = await bbdCheckingService.entryComplete(imageNumber);
    dispatch({
      type: ENTRY_COMPLETE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
