import { Box, Grid } from "@mui/material";
import disableScroll from "disable-scroll";
import img2 from "../../images/white.png";
import React, { useEffect, useRef, useState } from "react";
import { settingRatio, setTransform, zooming } from "../Helper/commonHelper";
import { createCanvas } from "../Helper/library";

export default function BBDCheckingImage({
  imgRef,
  imagesData,
  selectedSeries,
  selectedImage,
  isSecondImageClicked,
  continueImage,
}) {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  console.log("🚀 ~ file: BBDCheckingImage.js:17 ~ process.env:", process.env);
  const mainSectionRef = useRef(null);
  const canvRef = useRef(null);

  const [scale, setScale] = useState(1);

  useEffect(() => {
    var keyCodesPlus = [61, 107, 187];
    var keyCodesMinus = [173, 109, 189];
    if (document) {
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && keyCodesPlus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale *= 1.08).toFixed(2));
        } else if (e.ctrlKey && keyCodesMinus.indexOf(e.which) !== -1) {
          e.preventDefault();
          setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
        }
      });
    }
  }, []);

  useEffect(() => {
    setTransform(canvRef, scale);
  }, [scale]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          maxHeight: "100vh",
          overflow: "scroll",
          resize: "vertical",
        }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                id="imageContainer"
                className="zoom imgdiv"
                onWheel={(e) => zooming(e, canvRef, "", setScale)}
                onMouseLeave={() => disableScroll.off()}
                onMouseEnter={() => disableScroll.on()}
                onMouseOver={() => disableScroll.on()}
              >
                <img
                  id="imagesss"
                  className="imageContainer"
                  tabIndex="-1"
                  ref={imgRef}
                  src={
                    selectedImage === null
                      ? img2
                      : isSecondImageClicked && continueImage
                      ? `${imageBaseUrl}${selectedSeries}/${continueImage}`
                      : `${imageBaseUrl}${selectedSeries}/${selectedImage?.IMAGE_NUMBER}`
                  }
                  alt="img"
                />
              </div>
            </Box>
          </Grid>
        </Grid>{" "}
      </Box>
    </>
  );
}
