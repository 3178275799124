import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import axios from "axios";
import disableScroll from "disable-scroll";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useState, useEffect, useRef } from "react";
//import style from './Tagging.css'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import img from "../../images/nayan/test.jpg";
import imgtest from "../../images/nayan/test.jpg";
import img1 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00057.jpg";
import img2 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00058.jpg";
import img3 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00059.jpg";
import img4 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00060.jpg";
import img5 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00061.jpg";
import img6 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00062.jpg";
import img7 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00063.jpg";
import img8 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00064.jpg";
import img9 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00065.jpg";
import img10 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00066.jpg";
import img11 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00067.jpg";
import img12 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00068.jpg";
import img13 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00069.jpg";
import img14 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00070.jpg";
import img15 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00071.jpg";
import img16 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00072.jpg";
import img17 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00073.jpg";
import img18 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00074.jpg";
import img19 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00075.jpg";
import img20 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00076.jpg";
import img21 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00077.jpg";
import img22 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00078.jpg";
import img23 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00079.jpg";
import img24 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00080.jpg";
import img25 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00081.jpg";
import img26 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00082.jpg";
import img27 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00083.jpg";
import img28 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00084.jpg";
import img29 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00085.jpg";
import img30 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00086.jpg";
import img31 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00087.jpg";
import img32 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00088.jpg";
import img33 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00089.jpg";
import img34 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00090.jpg";
import img35 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00091.jpg";
import img36 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00092.jpg";
import img37 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00093.jpg";
import img38 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00094.jpg";
import img39 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00095.jpg";
import img40 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00096.jpg";
import img41 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00097.jpg";
import img42 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00098.jpg";
import img43 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00099.jpg";
import img44 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00100.jpg";
import img45 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00101.jpg";
import img46 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00102.jpg";
import img47 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00103.jpg";
import img48 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00104.jpg";
import img49 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00105.jpg";
import img50 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00106.jpg";
import img51 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00107.jpg";
import img52 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00108.jpg";
import img53 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00109.jpg";
import img54 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00110.jpg";
import img55 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00111.jpg";
import img56 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00112.jpg";
import img57 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00113.jpg";
import img58 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00114.jpg";
import img59 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00115.jpg";
import img60 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00116.jpg";
import img61 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00117.jpg";
import img62 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00118.jpg";
import img63 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00119.jpg";
import img64 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00120.jpg";
import img65 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00121.jpg";
import img66 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00122.jpg";
import img67 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00123.jpg";
import img68 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00124.jpg";
import img69 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00125.jpg";
import img70 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00126.jpg";
import img71 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00127.jpg";
import img72 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00128.jpg";
import img73 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00129.jpg";
import img74 from "../../images/61845_302022005540_0929/61845_302022005540_0929-00130.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00131.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00132.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00133.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00134.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00135.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00136.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00137.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00138.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00139.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00140.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00141.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00142.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00143.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00144.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00145.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00146.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00147.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00148.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00149.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00150.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00151.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00152.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00153.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00154.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00155.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00156.jpg";
// import img from "../../images/61845_302022005540_0929/61845_302022005540_0929-00157.jpg";
import FullScreen from "../FullScreen/FullScreen";
import Sidebar from "../Sidebar/Sidebar";
import { Zooming } from "../Helper/entryHelper";
const baptismField = [
  "Baptism Date",
  "Name",
  "Gender",
  "Chi:Gender",
  "Birth Date",
  "Fathers_name",
  "Mothers_name",
];

const staticImages = [
  // img1,
  // img2,
  // img3,
  // img4,
  // img5,
  // img6,
  // img7,
  // img8,
  // img9,
  // img10,
  // img11,
  // img12,
  // img13,
  // img14,
  // img15,
  // img16,
  // img17,
  // img18,
  // img19,
  // img20,
  // img21,
  // img22,
  // img23,
  // img24,
  // img25,
  // img26,
  // img27,
  // img28,
  // img29,
  // img30,
  // img31,
  // img32,
  // img33,
  // img34,
  // img35,
  // img36,
  // img37,
  // img38,
  // img39,
  // img40,
  // img41,
  // img42,
  // img43,
  // img44,
  // img45,
  // img46,
  // img47,
  // img48,
  // img49,
  // img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
];

export default function Aitagging() {
  const baseURL = "http://10.0.6.24:7077";
  const imageURL = "http://10.0.6.24:7078/test1.jpg";

  const [post, setPost] = useState(null);
  const [begann, setBegann] = useState({ x: 0, y: 0 });
  const [finishedd, setFinishedd] = useState({ h: 0, w: 0 });
  const [testImageCrop, setTestImageCrop] = useState("");

  const [scale, setScale] = useState(1);

  function createPost() {
    const abcx = localStorage.getItem("taggedDataxmin");
    const abcy = localStorage.getItem("taggedDataymin");
    const abcx2 = localStorage.getItem("taggedDataxmax");
    const abcy2 = localStorage.getItem("taggedDataymax");
    const x = {
      x1: parseFloat(abcx),
      y1: parseFloat(abcy),
      x2: parseFloat(abcx2),
      y2: parseFloat(abcy2),
    };
    // const x = {
    //   image_name: "61845_302022005540_0929-00057.jpg",
    //   x: 300,
    //   y: 200,
    //   h: 512,
    //   w: 512,
    // };
    // axios.post(`${baseURL}/image/coordinate`, x).then((response) => {
    //   setPost(response.data);
    // });
    axios.post(`${baseURL}/predict`, x).then((response) => {
      setPost(response.data);

      setTimeout(() => {
        setTestImageCrop("");
        setTestImageCrop("http://10.0.6.24:8000/crop_test.jpg");
      }, 2000);
    });
  }

  const imgRef = useRef(null);
  const canvRef = useRef(null);
  const rightMenu = useRef(null);
  const mainSectionRef = useRef(null);

  const [disabled, setDisabled] = useState(false);
  const [heightRatio, setHeightRatio] = useState(0);
  const [widthRatio, setWidthRatio] = useState(0);
  const [imageHW, setImageHW] = useState(null);

  const [params, setParams] = useState({
    type: "Baptism",
    fields: baptismField,
  });

  const baprismMenuState = [
    {
      id: 1,
      name: "First Name",
      disabled: null,
      keyCode: 65,
      keyName: "A",
    },
    {
      id: 2,
      name: "Last Name",
      disabled: null,
      keyCode: 83,
      keyName: "S",
    },
    {
      id: 3,
      name: "Fathers Name",
      disabled: null,
      keyCode: 68,
      keyName: "D",
    },
    {
      id: 4,
      name: "Mothers Name",
      disabled: null,
      keyCode: 70,
      keyName: "F",
    },
  ];
  const [open, setOpen] = useState(false);
  const [imgType, setImgType] = useState("");

  const [menuOptions, setMenuOptions] = useState(baprismMenuState);
  const handleClickOpen = (name) => {
    setImgType(name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState(staticImages);

  useEffect(() => {
    window.addEventListener("resize", settingRatio);
    let timer1 = setTimeout(() => settingRatio(), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [widthRatio, heightRatio]);

  function setMousePosition(e) {
    var ev = e || window.event; //Moz || IE

    const appendRef = canvRef.current;
    const bounds = appendRef.getBoundingClientRect();

    if (ev.pageX) {
      //Moz
      mouse.x =
        scale > 0
          ? (ev.pageX + window.pageXOffset - bounds.left) / scale
          : (ev.pageX + window.pageXOffset - bounds.left) * scale;
      mouse.y =
        scale > 0
          ? (ev.pageY + window.pageYOffset - bounds.top) / scale
          : (ev.pageY + window.pageYOffset - bounds.top) * scale;
    }
  }

  var mouse = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0,
  };
  var element = null;
  var setTransform = () => {
    const zoomImg = canvRef.current;
    zoomImg.style.transform = "scale(" + scale + ")";
  };

  useEffect(() => {
    setTransform();
  }, [scale]);
  const onmousemove = (e) => {
    const appendRef = canvRef.current;
    const bounds = appendRef.getBoundingClientRect();
    setMousePosition(e);
    if (element !== null) {
      element.style.width = Math.abs(mouse.x - mouse.startX) + "px";
      element.style.height = Math.abs(mouse.y - mouse.startY) + "px";

      element.style.left =
        mouse.x - mouse.startX < 0
          ? mouse.x * widthRatio + "px"
          : mouse.startX + "px";
      element.style.top =
        mouse.y - mouse.startY < 0
          ? mouse.y * heightRatio + "px"
          : mouse.startY + "px";
    }
  };

  const handleClick = (e) => {
    const canvas = canvRef.current;
    const mainSec = mainSectionRef.current;
    const appendRef = canvRef.current;
    const bounds = appendRef.getBoundingClientRect();
    const newPosx =
      scale > 0
        ? (e.clientX - bounds.left) / scale
        : (e.clientX - bounds.left) * scale;
    const newPosy =
      scale > 0
        ? (e.clientY - bounds.top) / scale
        : (e.clientY - bounds.top) * scale;

    let newPos = { x: newPosx / widthRatio, y: newPosy / heightRatio };
    setMousePosition(e);

    if (element !== null) {
      element = null;
      canvas.style.cursor = "default";
      localStorage.setItem("taggedDataxmax", newPos.x);
      localStorage.setItem("taggedDataymax", newPos.y);
      // handleMenu(e);
      createPost();
    } else {
      localStorage.setItem("taggedDataxmin", newPos.x);
      localStorage.setItem("taggedDataymin", newPos.y);
      mouse.startX =
        scale > 0
          ? (e.clientX - bounds.left) / scale
          : (e.clientX - bounds.left) * scale;
      mouse.startY =
        scale > 0
          ? (e.clientY - bounds.top) / scale
          : (e.clientY - bounds.top) * scale;
      element = document.createElement("div");
      element.className = "rectangle";
      element.style.left = newPos.x * scale * widthRatio + "px";
      element.style.top = newPos.y * scale * heightRatio + "px";
      canvas.appendChild(element);
      canvas.style.cursor = "crosshair";
    }
  };

  //********remove last tag start********

  const clearTag = (e) => {
    e.preventDefault();
    var selectLastTag = document.querySelectorAll(".rectangle");

    if (selectLastTag.length !== 0) {
      //    const lastTagedId = menuOptions.slice().reverse().find( ( menu ) => menu.disabled === true );
      //    setMenuOptions(prevState => {
      //        const prevMenuOptions = [...prevState];
      //        prevMenuOptions[lastTagedId !== 0 && lastTagedId.id -1].disabled = null;
      //        return prevMenuOptions;
      //    })

      selectLastTag[selectLastTag.length - 1].remove();
    }
  };
  //********remove last tag end********
  const handleMenu = (e) => {
    e.preventDefault();

    const showingMenu = rightMenu.current;
    const mainSec = mainSectionRef.current;

    //for menu click coordinate
    const rectMenu = mainSec.getBoundingClientRect();

    const x = e.clientX - rectMenu.left;
    const y = e.clientY - rectMenu.top;

    // Set the position for menu
    showingMenu.style.top = `${y}px`;
    showingMenu.style.left = `${x}px`;

    // Show the showingMenu
    showingMenu.classList.remove("container__menu_hidden");
    //handleMenuShowing(e)
  };

  const getFieldValue = (e, menuId) => {
    const showingMenu = rightMenu.current;

    //saving tagged values to state
    // let name =menuOptions[menuId -1].name
    // let positionx =positionXY.x
    // let positiony =positionXY.y
    // let imageName =images[currentIndex].name
    // let imageId =images[currentIndex].id

    // setRecords([
    //         ...records,
    //         {
    //             fieldName: name,
    //             coordinateX:positionx,
    //             coordinateY:positiony,
    //             imageName:imageName,
    //             imageId:imageId,
    //             line:lineNo
    //         }
    // ])

    //disabling menu
    setMenuOptions((prevState) => {
      const newMenuOptions = [...prevState];
      newMenuOptions[menuId - 1].disabled = true;
      return newMenuOptions;
    });

    handleClick(e);
    showingMenu.classList.add("container__menu_hidden");
  };
  const handleNextImage = () => {
    setCurrentIndex((prevState) => prevState + 1);
  };
  const handlePrevImage = () => {
    setCurrentIndex((prevState) => prevState - 1);
  };

  function settingRatio() {
    if (document !== undefined) {
      var windowSize = document?.querySelectorAll("#imagesss");
      var w = windowSize[0]?.clientWidth;
      var h = windowSize[0]?.clientHeight;
      var myImg = document.querySelector("#imagesss");
      var realWidth = myImg?.naturalWidth;
      var realHeight = myImg?.naturalHeight;

      setImageHW({ width: realWidth, height: realHeight });

      var widthRatios = w / realWidth;
      var heightRatios = h / realHeight;

      setWidthRatio(widthRatios);
      setHeightRatio(heightRatios);
    }
  }
  return (
    <>
      <FullScreen></FullScreen>
      <Sidebar></Sidebar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Current image has been marked as BAD or BLANK or DUPLICATE image"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="success" variant="outlined" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          maxWidth: 250,
          bgcolor: "background.paper",
        }}
        ref={rightMenu}
        className="container__menu container__menu_hidden"
      >
        <List>
          {menuOptions?.map((option) => {
            return (
              <ListItem key={option.id} disablePadding>
                <ListItemButton
                  component="a"
                  onClick={(e) => getFieldValue(e, option.id)}
                  disabled={option.disabled}
                >
                  <ListItemText primary={option.name} />
                  <span>{option?.keyName}</span>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Box
        sx={{ flexGrow: 1, height: "70vh", overflow: "scroll" }}
        ref={mainSectionRef}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box>
              <div
                ref={canvRef}
                className="zoom"
                onMouseOver={() => disableScroll.on()}
                onMouseEnter={() => disableScroll.on()}
                onMouseLeave={() => disableScroll.off()}
                onWheel={(e) => Zooming(e, setScale, canvRef)}
              >
                <img
                  style={{ position: "relative" }}
                  className="imageContainer"
                  tabIndex="-1"
                  ref={imgRef}
                  onClick={(e) => handleClick(e)}
                  onMouseMove={(e) => onmousemove(e)}
                  onContextMenu={(e) => clearTag(e)}
                  src={img}
                  alt="img"
                  id="imagesss"
                />
              </div>

              {/* must use tabIndex  otherwise keyboard event wont work */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {/* <Stack direction="row" spacing={1}>
        <Button
          sx={{ fontSize: "12px" }}
          color="success"
          // disabled={!disabled}
          variant="outlined"
          onClick={createPost}
        >
          Save
        </Button>
        <Button
          sx={{ fontSize: "12px" }}
          color="secondary"
          variant="outlined"
          onClick={handlePrevImage}
        >
          Prev Image
        </Button>
        <Button
          sx={{ fontSize: "12px" }}
          color="primary"
          variant="outlined"
          onClick={handleNextImage}
        >
          Next Image
        </Button>
      </Stack> */}
      {post !== null && (
        <div
          className="d-flex justify-content-center pt-2 align-items-start"
          id="suggestionTable"
        >
          <div className="d-flex justify-content-center flex-column align-items-center px-3 m-0">
            <img
              src={`data:image/jpeg;base64,${post?.image_base64}`}
              alt="Base64 Image"
              style={{ border: "1px solid black", margin: "5px" }}
              height={150}
            />
            <div className="d-flex justify-content-center">
              <p>Predicted Text: </p>
              <b style={{ color: "red" }} className="px-2">
                {" "}
                {post?.word}
              </b>
            </div>
          </div>
          <div
            style={{
              maxHeight: "250px",
              overflowY: "scroll",
              border: "1px solid black",
              borderRight: "0px solid black",
            }}
          >
            <table>
              <tr>
                <th colspan="2" className="text-center">
                  Suggestions
                </th>
              </tr>
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Score</th>
              </tr>
              {post?.char_info_list?.map((row, index) => (
                <tr key={index}>
                  <td className="text-center">{row[0]}</td>
                  <td className="text-center">{row[1]}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      )}
      {/* <Box sx={{ flexGrow: 1, height: "20vh", px: 3 }}>
        <Grid container item lg={12} spacing={2} py={1}>
          <Grid item lg={8}></Grid>

          <Grid item lg={4}>
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ fontSize: "12px" }}
                color="success"
                variant="outlined"
              >
                Add Record
              </Button>

              <Button sx={{ fontSize: "12px" }} variant="outlined" disabled>
                Get Last Record No and add
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} spacing={1} py={2}>
          <Grid item lg={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Language</FormLabel>
              <RadioGroup
                row
                aria-label="language"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="German"
                  control={<Radio />}
                  label="German"
                />
                <FormControlLabel
                  value="Latin"
                  control={<Radio />}
                  label="Latin"
                />
                <FormControlLabel
                  value="English"
                  control={<Radio />}
                  label="English"
                />
              </RadioGroup>
            </FormControl>
            <Stack direction="row" spacing={1}>
              <Button
                sx={{ fontSize: "12px" }}
                color="primary"
                variant="outlined"
              >
                Next Image
              </Button>

              {/* <Button onClick={goToPrevSlide} color="secondary" variant="outlined" >
                                        Prev Image
                                        </Button> 
              <Button
                sx={{ fontSize: "12px" }}
                color="success"
                disabled={!disabled}
                variant="outlined"
              >
                Save
              </Button>
            </Stack>
          </Grid>

          <Grid item lg={5}>
            <Stack direction="row">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="First record connection"
                />
                <FormControlLabel control={<Checkbox />} label="CopyZone" />
              </FormGroup>
              <Box>
                <Button
                  sx={{ mx: 1, my: 1, fontSize: "12px" }}
                  color="primary"
                  variant="outlined"
                >
                  continue
                </Button>
                <Button
                  sx={{ mx: 1, my: 1, fontSize: "12px" }}
                  color="primary"
                  variant="outlined"
                >
                  No Ext DataImage
                </Button>
                <Button
                  sx={{ mx: 1, my: 1, fontSize: "12px" }}
                  color="secondary"
                  variant="outlined"
                >
                  Other Language
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack direction="row" spacing={1}>
              <Button
                sx={{ fontSize: "12px" }}
                color="info"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Blank")}
              >
                Blank
              </Button>
              <Button
                sx={{ fontSize: "12px" }}
                color="error"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Bad")}
              >
                Bad
              </Button>
              <Button
                sx={{ fontSize: "12px" }}
                color="warning"
                disabled={disabled}
                variant="outlined"
                onClick={() => handleClickOpen("Duplicate")}
              >
                Duplicate
              </Button>
              <Button
                sx={{ fontSize: "12px" }}
                startIcon={<RotateLeftIcon />}
                color="primary"
                variant="outlined"
              >
                Rotate
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Grid container item lg={12} spacing={2} py={2}>
          <Grid item lg={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Data Type</FormLabel>
              <RadioGroup
                row
                aria-label="types"
                name="row-radio-buttons-group"
                defaultValue="Baptism"
                value={params.type}
              >
                <FormControlLabel
                  value="Baptism"
                  control={<Radio />}
                  label="Baptism"
                />
                <FormControlLabel
                  value="Burial"
                  control={<Radio />}
                  label="Burial"
                />
                <FormControlLabel
                  value="Marriage"
                  control={<Radio />}
                  label="Marriage"
                />
                <FormControlLabel
                  value="Mixed"
                  control={<Radio />}
                  label="Mixed"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Multiple Type </FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Baptism"
                  control={<Radio />}
                  label="Baptism"
                />
                <FormControlLabel
                  value="Burial"
                  control={<Radio />}
                  label="Burial"
                />
                <FormControlLabel
                  value="Marriage"
                  control={<Radio />}
                  label="Marriage"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
}
