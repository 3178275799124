import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "./ghit.png";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// import FmdBadIcon from "@mui/icons-material/FmdBad";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PsychologyIcon from "@mui/icons-material/Psychology";
// import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import ImageSeries from "../ImageSeries/ImageSeries";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useDispatch } from "react-redux";
import { clearDashboard } from "../../Redux/Actions/dashboardAction";
import { setImageEmptyTagging } from "../../Redux/Actions/imageTaggingAction";
export default function Menu() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  var selectedSeries = JSON.parse(sessionStorage?.getItem("selectedSeries"));

  const handleClose = (newValue) => {
    setOpen(false);
  };
  const handleClickListItem = (type) => {
    if (type !== "tag") {
      dispatch(setImageEmptyTagging());
    }
    setType(type);
    if (type.includes("entry-count")) {
      history.push("/entry-count");
    }
    if (selectedSeries && type !== "") {
      history.push({
        pathname: `/${type}`,
        state: selectedSeries,
      });
    } else setOpen(true);
  };

  return (
    <>
      <div>
        <aside className="main-sidebar sidebar-dark-blue  elevation-4">
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img src={logo} className="img-circle" alt="User-img" />
              </div>
              <div className="h4 info">
                <Link
                  to=""
                  style={{
                    color: "#ff9800",
                    fontWeight: "800",
                  }}
                  className="d-block"
                >
                  GHIT
                </Link>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li
                  className="nav-item"
                  onClick={() => dispatch(clearDashboard())}
                >
                  <Link to="/" className="nav-link">
                    {/* <i className="nav-icon fas fa-th" /> */}
                    <DashboardIcon />{" "}
                    <p>
                      Dashboard
                      {/* <span className="right badge badge-danger">New</span> */}
                    </p>
                  </Link>
                </li>

                <li
                  className="nav-item"
                  onClick={() => handleClickListItem("")}
                >
                  <Link to="#" className="nav-link">
                    {/* <i className="nav-icon fas fa-edit" /> */}
                    <CrisisAlertIcon />{" "}
                    <p>
                      Select Series
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                {/* <li className="nav-item has-treeview">
                  <Link to="#" className="nav-link">
                    <i className="nav-icon fas fa-table" />
                    <p>
                      Tables
                      <i className="fas fa-angle-left right" />
                    </p>
                  </Link>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/All-data" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>DataTables</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/Simple-table" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Simple Table</p>
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li
                  className="nav-item has-treeview"
                  onClick={() => {
                    handleClickListItem("tag");
                  }}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=tag" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-search-minus" /> */}
                    <ZoomOutIcon />{" "}
                    <p>
                      Field Tagging
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>

                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("tag-index")}
                >
                  {/* <Link to="/tag-index" className="nav-link"> */}
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=tag-index" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-search-minus" /> */}
                    <ZoomOutIcon />{" "}
                    <p>
                      Index Tagging
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("ai")}
                >
                  {/* <Link to="/tag-index" className="nav-link"> */}
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=tag-index" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-search-minus" /> */}
                    <PsychologyIcon />{" "}
                    <p>
                      AI Tagging
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("entry")}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=entry" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-keyboard" /> */}
                    <KeyboardAltIcon />{" "}
                    <p>
                      Data Entry
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("edit")}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=entry" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-keyboard" /> */}
                    <KeyboardAltIcon />{" "}
                    <p>
                      Data Edit
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("tag-checking")}
                >
                  <Link to="#" className="nav-link">
                    <PlaylistAddCheckIcon />
                    <p>
                      Tag Checking
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("all-checking")}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=entry" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-keyboard" /> */}
                    <FactCheckIcon />{" "}
                    <p>
                      All Checking
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("bbd-checking")}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=entry" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-keyboard" /> */}
                    <FolderCopyIcon />{" "}
                    <p>
                      BBD Checking
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
                <li
                  className="nav-item has-treeview"
                  onClick={() => handleClickListItem("entry-count")}
                >
                  <Link to="#" className="nav-link">
                    {/* <Link to="/series?target=entry" className="nav-link"> */}
                    {/* <i className="nav-icon fa fa-keyboard" /> */}
                    <HourglassBottomIcon />{" "}
                    <p>
                      Entry Count
                      <i className="fas fa-angle-right right" />
                    </p>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
      {/* pop up */}
      {open && (
        <ImageSeries
          id="image_series"
          keepMounted
          type={type}
          open={open}
          currentSeries={selectedSeries}
          onClose={handleClose}
        />
      )}
    </>
  );
}
