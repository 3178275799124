import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData } from "../../Redux/Actions/searchDataAction";
import Data from "./mock-data.json";

export default function SearchBar({ query, currentField, setIsProperCase }) {
  const dispatch = useDispatch();
  const searchData = useSelector((state) => state.searchDataReducer);
  const [getSearchItem, setGetSearchItem] = useState(null);
  const [selectedSearchField, setSelectedSearchField] = useState(currentField);

  useEffect(() => {
    if (query !== undefined && query.length === 1) {
      const suffix = query.toUpperCase();
      if (currentField.includes("GIVENNAME")) {
        setSelectedSearchField("GIVENNAME");
        dispatch(getSearchData(`GIVENNAMES_${suffix}`));
      } else {
        setSelectedSearchField("SURNAME");
        dispatch(getSearchData(`SURNAMES_${suffix}`));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (searchData !== null && searchData.length > 0) {
      let result =
        searchData.length > 5
          ? searchData
              .filter((post) => {
                if (
                  post[selectedSearchField]
                    .toLowerCase()
                    .includes(query.toLowerCase())
                ) {
                  return post;
                }
              })
              .slice(0, 5)
          : searchData.filter((post) => {
              if (
                post[selectedSearchField]
                  .toLowerCase()
                  .includes(query.toLowerCase())
              ) {
                return post;
              }
            });

      setGetSearchItem(result);
      checkQuery(result, query);
    }
  }, [query, searchData]);

  const checkQuery = (getItem, queryData) => {
    if (getItem.length > 0) {
      for (let i = 0; i < getItem.length; i++) {
        if (
          getItem[i][selectedSearchField].toLowerCase() ===
          queryData.toLowerCase()
        ) {
          setIsProperCase(true);
          break;
        } else setIsProperCase(false);
      }
    } else setIsProperCase(false);
  };
  // const getSearchItem
  // = searchData
  //   .filter((post) => {
  //     if (query === "") {
  //       return post;
  //     } else if (post.GIVENNAME.toLowerCase().includes(query.toLowerCase())) {
  //       return post;
  //     }
  //   })
  //   .slice(0, 5);

  return (
    <div className="search-bar searchResultsContainer">
      <div>
        <div
          className=" d-flex justify-content-flex-start"
          // className=" d-flex justify-content-between  bg-dark"
          style={{ lineHeight: "1", backgroundColor: "#1c2031", color: "#fff" }}
        >
          <h6>No</h6>
          <h6 className="d-block ml-3">{currentField}</h6>
        </div>

        {getSearchItem !== null &&
          getSearchItem.map((post, index) => (
            <div
              className="box row"
              key={index}
              style={{
                borderBottom: "1px solid black",
                backgroundColor: "white",
              }}
            >
              {/* {checkQuery(post.GIVENNAME, query)} */}
              <span className="col-2">{index + 1}</span>
              <span className="col-10">{post[selectedSearchField]}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
