import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useRef, useState } from "react";
export default function FloatingTable({
  clickedButton,
  tableBody,
  selectedField,
  setDoubleClickFocusData,
}) {
  const duplicateEntryHeader = ["IMAGE_NUMBER", "LINE", "ENTRY_NO"];
  const brackHeader = ["IMAGE_NUMBER", "LINE", selectedField];
  const auditReportHeader = [
    "IMAGE_NUMBER",
    "LINE",
    "FIELD_NAME",
    "KEYED_VALUE",
    "CORRECTION_VALUE",
  ];
  const tableRef = useRef(null);
  const [tableHeader, setTableHeader] = useState([]);
  const [duplicateEntryNo, setDuplicateEntryNo] = useState([]);
  useEffect(() => {
    if (clickedButton === "DuplicateEntryNo") {
      setTableHeader(duplicateEntryHeader);
      const duplicateIds = tableBody
        .map((row) => row.ENTRY_NO)
        .filter((row, index, indexArr) => indexArr.indexOf(row) !== index);
      setDuplicateEntryNo(
        tableBody.filter(
          (row) =>
            duplicateIds.includes(row["ENTRY_NO"]) && row["ENTRY_NO"] !== null
        )
      );
    } else if (clickedButton === "AuditReport") {
      setTableHeader(auditReportHeader);
    } else if (clickedButton === "DateBrack") {
      const brackDate = [];

      setTableHeader(brackHeader);
      for (let index = 1; index < tableBody.length; index++) {
        if (
          tableBody[index][selectedField] !== null &&
          tableBody[index - 1][selectedField] !== null &&
          new Date(`${tableBody[index - 1][selectedField]}`) >
            new Date(`${tableBody[index][selectedField]}`)
        ) {
          brackDate.push(tableBody[index - 1]);
          brackDate.push(tableBody[index]);
        }
      }
      setDuplicateEntryNo(brackDate);
    } else if (clickedButton === "YearBrack") {
      const brackYear = [];

      setTableHeader(brackHeader);
      for (let index = 1; index < tableBody.length; index++) {
        if (
          tableBody[index][selectedField] !== null &&
          tableBody[index - 1][selectedField] !== null &&
          new Date(`${tableBody[index - 1][selectedField]}`).getFullYear() >
            new Date(`${tableBody[index][selectedField]}`).getFullYear()
        ) {
          brackYear.push(tableBody[index - 1]);
          brackYear.push(tableBody[index]);
        }
      }
      setDuplicateEntryNo(brackYear);
    }
  }, [clickedButton]);

  // useEffect(() => {
  //   // const duplicateIds = tableBody
  //   //   .map((row) => row.ENTRY_NO)
  //   //   .filter((row, index, indexArr) => indexArr.indexOf(row) !== index);
  //   // setDuplicateEntryNo(
  //   //   tableBody.filter((row) => duplicateIds.includes(row.ENTRY_NO))
  //   // );new Date("Jul 21, 1883").getFullYear()

  //   const brackDate = tableBody
  //     .map((row) => {
  //       return { x0: row[selectedField], y0: row };
  //     })
  //     .filter((dates) => {
  //       return { x1: dates[0], y1: dates[1] };
  //     })
  //     .map((date) => {
  //       return { x2: new Date(`${date.x0}`).getFullYear(), y2: date.y0 };
  //     });
  //   const x = brackDate.filter((row, index, indexArr) => {
  //     if (
  //       indexArr[indexArr.indexOf(row)]?.x2 >
  //       indexArr[indexArr.indexOf(row) + 1]?.x2
  //     ) {
  //     }
  //   });

  //   // const brackDate = tableBody
  //   //   .map((row) => row[selectedField], tableBody)
  //   //   .filter((dates, index, indexArr) => dates, tableBody)
  //   //   .map((date) => new Date(`${date}`).getFullYear(), tableBody)
  //   //   .filter(
  //   //     (row, index, indexArr) => {
  //   //       if (indexArr.indexOf(row) > indexArr.indexOf(row + 1)) {
  //   //         return tableBody[0];
  //   //       }
  //   //     }
  //   //     // indexArr.indexOf(row) > indexArr.indexOf(row + 1)
  //   //   );
  //   setDuplicateEntryNo(
  //     tableBody.filter((row) => brackDate.includes(row[selectedField]))
  //   );
  // }, [tableBody]);

  return (
    <>
      <Box
        className={"floatingTable"}
        sx={{
          borderTop: "2px solid #979aa8",
          borderLeft: "2px solid #979aa8",
          borderBottom: "2px solid #979aa8",
          flexGrow: 1,
          height: "15vh",
          background: "#FFFFE6",
          position: "absolute",
          top: "33vh",
          right: clickedButton === "AuditReport" ? "16px" : null,
          overflowY: "scroll",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{}}>
              {tableHeader.length > 0 &&
                tableHeader.map((image, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      background: "#d3d3d3",
                      padding: "0px",
                      fontSize: "12px",
                      position: "sticky",
                      top: 0,
                    }}
                    size="small"
                  >
                    <span
                      style={{
                        borderRight: "2px solid #b1b1b1",
                        borderLeft: "2px solid #b1b1b1",
                        padding: "0px 5px",
                      }}
                    >
                      {image}
                    </span>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody
            ref={tableRef}
            sx={{
              overflow: "scroll",
            }}
          >
            {duplicateEntryNo?.map((row, index) => (
              <TableRow
                key={index}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
                onDoubleClick={() => {
                  setDoubleClickFocusData({
                    imageNumber: row.IMAGE_NUMBER,
                    line: row.LINE,
                    fieldName: tableHeader[tableHeader.length - 1],
                  });
                }}
              >
                {tableHeader?.map((image, index) => {
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        // border: "2px solid #b1b1b1!important",
                        padding: "0px 5px",
                        borderBottom: "0px",
                      }}
                      size="small"
                    >
                      {row[image]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
