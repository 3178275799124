import disableScroll from "disable-scroll";
var current_rotation = 0;

//======================drawing full line start=================================
export const drawFullLine = (e, previousLeftForFullLine) => {
  const { checked } = e.target;
  var element = document.querySelector("canvas");

  if (checked) {
    element.style.left = "0px";
    element.style.width = "100%";
  } else {
    element.style.left = previousLeftForFullLine;
    element.style.width = "200px";
  }
};
export const drawFullLineGeneral = (
  isFullLineClicked,
  previousLeftForFullLine
) => {
  var element = document.querySelector("canvas");
  if (element !== null) {
    if (isFullLineClicked) {
      element.style.left = "0px";
      element.style.width = "100%";
    } else {
      element.style.left = previousLeftForFullLine;
      element.style.width = "100px";
    }
  }
};
//======================drawing full line end=================================

//======================image rotation start=================================
export const handleRotation = (imgRef) => {
  const imgRot = imgRef.current;
  current_rotation += 90; // rotate clockwise by 90 degrees
  imgRot.style.transform = "rotate(" + current_rotation + "deg)";
};
//======================image rotation end=================================

//======================image scalling start=================================
export const setTransform = (elementRef, scale) => {
  const zoomImg = elementRef.current;
  zoomImg.style.transform = "scale(" + scale + ")";
};
//======================image scalling end=================================

//======================image zooming start=================================
export const zooming = (e, canvRef, setFocusData, setScale) => {
  // var element = document.querySelector("canvas").getBoundingClientRect();
  // setFocusData({
  //   x: element.left,
  //   y: element.top,
  // });
  // setFocusData({
  //   x: element.left / widthRatio,
  //   y: element.top / heightRatio,
  // });
  const canvRect = canvRef.current.getBoundingClientRect().left;
  // var windowSize = document.querySelectorAll("#imagesss");
  // windowSize.onMouseEnter(() => {
  //   alert("hey");
  // });

  if (!e.altKey && !e.shiftKey) {
    disableScroll.on();
    var delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
    delta > 0
      ? setScale((prevScale) => (prevScale *= 1.08).toFixed(2))
      : setScale((prevScale) => (prevScale /= 1.08).toFixed(2));
    // setTransform(e);
  } else {
    disableScroll.off();
  }
};
//======================image zooming start=================================
//======================Setting Screen Ratio Start=================================
export const settingRatio = (windowSize, imageSize) => {
  // var windowSize = document.querySelectorAll(".imgdiv");
  // var w = windowSize[0]?.clientWidth;
  // var h = windowSize[0]?.clientHeight;

  // var imageSize = document.querySelector("#imagesss");
  // var realWidth = imageSize?.naturalWidth;
  // var realHeight = imageSize.naturalHeight;

  // setImageHW({ width: realWidth, height: realHeight });

  var widthRatios = windowSize.width / imageSize.width;
  var heightRatios = windowSize.height / imageSize.height;

  return { widthRatio: widthRatios, heightRatio: heightRatios };
};
//======================Setting Screen Ratio End=================================
