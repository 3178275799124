import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDispatch } from "react-redux";
export default function BlankOffsetSetUpBox({
  title,
  open,
  onClose,
  setOffsetValue,
  offsetValue,
}) {
  const dispatch = useDispatch();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const handleSave = (e) => {
    setOffsetValue((prev) => {
      return {
        top: parseFloat(prev.top) + top,
        left: parseFloat(prev.left) + left,
      };
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="100%"
    >
      <DialogTitle id="alert-dialog-title" style={{ padding: "5px 24px" }}>
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: "0px 24px" }}>
        <form>
          <div className="d-flex justify-content-between flex-wrap flex-column px-3 py-1">
            <div className="d-flex flex-row-reverse align-items-center">
              <input
                disabled
                className="m-1 check-dialog"
                id="top"
                name="top"
                value={offsetValue?.top}
                type="number"
                style={{ width: "100px", padding: "0px 5px" }}
              ></input>
              <label htmlFor="top" className=" check-dialog">
                Current Top
              </label>
            </div>
            <div className="d-flex flex-row-reverse align-items-center">
              <input
                className="m-1 check-dialog"
                id="top"
                name="top"
                type="number"
                style={{ width: "100px", padding: "0px 5px" }}
                onChange={(e) => setTop(parseInt(e.target.value))}
              ></input>
              <label htmlFor="top" className=" check-dialog">
                New Top
              </label>
            </div>
            <div className="d-flex flex-row-reverse align-items-center">
              <input
                disabled
                className="m-1 check-dialog"
                id="top"
                name="top"
                value={offsetValue?.left}
                type="number"
                style={{ width: "100px", padding: "0px 5px" }}
              ></input>
              <label htmlFor="top" className=" check-dialog">
                Current Left
              </label>
            </div>
            <div className="d-flex flex-row-reverse align-items-center">
              <input
                className="m-1 check-dialog"
                id="left"
                name="left"
                type="number"
                style={{ width: "100px", padding: "0px 5px" }}
                onChange={(e) => setLeft(parseInt(e.target.value))}
              ></input>
              <label htmlFor="left" className=" check-dialog">
                New Left
              </label>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions
        className="justify-content-right"
        style={{ padding: "8px 24px" }}
      >
        <Button
          color="success"
          variant="outlined"
          onClick={handleSave}
          sx={{ fontSize: "12px" }}
          autoFocus={true}
        >
          Save
        </Button>

        <Button
          color="error"
          variant="outlined"
          onClick={onClose}
          sx={{ fontSize: "12px" }}
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
